import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RocketIcon } from "lucide-react";
import { useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { useSearchParams } from "react-router-dom";
import { Button } from "src/components/Button";
import { Dialog, DialogContent } from "src/components/Dialog";
import { useCustomerStatus } from "src/utils/useCustomerStatus";
export var PaymentSuccessDialog = function () {
    var searchParams = useSearchParams()[0];
    var sessionId = searchParams.get("session_id");
    var _a = useState(false), showDialog = _a[0], setShowDialog = _a[1];
    var _b = useCustomerStatus(), customerStatus = _b.data, isLoading = _b.isLoading;
    useEffect(function () {
        var _a;
        if (sessionId && ((_a = customerStatus === null || customerStatus === void 0 ? void 0 : customerStatus.customerStatus) === null || _a === void 0 ? void 0 : _a.isActive)) {
            setShowDialog(true);
        }
    }, [sessionId, customerStatus]);
    var handleClose = function () {
        setShowDialog(false);
        // Remove the session_id from the URL
        searchParams.delete("session_id");
        window.history.replaceState({}, "", "".concat(window.location.pathname, "?").concat(searchParams.toString()));
    };
    if (isLoading) {
        return null;
    }
    return (_jsx(Dialog, { open: showDialog, onOpenChange: handleClose, children: _jsx(DialogContent, { insertBetween: _jsx("div", { className: "fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center", children: _jsx(ConfettiExplosion, { force: 0.8, duration: 8000, particleCount: 175, width: 1600 }) }), children: _jsxs("div", { className: "flex flex-col items-center gap-4 p-6", children: [_jsx("img", { src: "/logo.png", className: "w-16", alt: "Cloudy Logo" }), _jsx("h2", { className: "text-2xl font-bold text-primary", children: "Thank You!" }), _jsx("p", { className: "text-center text-secondary", children: "Your subscription is now active. We're excited to have you on board!" }), _jsxs(Button, { onClick: handleClose, className: "mt-4", children: [_jsx(RocketIcon, { className: "h-4 w-4" }), _jsx("span", { children: "Get Started" })] })] }) }) }));
};
