import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var formatPrice = function (amount, currency) {
    if (amount === null || amount === undefined) {
        return "N/A";
    }
    var formattedAmount = (amount / 100).toFixed(2);
    var formatter = new Intl.NumberFormat(undefined, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });
    return formatter.format(parseFloat(formattedAmount)).replace(/\.00$/, "");
};
export var Pricing = function (_a) {
    var price = _a.price, fullPrice = _a.fullPrice, _b = _a.showDiscount, showDiscount = _b === void 0 ? true : _b;
    var discountPercentage = fullPrice && fullPrice.unit_amount && price.unit_amount
        ? Math.round(((fullPrice.unit_amount - price.unit_amount) /
            fullPrice.unit_amount) *
            100)
        : 0;
    return (_jsx("div", { className: "flex flex-row items-center", children: fullPrice ? (_jsxs("div", { className: "flex flex-row items-center gap-1", children: [_jsx("div", { className: "text-base text-secondary line-through", children: formatPrice(fullPrice.unit_amount, fullPrice.currency) }), _jsxs("div", { className: "flex items-baseline", children: [_jsx("span", { className: "text-3xl font-bold text-primary", children: formatPrice(price.unit_amount, price.currency) }), price.recurring && (_jsx("span", { className: "text-sm text-secondary ml-1 mb-1", children: "/seat" }))] }), showDiscount && (_jsxs("div", { className: "text-sm text-accent font-medium", children: ["Save ", discountPercentage, "%"] }))] })) : (_jsxs("div", { className: "flex items-baseline", children: [_jsx("span", { className: "text-3xl font-bold text-primary", children: formatPrice(price.unit_amount, price.currency) }), price.recurring && (_jsxs("span", { className: "text-sm text-secondary ml-1", children: ["/", price.recurring.interval] }))] })) }));
};
