var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useState } from "react";
import * as SliderPrimitive from "@radix-ui/react-slider";
import { cn } from "./utils";
export var Slider = forwardRef(function (_a, ref) {
    var _b;
    var className = _a.className, showValue = _a.showValue, onValueChange = _a.onValueChange, props = __rest(_a, ["className", "showValue", "onValueChange"]);
    var _c = useState((_b = props.defaultValue) !== null && _b !== void 0 ? _b : [0]), value = _c[0], setValue = _c[1];
    var handleValueChange = function (newValue) {
        setValue(newValue);
        onValueChange === null || onValueChange === void 0 ? void 0 : onValueChange(newValue);
    };
    return (_jsxs("div", { className: "flex items-center w-full", children: [_jsxs(SliderPrimitive.Root, __assign({ ref: ref, className: cn("relative flex w-full touch-none select-none items-center", className) }, props, { value: value, onValueChange: handleValueChange, children: [_jsx(SliderPrimitive.Track, { className: "relative h-2 w-full grow overflow-hidden rounded-full bg-card cursor-pointer", children: _jsx(SliderPrimitive.Range, { className: "absolute h-full bg-accent" }) }), _jsx(SliderPrimitive.Thumb, { className: "block h-5 w-5 rounded-full hover:bg-accent border-2 active:scale-90 border-accent bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 cursor-grab" })] })), showValue && (_jsx("div", { className: "ml-4 min-w-[3ch] text-sm", children: value[0] }))] }));
});
Slider.displayName = SliderPrimitive.Root.displayName;
