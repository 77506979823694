export var fuzzyMatch = function (text, pattern, threshold) {
    if (threshold === void 0) { threshold = 0.9; }
    if (pattern.length <= 6) {
        // For very short search terms, use exact matching
        return text.toLowerCase().includes(pattern.toLowerCase());
    }
    var words = pattern.toLowerCase().split(/\s+/);
    var textLower = text.toLowerCase();
    var matchCount = 0;
    var lastIndex = -1;
    for (var _i = 0, words_1 = words; _i < words_1.length; _i++) {
        var word = words_1[_i];
        var index = textLower.indexOf(word, lastIndex + 1);
        if (index > -1) {
            matchCount++;
            lastIndex = index;
        }
    }
    var matchRatio = matchCount / words.length;
    return matchRatio >= threshold;
};
export var processSearches = function (doc, searchTerm, threshold) {
    if (threshold === void 0) { threshold = 0.9; }
    var results = [];
    var textNodesWithPosition = [];
    var index = 0;
    if (!searchTerm) {
        return [];
    }
    doc === null || doc === void 0 ? void 0 : doc.descendants(function (node, pos) {
        if (node.isText) {
            if (textNodesWithPosition[index]) {
                textNodesWithPosition[index] = {
                    text: textNodesWithPosition[index].text + node.text,
                    pos: textNodesWithPosition[index].pos,
                };
            }
            else {
                textNodesWithPosition[index] = {
                    text: "".concat(node.text),
                    pos: pos,
                };
            }
        }
        else {
            index += 1;
        }
    });
    textNodesWithPosition = textNodesWithPosition.filter(Boolean);
    for (var _i = 0, textNodesWithPosition_1 = textNodesWithPosition; _i < textNodesWithPosition_1.length; _i++) {
        var element = textNodesWithPosition_1[_i];
        var text = element.text, pos = element.pos;
        var chunkSize = Math.max(searchTerm.length, 100); // Adjust chunk size based on search term length
        if (searchTerm.length <= 6) {
            // For very short search terms, search the entire text without chunking
            var escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
            var matches = Array.from(text.matchAll(new RegExp(escapedSearchTerm, "gi")));
            for (var _a = 0, matches_1 = matches; _a < matches_1.length; _a++) {
                var match = matches_1[_a];
                results.push({
                    pos: pos,
                    from: pos + match.index,
                    to: pos + match.index + searchTerm.length,
                });
            }
        }
        else {
            // For longer search terms, use the chunking approach
            for (var i = 0; i < text.length; i += Math.floor(chunkSize / 2)) {
                var chunk = text.slice(i, i + chunkSize);
                if (fuzzyMatch(chunk, searchTerm, threshold)) {
                    results.push({
                        pos: pos,
                        from: pos + i,
                        to: pos + i + chunk.length,
                    });
                }
            }
        }
    }
    return results;
};
export var processExactSearches = function (doc, searchTerm) {
    var results = [];
    var textNodesWithPosition = [];
    var index = 0;
    doc.descendants(function (node, pos) {
        if (node.isText) {
            if (textNodesWithPosition[index]) {
                textNodesWithPosition[index] = {
                    text: textNodesWithPosition[index].text + node.text,
                    pos: textNodesWithPosition[index].pos,
                };
            }
            else {
                textNodesWithPosition[index] = {
                    text: "".concat(node.text),
                    pos: pos,
                };
            }
        }
        else {
            index += 1;
        }
    });
    textNodesWithPosition = textNodesWithPosition.filter(Boolean);
    for (var _i = 0, textNodesWithPosition_2 = textNodesWithPosition; _i < textNodesWithPosition_2.length; _i++) {
        var element = textNodesWithPosition_2[_i];
        var text = element.text, pos = element.pos;
        var escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        var matches = Array.from(text.matchAll(new RegExp(escapedSearchTerm, "g")));
        for (var _a = 0, matches_2 = matches; _a < matches_2.length; _a++) {
            var match = matches_2[_a];
            results.push({
                pos: pos,
                from: pos + match.index,
                to: pos + match.index + searchTerm.length,
            });
        }
    }
    return results;
};
