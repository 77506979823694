import { format, formatDistanceToNow, isToday, isYesterday } from "date-fns";
export var makeHeadTitle = function (title) {
    return "".concat(title, " | Cloudy");
};
export var makeHumanizedTime = function (date, options) {
    if (typeof date === "string") {
        date = new Date(date);
    }
    var now = new Date();
    var diffInSeconds = Math.floor((date.getTime() - now.getTime()) / 1000);
    var isInPast = diffInSeconds < 0;
    var absDiff = Math.abs(diffInSeconds);
    // Handle future dates
    if (!isInPast) {
        if (absDiff <= 30)
            return "in a few seconds";
        return formatDistanceToNow(date, {
            addSuffix: true,
            locale: options === null || options === void 0 ? void 0 : options.locale,
        });
    }
    // Handle past dates
    if (absDiff <= 30) {
        return "just now";
    }
    else if (absDiff < 60 * 60) {
        // Less than an hour ago
        if (options === null || options === void 0 ? void 0 : options.includeSeconds) {
            return formatDistanceToNow(date, {
                addSuffix: true,
                includeSeconds: true,
                locale: options === null || options === void 0 ? void 0 : options.locale,
            });
        }
        return formatDistanceToNow(date, {
            addSuffix: true,
            locale: options === null || options === void 0 ? void 0 : options.locale,
        });
    }
    else if (absDiff < 24 * 60 * 60) {
        if (options === null || options === void 0 ? void 0 : options.hoursOnly) {
            // Less than a day ago, use time format
            return format(date, "h:mm a");
        }
        // Less than a day ago, use "today" or "yesterday" with time
        if (isToday(date)) {
            return "Today at ".concat(format(date, "h:mm a"));
        }
        else if (isYesterday(date)) {
            return "Yesterday at ".concat(format(date, "h:mm a"));
        }
        return format(date, "MMM d 'at' h:mm a");
    }
    else {
        // More than a day ago, use date format
        var currentYear = new Date().getFullYear();
        return date.getFullYear() === currentYear ? format(date, "MMM d") : format(date, "MMM d, yyyy");
    }
};
export var capitalizeFirstLetter = function (text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
};
export var pluralize = function (count, noun) {
    return "".concat(count, " ").concat(noun).concat(count !== 1 ? "s" : "");
};
