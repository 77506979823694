var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Mark, mergeAttributes } from "@tiptap/core";
import { Extension, Node } from "@tiptap/core";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import Link from "@tiptap/extension-link";
import ListKeymap from "@tiptap/extension-list-keymap";
import Placeholder from "@tiptap/extension-placeholder";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import Typography from "@tiptap/extension-typography";
import Underline from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import { common, createLowlight } from "lowlight";
import { Markdown } from "tiptap-markdown";
// Import default theme
import { createCodeBlockPasteRule } from "src/utils/tiptapCodeBlockPasteRule";
import { PendingAttachmentNode } from "./PendingAttachment";
import { Mention, mention } from "./mention";
import ResizableImageExtension from "./resizableImageExtension";
export var IndentNode = Node.create({
    name: "indent",
    content: "block+",
    group: "block",
    addAttributes: function () {
        return {
            level: {
                default: 1,
                parseHTML: function (element) { return parseInt(element.getAttribute("data-level") || "1", 10); },
                renderHTML: function (attributes) { return ({ "data-level": attributes.level }); },
            },
        };
    },
    parseHTML: function () {
        return [{ tag: "div.indent" }];
    },
    renderHTML: function (_a) {
        var HTMLAttributes = _a.HTMLAttributes, node = _a.node;
        var level = node.attrs.level || 1;
        return ["div", __assign({ class: "indent indent-level-".concat(level) }, HTMLAttributes), 0];
    },
});
export var IndentExtension = Extension.create({
    name: "indentHandler",
    addKeyboardShortcuts: function () {
        return {
            Tab: function (_a) {
                var editor = _a.editor;
                var selection = editor.state.selection;
                var $from = selection.$from;
                // Check if we're at the start of a list item
                if (editor.isActive("listItem") && $from.parentOffset === 0) {
                    // Attempt to sink the list item
                    var sinkResult = editor.chain().sinkListItem("listItem").run();
                    // If sinking was successful, return true
                    if (sinkResult) {
                        return true;
                    }
                    // If sinking failed, we'll fall through to inserting a tab
                }
                var currentLevel = editor.isActive("indent") ? editor.getAttributes("indent").level : 0;
                if (currentLevel < 3) {
                    var newLevel = currentLevel + 1;
                    editor.commands.toggleWrap("indent", { level: newLevel });
                }
                // Prevent default behavior (losing focus)
                return true;
            },
            "Shift-Tab": function (_a) {
                var editor = _a.editor;
                var selection = editor.state.selection;
                var $from = selection.$from;
                // Check if we're at the start of a list item
                if (editor.isActive("listItem") && $from.parentOffset === 0) {
                    // If so, lift the list item
                    return editor.chain().liftListItem("listItem").run();
                }
                var currentLevel = editor.isActive("indent") ? editor.getAttributes("indent").level : 0;
                if (currentLevel === 1) {
                    editor.commands.lift("indent");
                }
                else if (currentLevel > 1) {
                    var newLevel = currentLevel - 1;
                    editor.commands.toggleWrap("indent", { level: newLevel });
                }
                // Prevent default behavior (losing focus)
                return true;
            },
        };
    },
});
// Define a custom CommentHighlight mark
var CommentHighlight = Mark.create({
    name: "commentHighlight",
    renderHTML: function (_a) {
        var HTMLAttributes = _a.HTMLAttributes;
        return ["span", mergeAttributes(HTMLAttributes, { class: "editor-comment-highlight" }), 0];
    },
});
var AdditionHighlight = Mark.create({
    name: "additionHighlight",
    renderHTML: function (_a) {
        var HTMLAttributes = _a.HTMLAttributes;
        return ["span", mergeAttributes(HTMLAttributes, { class: "editor-addition-highlight" }), 0];
    },
});
var RemovalHighlight = Mark.create({
    name: "removalHighlight",
    renderHTML: function (_a) {
        var HTMLAttributes = _a.HTMLAttributes;
        return ["span", mergeAttributes(HTMLAttributes, { class: "editor-removal-highlight" }), 0];
    },
});
var EditHighlight = Mark.create({
    name: "editHighlight",
    inclusive: true,
    spanning: true,
    renderHTML: function (_a) {
        var HTMLAttributes = _a.HTMLAttributes;
        return ["edit", HTMLAttributes, 0];
    },
    parseHTML: function () {
        return [{ tag: "edit" }];
    },
});
/**
 * Matches a code block with backticks.
 */
export var backtickInputRegex = /```([a-z]+)?\s*(.*?)```/;
/**
 * Matches a code block with tildes.
 */
export var tildeInputRegex = /^~~~([a-z]+)?[\s\n]$/;
var ExtendedCodeBlockLowlight = CodeBlockLowlight.extend({
    marks: "additionHighlight removalHighlight editHighlight",
    priority: 1000,
    addPasteRules: function () {
        return [
            createCodeBlockPasteRule({
                find: function (text, event) {
                    var rawMatch = text.match(backtickInputRegex);
                    return rawMatch ? [{ index: 0, text: rawMatch[0], match: rawMatch }] : null;
                },
                type: this.type,
            }),
        ];
    },
});
export var wrapSelectionAroundWords = function (editor) {
    var _a, _b;
    var selection = editor.state.selection;
    var nodeBeforeText = ((_a = selection.$from.nodeBefore) === null || _a === void 0 ? void 0 : _a.textContent) || "";
    var lastWordBefore = nodeBeforeText.split(" ").pop() || "";
    var newFrom = selection.from - lastWordBefore.length;
    var nodeAfterText = ((_b = selection.$to.nodeAfter) === null || _b === void 0 ? void 0 : _b.textContent) || "";
    var firstWordAfter = nodeAfterText.split(" ").shift() || "";
    var newTo = selection.to + firstWordAfter.length;
    return {
        from: newFrom,
        to: newTo,
    };
};
var lowlight = createLowlight(common);
export var tiptapExtensions = [
    StarterKit.configure({
        dropcursor: {
            color: "rgb(var(--color-accent) / 0.6)",
            width: 4,
        },
        history: false,
        codeBlock: false,
    }),
    Placeholder.configure({
        placeholder: "What are you thinking about?",
    }),
    Markdown.configure({
        transformPastedText: true,
    }),
    CommentHighlight,
    AdditionHighlight,
    RemovalHighlight,
    EditHighlight,
    Underline,
    TaskList.configure({
        HTMLAttributes: {
            class: "editor-task-list",
        },
    }),
    TaskItem.configure({
        nested: true,
        HTMLAttributes: {
            class: "editor-task-item",
        },
    }),
    Typography,
    ListKeymap,
    IndentExtension,
    IndentNode,
    Mention.configure({
        suggestion: mention,
    }),
    ResizableImageExtension,
    PendingAttachmentNode,
    Link.configure({
        autolink: true,
        linkOnPaste: true,
        protocols: ["http", "https", "mailto"],
    }),
    ExtendedCodeBlockLowlight.configure({
        lowlight: lowlight,
    }),
];
export var clearAllEditMarks = function (editor) {
    var currentSelection = editor.state.selection;
    editor
        .chain()
        .setTextSelection({ from: 0, to: editor.state.doc.content.size })
        .unsetMark("editHighlight")
        .setTextSelection(currentSelection)
        .run();
};
export var clearAllApplyMarks = function (editor) {
    var currentSelection = editor.state.selection;
    editor
        .chain()
        .setTextSelection({ from: 0, to: editor.state.doc.content.size })
        .unsetMark("additionHighlight")
        .unsetMark("removalHighlight")
        .setTextSelection(currentSelection)
        .run();
};
