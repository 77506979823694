import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FolderGit2Icon, FolderKanbanIcon, PlusIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "src/components/Button";
import { useWorkspace } from "src/stores/workspace";
import { makeNewProjectUrl } from "src/utils/projects";
import { useWorkspaceProjects } from "src/utils/workspaces";
export var ProjectsList = function () {
    var workspace = useWorkspace();
    var workspaceProjects = useWorkspaceProjects().data;
    return (_jsxs("div", { className: "flex flex-col", children: [_jsxs("div", { className: "flex items-center justify-between gap-1", children: [_jsx("span", { className: "mb-1 text-sm font-medium text-secondary", children: "Projects" }), _jsx(Link, { to: makeNewProjectUrl(workspace.slug), children: _jsx(Button, { variant: "ghost", size: "icon-sm", className: "text-secondary", children: _jsx(PlusIcon, { className: "size-4" }) }) })] }), workspaceProjects && workspaceProjects.length > 0 ? (workspaceProjects.map(function (project) { return (_jsx(Link, { to: "/workspaces/".concat(workspace.slug, "/projects/").concat(project.slug), children: _jsxs("div", { className: "flex w-full items-center gap-2 rounded px-2 py-1 text-sm hover:bg-card", children: [project.hasRepositoryConnection ? (_jsx(FolderGit2Icon, { className: "size-4" })) : (_jsx(FolderKanbanIcon, { className: "size-4" })), _jsx("span", { children: project.name })] }) }, project.id)); })) : (_jsx("div", { className: "mt-1 w-full rounded border border-dashed border-border px-4 py-1 text-center text-xs text-tertiary", children: "No projects yet" }))] }));
};
