import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Command, Option, ArrowUp, ArrowBigUp, ArrowDown, ArrowLeft, ArrowRight, CornerDownLeftIcon, } from "lucide-react";
import { cn } from "./utils";
var iconMap = {
    cmd: Command,
    command: Command,
    option: Option,
    shift: ArrowBigUp,
    alt: Option,
    up: ArrowUp,
    down: ArrowDown,
    left: ArrowLeft,
    right: ArrowRight,
    enter: CornerDownLeftIcon,
};
export var Hotkey = function (_a) {
    var keys = _a.keys, className = _a.className;
    return (_jsx("div", { className: cn("flex items-center gap-0.5", className), children: keys.map(function (key, index) {
            var IconComponent = iconMap[key.toLowerCase()];
            return (_jsx(React.Fragment, { children: _jsx("kbd", { className: cn("flex items-center justify-center text-xs font-sans font-normal text-secondary bg-background dark:text-primary dark:bg-white/20 dark:border-white/10 border border-border rounded", IconComponent ? "size-5" : "h-5"), children: IconComponent ? (_jsx(IconComponent, { className: "size-3" })) : (_jsx("span", { className: "px-1", children: key })) }) }, index));
        }) }));
};
export var Hotkeys = function (_a) {
    var shortcuts = _a.shortcuts, className = _a.className;
    return (_jsx("div", { className: cn("space-y-2", className), children: shortcuts.map(function (shortcut, index) { return (_jsxs("div", { className: "flex items-center justify-between", children: [_jsx("span", { className: "text-sm text-secondary", children: shortcut.label }), _jsx(Hotkey, { keys: shortcut.keys })] }, index)); }) }));
};
