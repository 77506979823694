var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";
import { cva } from "class-variance-authority";
import { XIcon } from "lucide-react";
import * as React from "react";
import { cn } from "src/utils";
import { Button } from "./Button";
var Dialog = AlertDialogPrimitive.Root;
var DialogTrigger = AlertDialogPrimitive.Trigger;
var DialogPortal = AlertDialogPrimitive.Portal;
var DialogOverlay = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (_jsx(AlertDialogPrimitive.Overlay, __assign({ className: cn("fixed inset-0 z-50 bg-background/20 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0", className) }, props, { ref: ref })));
});
DialogOverlay.displayName = AlertDialogPrimitive.Overlay.displayName;
var dialogVariants = cva("fixed z-50 gap-4 bg-background border flex flex-col rounded-lg border-border p-6 transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500", {
    variants: {
        position: {
            center: "left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]",
            // top: "left-[50%] top-[5%] translate-x-[-50%] rounded-b-lg data-[state=open]:slide-in-from-top-0",
            // bottom: "left-[50%] bottom-[5%] translate-x-[-50%] rounded-t-lg data-[state=open]:slide-in-from-bottom-0",
            // left: "left-4 top-[50%] translate-y-[-50%] rounded-r-lg data-[state=open]:slide-in-from-left-0",
            // right: "right-4 top-[50%] translate-y-[-50%] rounded-l-lg data-[state=open]:slide-in-from-right-0",
        },
        size: {
            sm: "w-full max-w-sm",
            md: "w-full max-w-md",
            lg: "w-full max-w-lg",
            xl: "w-full max-w-xl",
            full: "w-[95vw] h-[95vh]",
        },
    },
    defaultVariants: {
        position: "center",
        size: "md",
    },
});
var DialogContent = React.forwardRef(function (_a, ref) {
    var className = _a.className, position = _a.position, size = _a.size, onClose = _a.onClose, children = _a.children, props = __rest(_a, ["className", "position", "size", "onClose", "children"]);
    return (_jsxs(DialogPortal, { children: [_jsx(DialogOverlay, {}), _jsxs(AlertDialogPrimitive.Content, __assign({ ref: ref, className: cn(dialogVariants({ position: position, size: size }), className) }, props, { children: [children, onClose && (_jsxs(AlertDialogPrimitive.Cancel, { className: "focus:ring-ring absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-secondary", onClick: onClose, children: [_jsx(XIcon, { className: "h-4 w-4" }), _jsx("span", { className: "sr-only", children: "Close" })] }))] }))] }));
});
DialogContent.displayName = AlertDialogPrimitive.Content.displayName;
var DialogHeader = function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (_jsx("div", __assign({ className: cn("flex flex-col space-y-1.5 text-center sm:text-left", className) }, props)));
};
DialogHeader.displayName = "DialogHeader";
var DialogFooter = function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (_jsx("div", __assign({ className: cn("flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2", className) }, props)));
};
DialogFooter.displayName = "DialogFooter";
var DialogTitle = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (_jsx(AlertDialogPrimitive.Title, __assign({ ref: ref, className: cn("text-lg font-semibold leading-none tracking-tight", className) }, props)));
});
DialogTitle.displayName = AlertDialogPrimitive.Title.displayName;
var DialogDescription = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (_jsx(AlertDialogPrimitive.Description, __assign({ ref: ref, className: cn("text-sm text-secondary", className) }, props)));
});
DialogDescription.displayName = AlertDialogPrimitive.Description.displayName;
var DialogCancel = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (_jsx(AlertDialogPrimitive.Cancel, __assign({ ref: ref, className: cn("focus:ring-ring mt-2 inline-flex h-10 items-center justify-center rounded-md bg-background px-4 py-2 text-sm font-semibold ring-offset-background transition-colors hover:bg-card focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 sm:mt-0", className) }, props)));
});
DialogCancel.displayName = AlertDialogPrimitive.Cancel.displayName;
var DialogAction = React.forwardRef(function (_a, ref) {
    var className = _a.className, _b = _a.destructive, destructive = _b === void 0 ? false : _b, props = __rest(_a, ["className", "destructive"]);
    return (_jsx(AlertDialogPrimitive.Action, __assign({ asChild: true, ref: ref }, props, { children: _jsx(Button, { variant: destructive ? "destructive" : "default", className: cn("focus:ring-ring inline-flex h-10 items-center justify-center rounded-md px-4 py-2 text-sm font-semibold ring-offset-background transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50", className), children: props.children }) })));
});
DialogAction.displayName = AlertDialogPrimitive.Action.displayName;
export { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogFooter, DialogTitle, DialogDescription, DialogCancel, DialogAction, };
