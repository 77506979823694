import { jsx as _jsx } from "react/jsx-runtime";
import { cn } from "src/utils";
import LoadingSpinner from "./LoadingSpinner";
export var SimpleLayout = function (_a) {
    var children = _a.children, isLoading = _a.isLoading, className = _a.className;
    return (_jsx("div", { className: cn("no-scrollbar box-border flex flex-1 flex-col overflow-y-scroll px-6 md:px-16 lg:px-20", className), children: isLoading ? (_jsx("div", { className: "flex h-[calc(100vh-20rem)] flex-col items-center justify-center", children: _jsx(LoadingSpinner, {}) })) : (children) }));
};
export var SimpleLayoutView = function (_a) {
    var children = _a.children, className = _a.className;
    return _jsx("div", { className: cn("flex flex-col bg-background", className), children: children });
};
SimpleLayout.View = SimpleLayoutView;
