export var handleSupabaseError = function (response) {
    if (response.error) {
        console.error("Supabase error:", response.error.message, "Details:", response.error.details, "Hint:", response.error.hint);
        throw new Error("Database operation failed: ".concat(response.error.message));
    }
    return response.data;
};
export function fixOneToOne(objectOrNull) {
    return objectOrNull || null;
}
