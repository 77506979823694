import { useQuery } from "@tanstack/react-query";
import { apiClient } from "src/api/client";
import { paymentsQueryKeys } from "src/api/queryKeys";
import { useWorkspaceStore } from "src/stores/workspace";
export var useCustomerStatus = function () {
    var workspace = useWorkspaceStore().workspace;
    return useQuery({
        queryKey: paymentsQueryKeys.customerStatus(workspace === null || workspace === void 0 ? void 0 : workspace.slug),
        queryFn: function () {
            return apiClient
                .get("/api/payments/customers/status", {
                params: { wsSlug: workspace.slug },
            })
                .then(function (res) {
                return res.data;
            });
        },
        enabled: Boolean(workspace),
        retry: 3,
        refetchInterval: 1000 * 60,
    });
};
