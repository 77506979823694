import { jsx as _jsx } from "react/jsx-runtime";
import { XIcon } from "lucide-react";
import { ToastContainer as BaseToastContainer, Bounce } from "react-toastify";
import { cn } from "src/utils";
import { Button } from "./Button";
export var ToastContainer = function () {
    return (_jsx(BaseToastContainer, { position: "bottom-center", autoClose: 6000, hideProgressBar: false, newestOnTop: true, closeOnClick: true, rtl: false, pauseOnFocusLoss: true, draggable: true, pauseOnHover: true, transition: Bounce, icon: false, closeButton: function (_a) {
            var closeToast = _a.closeToast;
            return (_jsx("div", { className: "absolute right-2 top-0 flex h-full items-center", children: _jsx(Button, { variant: "ghost", size: "icon-xs", className: "text-secondary", onClick: closeToast, children: _jsx(XIcon, { className: "h-4 w-4" }) }) }));
        }, className: "flex flex-col gap-2", toastClassName: function (props) {
            return cn(
            // props?.defaultClassName,
            "relative flex min-h-10 cursor-pointer justify-between overflow-hidden rounded-md border border-border bg-background p-1 shadow-sm");
        }, bodyClassName: function (props) {
            return cn(props === null || props === void 0 ? void 0 : props.defaultClassName, "block flex flex-row items-center p-3 font-sans text-sm text-primary");
        }, progressClassName: function (props) { return cn(props === null || props === void 0 ? void 0 : props.defaultClassName, "h-[2px] bg-accent/40"); } }));
};
