import { diffLines } from "diff";
import { processExactSearches } from "src/utils/tiptapSearchAndReplace";
export var ADDITION_MARKER = "-$$-";
export var REMOVAL_MARKER = "-##-";
var setMark = function (markName, startPos, endPos, isCodeBlock, editor) {
    var chain = editor.chain();
    chain.setTextSelection({ from: startPos.from, to: endPos.to }).setMark(markName);
    if (!isCodeBlock) {
        chain.setTextSelection(endPos).selectParentNode();
    }
    else {
        chain.setTextSelection({
            from: endPos.from - 2, // Offset for newlines
            to: endPos.to + 2,
        });
    }
    chain.deleteSelection();
    if (!isCodeBlock) {
        chain.setTextSelection(startPos).selectParentNode();
    }
    else {
        chain.setTextSelection({
            from: startPos.from - 2, // Offset for newlines
            to: startPos.to + 2,
        });
    }
    chain.deleteSelection();
    chain.run();
};
var convertMarkersToTiptapMarks = function (marker, markName, editor) {
    var results = processExactSearches(editor.state.doc, "".concat(marker));
    var maxIterations = 20;
    var i = 0;
    while (results.length > 0 && i < maxIterations) {
        var startPos = results[0];
        var endPos = results[1];
        if (startPos && endPos) {
            var resolvedPos = editor.state.doc.resolve(startPos.from);
            var isCodeBlock = resolvedPos.parent.type.name === "codeBlock";
            setMark(markName, startPos, endPos, isCodeBlock, editor);
        }
        i++;
        // Get fresh positions after the edits
        results = processExactSearches(editor.state.doc, marker);
    }
};
export var showDiffInEditor = function (originalMd, newMd, editor) {
    // Ideally we'd do words here, but it'll be very expensive.
    var diff = diffLines(originalMd, newMd, {});
    var diffMd = "";
    // Wrap the parts in special markers
    diff.forEach(function (part) {
        if (part.added) {
            diffMd += "\n\n".concat(ADDITION_MARKER, "\n\n");
            diffMd += part.value;
            diffMd += "\n\n".concat(ADDITION_MARKER, "\n\n");
        }
        else if (part.removed) {
            diffMd += "\n\n".concat(REMOVAL_MARKER, "\n\n");
            diffMd += part.value;
            diffMd += "\n\n".concat(REMOVAL_MARKER, "\n\n");
        }
        else {
            diffMd += part.value;
        }
    });
    editor.commands.setContent(diffMd);
    convertMarkersToTiptapMarks(ADDITION_MARKER, "additionHighlight", editor);
    convertMarkersToTiptapMarks(REMOVAL_MARKER, "removalHighlight", editor);
};
