import { jsx as _jsx } from "react/jsx-runtime";
import { cva } from "class-variance-authority";
import { UserIcon } from "lucide-react";
import { cn } from "src/utils";
var avatarVariants = cva("flex items-center justify-center rounded-full", {
    variants: {
        size: {
            xs: "size-5",
            sm: "size-6",
            md: "size-8",
            lg: "size-10",
        },
    },
    defaultVariants: {
        size: "md",
    },
});
var iconVariants = cva("", {
    variants: {
        size: {
            xs: "size-4",
            sm: "size-5",
            md: "size-6",
            lg: "size-7",
        },
    },
    defaultVariants: {
        size: "md",
    },
});
var textVariants = cva("text-sm font-medium text-secondary", {
    variants: {
        size: {
            xs: "text-[0.57rem] border border-border",
            sm: "text-xs",
            md: "text-sm",
            lg: "text-base",
        },
    },
    defaultVariants: {
        size: "md",
    },
});
export var Avatar = function (_a) {
    var src = _a.src, fallback = _a.fallback, size = _a.size, className = _a.className;
    if (src) {
        return _jsx("img", { src: src, alt: "Avatar", className: cn("object-cover", avatarVariants({ size: size, className: className })) });
    }
    if (!fallback) {
        return (_jsx("div", { className: cn("bg-card text-secondary", avatarVariants({ size: size, className: className })), children: _jsx(UserIcon, { className: iconVariants({ size: size }) }) }));
    }
    return (_jsx("div", { className: cn("bg-card", textVariants({ size: size, className: className }), avatarVariants({ size: size, className: className })), children: fallback.slice(0, 2).toUpperCase() }));
};
