var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Hotkey } from "@cloudy/ui";
import { handleSupabaseError } from "@cloudy/utils/common";
import { useQuery } from "@tanstack/react-query";
import { ArrowRightIcon, NotebookTextIcon, PlusIcon } from "lucide-react";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { supabase } from "src/clients/supabase";
import LoadingSpinner from "src/components/LoadingSpinner";
import { useWorkspace } from "src/stores/workspace";
import { cn } from "src/utils";
import { makeHumanizedTime } from "src/utils/strings";
import { makeThoughtLabel } from "src/utils/thought";
import { useEditThought } from "./hooks";
var useSearchThoughts = function (query) {
    var workspace = useWorkspace();
    return useQuery({
        enabled: !!query && query.length > 2,
        queryKey: ["thoughts", query],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var results, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase
                                .rpc("search_thoughts", {
                                search_query: query,
                                p_workspace_id: workspace.id,
                            })
                                .order("thought_updated_at", { ascending: false })];
                    case 1:
                        results = _a.apply(void 0, [_b.sent()]);
                        return [2 /*return*/, results.map(function (t) { return ({
                                id: t.thought_id,
                                title: t.thought_title,
                                content_md: t.thought_content_md,
                                content_plaintext: t.thought_content_plaintext,
                                updated_at: t.thought_updated_at,
                            }); })];
                }
            });
        }); },
        staleTime: 1000,
    });
};
var useLatestThoughts = function () {
    var workspace = useWorkspace();
    return useQuery({
        queryKey: [workspace.slug, "latest_thoughts"],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var results, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase
                                .from("thoughts")
                                .select("id, title, content_md, content_plaintext, updated_at")
                                .eq("workspace_id", workspace.id)
                                .order("updated_at", { ascending: false })
                                .limit(8)];
                    case 1:
                        results = _a.apply(void 0, [_b.sent()]);
                        return [2 /*return*/, results];
                }
            });
        }); },
    });
};
var menuOptions = ["latest_notes", "new_note"];
export var MentionHandler = forwardRef(function (_a, ref) {
    var _b;
    var query = _a.query, command = _a.command;
    var _c = useSearchThoughts(query), data = _c.data, isLoadingThoughtSearch = _c.isLoading;
    var _d = useLatestThoughts(), latestThoughts = _d.data, isLoadingLatestThoughts = _d.isLoading;
    var editThoughtMutation = useEditThought();
    var _e = useState("default"), currentView = _e[0], setCurrentView = _e[1];
    var _f = useState(0), selectedIndex = _f[0], setSelectedIndex = _f[1];
    var displayingData = (_b = (currentView === "search" ? data : latestThoughts)) !== null && _b !== void 0 ? _b : [];
    var upHandler = function () {
        if (currentView === "default") {
            setSelectedIndex((selectedIndex + menuOptions.length - 1) % menuOptions.length);
            return;
        }
        if (displayingData.length === 0) {
            return;
        }
        setSelectedIndex((selectedIndex + displayingData.length - 1) % displayingData.length);
    };
    var downHandler = function () {
        if (currentView === "default") {
            setSelectedIndex((selectedIndex + 1) % menuOptions.length);
            return;
        }
        if (displayingData.length === 0) {
            return;
        }
        setSelectedIndex((selectedIndex + 1) % displayingData.length);
    };
    var apply = function (index) {
        var selectedThought = displayingData[index];
        command({
            id: selectedThought.id,
            label: makeThoughtLabel(selectedThought),
        });
    };
    var enterHandler = function () {
        if (currentView === "default") {
            if (selectedIndex === 0) {
                setCurrentView("latest_notes");
            }
            else if (selectedIndex === 1) {
                editThoughtMutation.mutateAsync().then(function (newThought) {
                    if (newThought) {
                        command({
                            id: newThought.id,
                            label: "Untitled",
                        });
                    }
                });
            }
        }
        else if (currentView === "search") {
            apply(selectedIndex);
        }
        else if (currentView === "latest_notes") {
            apply(selectedIndex);
        }
    };
    useEffect(function () { return setSelectedIndex(0); }, [data, currentView]);
    useEffect(function () {
        if (data && data.length > 0) {
            setCurrentView("search");
        }
    }, [data]);
    useEffect(function () {
        if (query && query.length > 0) {
            setCurrentView("search");
        }
        else {
            setCurrentView("default");
        }
    }, [query]);
    useImperativeHandle(ref, function () { return ({
        onKeyDown: function (_a) {
            var event = _a.event, hide = _a.hide;
            if (event.key === "ArrowUp") {
                upHandler();
                return true;
            }
            if (event.key === "ArrowDown") {
                downHandler();
                return true;
            }
            if (event.key === "Enter") {
                enterHandler();
                return true;
            }
            if (event.key === "Escape") {
                if (currentView === "latest_notes") {
                    setCurrentView("default");
                    return true;
                }
                hide();
                return true;
            }
            return false;
        },
    }); });
    var title;
    switch (currentView) {
        case "search":
            title = isLoadingThoughtSearch ? "Searching..." : "Viewing search results";
            break;
        case "latest_notes":
            title = isLoadingLatestThoughts ? "Searching..." : "Viewing latest notes";
            break;
        default:
            title = "Link a note, type to search for notes.";
    }
    var viewMap = {
        default: (_jsxs(_Fragment, { children: [_jsxs("div", { className: cn("flex w-full cursor-pointer items-center gap-2 rounded-sm px-2 py-1 text-sm hover:bg-card", selectedIndex === 0 && "bg-accent/10"), onClick: function () { return setCurrentView("latest_notes"); }, children: [_jsx(ArrowRightIcon, { className: "size-4 flex-shrink-0 text-secondary" }), _jsx("span", { className: "flex-1", children: "View latest notes" }), _jsxs("div", { className: "flex flex-shrink-0 items-center gap-1 text-xs text-secondary", children: [_jsx(Hotkey, { keys: ["enter"] }), _jsx("span", { children: "Open" })] })] }), _jsxs("div", { className: cn("flex w-full cursor-pointer items-center gap-2 rounded-sm px-2 py-1 text-sm hover:bg-card", selectedIndex === 1 && "bg-accent/10"), onClick: function () { }, children: [_jsx(PlusIcon, { className: "size-4 flex-shrink-0 text-secondary" }), _jsx("span", { className: "flex-1", children: "New note" }), _jsxs("div", { className: "flex flex-shrink-0 items-center gap-1 text-xs text-secondary", children: [_jsx(Hotkey, { keys: ["enter"] }), _jsx("span", { children: "Create new note" })] })] })] })),
        latest_notes: (_jsx(ResultsView, { displayingData: latestThoughts !== null && latestThoughts !== void 0 ? latestThoughts : [], selectedIndex: selectedIndex, apply: apply, isLoading: isLoadingLatestThoughts })),
        search: (_jsx(ResultsView, { displayingData: data !== null && data !== void 0 ? data : [], selectedIndex: selectedIndex, apply: apply, isLoading: isLoadingThoughtSearch })),
    };
    return (_jsxs("div", { className: "w-full rounded-md border border-border bg-background p-2 shadow-md md:w-[28rem]", children: [_jsx("div", { className: "mb-2 ml-1 text-sm text-secondary", children: title }), viewMap[currentView]] }));
});
var ResultsView = function (_a) {
    var displayingData = _a.displayingData, selectedIndex = _a.selectedIndex, apply = _a.apply, isLoading = _a.isLoading;
    if (isLoading) {
        return (_jsx("div", { children: _jsx(LoadingSpinner, { size: "xs" }) }));
    }
    if (displayingData.length === 0) {
        return _jsx("div", { className: "p-2 text-center text-sm text-secondary", children: "No results found." });
    }
    return (_jsx(_Fragment, { children: displayingData.map(function (thought, index) { return (_jsxs("div", { className: cn("flex w-full cursor-pointer items-center gap-2 rounded-sm px-2 py-1 hover:bg-card", selectedIndex === index ? "bg-accent/10" : ""), onClick: function () { return apply(index); }, children: [_jsx(NotebookTextIcon, { className: "size-4 flex-shrink-0 text-secondary" }), _jsxs("div", { className: "flex min-w-0 flex-1 flex-col", children: [_jsx("span", { className: "text-xs text-secondary", children: makeHumanizedTime(thought.updated_at) }), _jsx("span", { className: cn("truncate text-sm", thought.title ? "font-medium" : "text-primary/80"), children: thought.title || thought.content_plaintext || thought.content_md })] }), _jsxs("div", { className: "flex flex-shrink-0 items-center gap-1 text-xs text-secondary", children: [_jsx(Hotkey, { keys: ["enter"] }), _jsx("span", { children: "Link" })] })] }, thought.id)); }) }));
};
