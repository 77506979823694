import { createContext } from "react";
export var ThoughtContext = createContext({
    thoughtId: "",
    isDocumentLoading: false,
    isConnected: false,
    editor: null,
    isEditingDisabled: false,
    previewingKey: null,
    disableUpdatesRef: { current: false },
    hideControlColumn: false,
    isShowingAiEditorMenu: false,
    isShowingAiSelectionMenu: false,
    isAiWriting: false,
    threadId: null,
    onUpdate: function () { },
    setIsAiWriting: function () { },
    setIsEditingDisabled: function () { },
    setPreviewingKey: function () { },
    storeContentIfNeeded: function () { },
    restoreFromLastContent: function () { },
    clearStoredContent: function () { },
    setHideControlColumn: function () { },
    setShowAiEditorMenu: function () { },
    showAiEditor: function () { },
    hideAiEditor: function () { },
    applySuggestedChanges: function () { },
    onStartAiEdits: function () { },
    onFinishAiEdits: function () { },
    setThreadId: function () { },
    convertSelectionToEditMark: function () { },
    hideAiSelectionMenu: function () { },
    showAiSelectionMenu: function () { },
    storeContentAsApplyContent: function () { },
    clearApplyContent: function () { },
});
export var AiGenerationContext = createContext({
    isGenerating: false,
});
