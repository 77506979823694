var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ellipsizeText } from "@cloudy/utils/common";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useWorkspaceStore } from "src/stores/workspace";
import { useProject } from "src/views/projects/ProjectContext";
import { useEditThought } from "src/views/thoughtDetail/hooks";
export var makeThoughtUrl = function (wsSlug, thoughtId) {
    return "/workspaces/".concat(wsSlug, "/thoughts/").concat(thoughtId);
};
export var makeProjectDocUrl = function (wsSlug, projectSlug, docId) {
    return "/workspaces/".concat(wsSlug, "/projects/").concat(projectSlug, "/docs/").concat(docId);
};
export var makeDocUrl = function (components) {
    if (components.projectSlug) {
        return makeProjectDocUrl(components.workspaceSlug, components.projectSlug, components.docId);
    }
    return makeThoughtUrl(components.workspaceSlug, components.docId);
};
export var makeThoughtLabel = function (thought) {
    return ellipsizeText(thought.title || thought.content_plaintext || thought.content_md || "Untitled", 36);
};
export var useCreateThought = function () {
    var workspace = useWorkspaceStore(function (s) { return s.workspace; });
    var project = useProject();
    var editThoughtMutation = useEditThought();
    var navigate = useNavigate();
    return useMutation({
        mutationFn: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
            var newThought;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!workspace) {
                            throw new Error("Workspace not found");
                        }
                        return [4 /*yield*/, editThoughtMutation.mutateAsync({
                                collectionId: payload.collectionId,
                                ts: new Date(),
                            })];
                    case 1:
                        newThought = _a.sent();
                        return [2 /*return*/, newThought];
                }
            });
        }); },
        onError: function (e) {
            console.error(e);
            toast.error("Failed to create thought");
        },
        onSuccess: function (newThought) {
            if (workspace && newThought) {
                navigate(makeDocUrl({ workspaceSlug: workspace.slug, projectSlug: project === null || project === void 0 ? void 0 : project.slug, docId: newThought.id }));
            }
        },
    });
};
