import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState } from "react";
var ThemeContext = createContext(undefined);
export var ThemeProvider = function (_a) {
    var children = _a.children;
    var _b = useState(function () {
        if (typeof window === "undefined")
            return "light";
        // Check localStorage first
        if (localStorage.theme === "dark")
            return "dark";
        if (localStorage.theme === "light")
            return "light";
        // Fall back to system preference
        return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
    }), theme = _b[0], setTheme = _b[1];
    useEffect(function () {
        // Update document data-theme attribute
        document.documentElement.setAttribute("data-theme", theme);
        // Keep the dark class for other styles that might depend on it
        if (theme === "dark") {
            document.documentElement.classList.add("dark");
        }
        else {
            document.documentElement.classList.remove("dark");
        }
    }, [theme]);
    var toggleTheme = function () {
        var newTheme = theme === "light" ? "dark" : "light";
        setTheme(newTheme);
        localStorage.theme = newTheme;
    };
    var value = {
        theme: theme,
        setTheme: setTheme,
        toggleTheme: toggleTheme,
        isDark: theme === "dark",
    };
    return _jsx(ThemeContext.Provider, { value: value, children: children });
};
export var useTheme = function () {
    var context = useContext(ThemeContext);
    if (context === undefined) {
        throw new Error("useThemeContext must be used within a ThemeProvider");
    }
    return context;
};
export var useCodeThemeClass = function () {
    var isDark = useTheme().isDark;
    return isDark ? "code-theme-github-dark" : "code-theme-github";
};
