import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Hotkey } from "@cloudy/ui";
import { ChevronsDownIcon, ChevronsUpIcon } from "lucide-react";
import { useContext } from "react";
import { Button } from "src/components/Button";
import { useBreakpoint } from "src/utils/tailwind";
import { ThoughtContext } from "./thoughtContext";
export var FooterRow = function () {
    var _a = useContext(ThoughtContext), hideControlColumn = _a.hideControlColumn, setHideControlColumn = _a.setHideControlColumn, showAiEditor = _a.showAiEditor, isShowingAiEditorMenu = _a.isShowingAiEditorMenu;
    var isMdBreakpoint = useBreakpoint("md");
    return (_jsx("div", { className: "pointer-events-none sticky bottom-0 z-10 flex w-full items-center justify-center p-4 md:justify-between", children: _jsxs("div", { className: "pointer-events-auto", children: [_jsx("div", { children: !isShowingAiEditorMenu && (_jsxs(Button, { size: "sm", onClick: showAiEditor, children: [isMdBreakpoint && _jsx(Hotkey, { keys: ["Command", "O"] }), _jsx("span", { children: "Chat" })] })) }), _jsx("div", { className: "block lg:hidden", children: hideControlColumn ? (_jsxs(Button, { className: "bg-background text-secondary", variant: "outline", size: "sm", onClick: function () { return setHideControlColumn(false); }, children: [_jsx(ChevronsUpIcon, { className: "size-5" }), _jsx("span", { children: "Show panel" })] })) : (_jsxs(Button, { className: "bg-background text-secondary", variant: "outline", size: "sm", onClick: function () { return setHideControlColumn(true); }, children: [_jsx(ChevronsDownIcon, { className: "size-5" }), _jsx("span", { children: "Hide panel" })] })) })] }) }));
};
