import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CircleHelpIcon, HandshakeIcon, LogOutIcon, MenuIcon, MoonIcon, ScrollTextIcon, SunIcon } from "lucide-react";
import { supabase } from "src/clients/supabase";
import { Button } from "src/components/Button";
import { Dropdown, DropdownItem } from "src/components/Dropdown";
import { useTheme } from "src/stores/theme";
export var SidebarDropdown = function () {
    var _a = useTheme(), theme = _a.theme, toggleTheme = _a.toggleTheme;
    var handleSignOut = function () {
        supabase.auth.signOut();
    };
    return (_jsxs(Dropdown, { trigger: _jsx(Button, { variant: "ghost", size: "icon", "aria-label": "New thought", children: _jsx(MenuIcon, { size: 24 }) }), className: "w-64 pt-2", children: [_jsxs(DropdownItem, { onSelect: toggleTheme, children: [theme === "light" ? _jsx(MoonIcon, { className: "size-4" }) : _jsx(SunIcon, { className: "size-4" }), _jsx("span", { children: theme === "light" ? "Dark Mode" : "Light Mode" })] }), _jsx("div", { className: "my-2 border-b border-border" }), _jsx("a", { href: "https://usecloudy.com/support", children: _jsxs(DropdownItem, { children: [_jsx(CircleHelpIcon, { className: "size-4" }), _jsx("span", { children: "Support" })] }) }), _jsx("a", { href: "https://usecloudy.com/pp", children: _jsxs(DropdownItem, { children: [_jsx(HandshakeIcon, { className: "size-4" }), _jsx("span", { children: "Privacy Policy" })] }) }), _jsx("a", { href: "https://usecloudy.com/tos", children: _jsxs(DropdownItem, { children: [_jsx(ScrollTextIcon, { className: "size-4" }), _jsx("span", { children: "Terms of Service" })] }) }), _jsx("div", { className: "my-2 border-b border-border" }), _jsxs(DropdownItem, { onSelect: handleSignOut, className: "text-red-600", children: [_jsx(LogOutIcon, { className: "size-4" }), _jsx("span", { children: "Sign out" })] })] }));
};
