var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { handleSupabaseError } from "@cloudy/utils/common";
import { useEffect } from "react";
import { Navigate, Outlet, useNavigate, useParams } from "react-router-dom";
import { useAsync } from "react-use";
import { supabase } from "src/clients/supabase";
import { useUser, useUserOptions } from "src/stores/user";
import { useWorkspaceStore } from "src/stores/workspace";
import { LoadingView } from "../loading/LoadingView";
import { SubscriptionModal } from "../pricing/PaymentGuard";
import { useProjectStore } from "../projects/ProjectContext";
var useWorkspaceSlug = function (wsSlug) {
    var user = useUser();
    var _a = useWorkspaceStore(), workspace = _a.workspace, role = _a.role, setWorkspace = _a.setWorkspace, setRole = _a.setRole;
    var navigate = useNavigate();
    useAsync(function () { return __awaiter(void 0, void 0, void 0, function () {
        var workspace_1, _a, role_1, _b, error_1;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 3, , 4]);
                    _a = handleSupabaseError;
                    return [4 /*yield*/, supabase.from("workspaces").select("*").eq("slug", wsSlug).single()];
                case 1:
                    workspace_1 = _a.apply(void 0, [_c.sent()]);
                    _b = handleSupabaseError;
                    return [4 /*yield*/, supabase
                            .from("workspace_users")
                            .select("role")
                            .eq("user_id", user.id)
                            .eq("workspace_id", workspace_1.id)
                            .single()];
                case 2:
                    role_1 = _b.apply(void 0, [_c.sent()]).role;
                    setWorkspace(workspace_1);
                    setRole(role_1);
                    return [2 /*return*/, workspace_1];
                case 3:
                    error_1 = _c.sent();
                    console.error(error_1);
                    navigate("/404");
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [wsSlug, user.id]);
    return { isReady: Boolean(workspace && role), workspace: workspace, role: role };
};
export var WorkspaceLayout = function () {
    var _a = useParams(), wsSlug = _a.wsSlug, projectSlug = _a.projectSlug;
    var userOptions = useUserOptions();
    var isReady = useWorkspaceSlug(wsSlug).isReady;
    var setProject = useProjectStore().setProject;
    useEffect(function () {
        if (wsSlug && wsSlug !== "undefined") {
            userOptions.set("last_opened_workspace", wsSlug);
        }
    }, [wsSlug]);
    useEffect(function () {
        if (!projectSlug) {
            // Clear project when leaving project view
            setProject(null);
        }
    }, [projectSlug, setProject]);
    if (!wsSlug || wsSlug === "undefined") {
        return _jsx(Navigate, { to: "/" });
    }
    if (!isReady) {
        return _jsx(LoadingView, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(Outlet, {}), _jsx(SubscriptionModal, {})] }));
};
