import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Check, ChevronDown } from "lucide-react";
import { useState } from "react";
import { cn } from "../utils";
import { Button } from "./Button";
import { Dropdown, DropdownItem } from "./Dropdown";
export var SelectDropdown = function (_a) {
    var options = _a.options, value = _a.value, onChange = _a.onChange, _b = _a.placeholder, placeholder = _b === void 0 ? "Select an option" : _b, className = _a.className, _c = _a.size, size = _c === void 0 ? "default" : _c, _d = _a.disabled, disabled = _d === void 0 ? false : _d;
    var _e = useState(false), isOpen = _e[0], setIsOpen = _e[1];
    var selectedOption = options.find(function (option) { return option.value === value; });
    var handleSelect = function (optionValue) {
        var option = options.find(function (opt) { return opt.value === optionValue; });
        if (option && !option.disabled) {
            onChange(optionValue);
            setIsOpen(false);
        }
    };
    return (_jsx(Dropdown, { trigger: _jsxs(Button, { variant: "outline", className: cn("inline-flex items-center justify-between", className), size: size, onClick: function () { return setIsOpen(!isOpen); }, disabled: disabled, children: [selectedOption ? (_jsxs("div", { className: "flex items-center gap-1", children: [selectedOption.icon, selectedOption.label] })) : (placeholder), _jsx(ChevronDown, { className: cn("ml-2 h-4 w-4 shrink-0 transition-transform", isOpen && "rotate-180") })] }), align: "start", className: "max-h-[50dvh] overflow-y-auto overflow-x-hidden", onClose: function () { return setIsOpen(false); }, children: options.map(function (option) { return (_jsxs(DropdownItem, { onSelect: function () { return handleSelect(option.value); }, className: cn("flex items-center justify-between", option.disabled && "cursor-not-allowed opacity-50"), disabled: option.disabled, children: [_jsxs("span", { className: "flex items-center", children: [option.icon, option.label] }), option.value === value && _jsx(Check, { className: "h-4 w-4 text-accent" })] }, option.value)); }) }));
};
