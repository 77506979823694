var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { VALID_WORKSPACE_SLUG_CHARS } from "@cloudy/utils/common";
import { Input } from "src/components/Input";
export var NameAndSlugFields = function (_a) {
    var register = _a.register, errors = _a.errors, isSlugAvailable = _a.isSlugAvailable, handleSlugChange = _a.handleSlugChange;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex flex-col gap-1", children: [_jsx("label", { htmlFor: "name", className: "font-medium", children: "Workspace Name" }), _jsx(Input, __assign({}, register("name", { required: "Workspace name is required" }), { placeholder: "Brain Fog Inc.", error: !!errors.name })), errors.name && _jsx("p", { className: "mt-1 text-sm text-red-500", children: errors.name.message })] }), _jsxs("div", { className: "flex flex-col gap-1", children: [_jsx("label", { htmlFor: "slug", className: "font-medium", children: "Workspace Slug" }), _jsx("div", { className: "flex items-center", children: _jsx(Input, __assign({ prefix: "app.usecloudy.com/workspaces/" }, register("slug", {
                            required: "Slug is required",
                            pattern: {
                                value: VALID_WORKSPACE_SLUG_CHARS,
                                message: "Slug can only contain lowercase letters, numbers, and hyphens",
                            },
                            validate: function (value) { return isSlugAvailable === true || "This slug is already taken"; },
                        }), { placeholder: "brain-fog-inc", className: "flex-grow", error: !!errors.slug, onChange: handleSlugChange })) }), errors.slug && _jsx("p", { className: "mt-1 text-sm text-red-500", children: errors.slug.message }), isSlugAvailable === true && _jsx("p", { className: "mt-1 text-xs text-green-600", children: "This slug is available" }), isSlugAvailable === false && _jsx("p", { className: "mt-1 text-xs text-red-600", children: "This slug is already taken" })] })] }));
};
