var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { handleSupabaseError } from "@cloudy/utils/common";
import { VALID_WORKSPACE_SLUG_CHARS } from "@cloudy/utils/common";
import { SiGithub } from "@icons-pack/react-simple-icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { XIcon } from "lucide-react";
import { useState } from "react";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { apiClient } from "src/api/client";
import { queryClient } from "src/api/queryClient";
import { projectQueryKeys } from "src/api/queryKeys";
import { supabase } from "src/clients/supabase";
import { Button } from "src/components/Button";
import { Input } from "src/components/Input";
import LoadingSpinner from "src/components/LoadingSpinner";
import { MainLayout } from "src/components/MainLayout";
import { SelectDropdown } from "src/components/SelectDropdown";
import { useWorkspace } from "src/stores/workspace";
import { useEditProject, useProjectRepos } from "src/utils/projects";
import { ConnectGithubCard } from "../github/ConnectGithubCard";
import { useProject } from "./ProjectContext";
var useWorkspaceRepos = function (workspaceId) {
    return useQuery({
        queryKey: ["workspace-repos", workspaceId],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!workspaceId)
                            return [2 /*return*/, { repositories: [] }];
                        return [4 /*yield*/, apiClient.get("/api/integrations/github/all-workspace-repos", {
                                params: { workspaceId: workspaceId },
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        }); },
        enabled: !!workspaceId,
    });
};
export var ProjectSettingsView = function () {
    var project = useProject();
    var workspace = useWorkspace();
    var _a = useState(true), isSlugAvailable = _a[0], setIsSlugAvailable = _a[1];
    if (!project)
        throw new Error("Project not found");
    var _b = useForm({
        defaultValues: {
            name: project.name,
            slug: project.slug,
        },
    }), register = _b.register, handleSubmit = _b.handleSubmit, watch = _b.watch, setValue = _b.setValue, errors = _b.formState.errors;
    var editProjectMutation = useEditProject();
    var projectRepos = useProjectRepos(project.id).data;
    var unlinkRepositoryMutation = useMutation({
        mutationFn: function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var _c;
            var projectId = _b.projectId, repoId = _b.repoId;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _c = handleSupabaseError;
                        return [4 /*yield*/, supabase.from("repository_connections").delete().eq("project_id", projectId).eq("id", repoId)];
                    case 1:
                        _c.apply(void 0, [_d.sent()]);
                        return [2 /*return*/];
                }
            });
        }); },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: projectQueryKeys.repos(project.id) });
        },
    });
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, editProjectMutation.mutateAsync({
                        projectId: project.id,
                        name: data.name,
                        slug: data.slug,
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var watchSlug = watch("slug");
    var watchName = watch("name");
    var hasChanges = watchName !== project.name || watchSlug !== project.slug;
    var handleSlugChange = function (e) {
        var newSlug = e.target.value
            .toLowerCase()
            .replace(/\s/g, "-")
            .replace(/[^a-z0-9-]/g, "");
        setValue("slug", newSlug);
    };
    var _c = useState(""), selectedOwner = _c[0], setSelectedOwner = _c[1];
    var workspaceRepos = useWorkspaceRepos(workspace.id).data;
    var reposByOwner = useMemo(function () {
        if (!(workspaceRepos === null || workspaceRepos === void 0 ? void 0 : workspaceRepos.repositories))
            return [];
        var ownerMap = new Map();
        workspaceRepos.repositories.forEach(function (repo) {
            var owner = repo.fullName.split("/")[0];
            if (!ownerMap.has(owner)) {
                ownerMap.set(owner, []);
            }
            ownerMap.get(owner).push(repo);
        });
        return Array.from(ownerMap.entries()).map(function (_a) {
            var login = _a[0], repositories = _a[1];
            return ({
                login: login,
                repositories: repositories,
            });
        });
    }, [workspaceRepos === null || workspaceRepos === void 0 ? void 0 : workspaceRepos.repositories]);
    var ownerOptions = reposByOwner.map(function (owner) { return ({
        value: owner.login,
        label: owner.login,
        disabled: false,
    }); });
    var repoOptions = useMemo(function () {
        var owner = reposByOwner.find(function (o) { return o.login === selectedOwner; });
        if (!owner)
            return [];
        return owner.repositories.map(function (repo) { return ({
            value: repo.fullName,
            label: repo.name,
            disabled: false,
        }); });
    }, [reposByOwner, selectedOwner]);
    var handleOwnerChange = function (value) {
        setSelectedOwner(value);
    };
    var connectRepositoryMutation = useMutation({
        mutationFn: function (repoFullName) { return __awaiter(void 0, void 0, void 0, function () {
            var repo, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        repo = workspaceRepos === null || workspaceRepos === void 0 ? void 0 : workspaceRepos.repositories.find(function (r) { return r.fullName === repoFullName; });
                        if (!repo)
                            throw new Error("Repository not found");
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase.from("repository_connections").insert({
                                project_id: project.id,
                                provider: "github",
                                external_id: String(repo.id),
                                name: repo.name,
                                owner: repo.fullName.split("/")[0],
                                installation_id: String(repo.installationId),
                                default_branch: repo.defaultBranch,
                            })];
                    case 1: return [2 /*return*/, _a.apply(void 0, [_b.sent()])];
                }
            });
        }); },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: projectQueryKeys.repos(project.id) });
        },
    });
    return (_jsx(MainLayout, { className: "flex h-screen flex-col items-center overflow-y-scroll", children: _jsxs("div", { className: "flex w-full max-w-screen-sm flex-col gap-4 p-6 pt-12", children: [_jsx("h1", { className: "font-display text-2xl font-bold", children: "Project settings" }), _jsxs("form", { onSubmit: handleSubmit(onSubmit), className: "flex flex-col gap-4", children: [_jsxs("div", { className: "flex flex-col gap-1", children: [_jsx("label", { htmlFor: "name", children: "Project name" }), _jsx(Input, __assign({}, register("name", { required: "Project name is required" }), { placeholder: "My Project", error: !!errors.name })), errors.name && _jsx("p", { className: "mt-1 text-sm text-red-500", children: errors.name.message })] }), _jsxs("div", { className: "flex flex-col gap-1", children: [_jsx("label", { htmlFor: "slug", children: "Project slug" }), _jsxs("div", { className: "flex items-center", children: [_jsxs("span", { className: "mr-2 text-sm text-secondary", children: ["/workspaces/", workspace.slug, "/projects/"] }), _jsx(Input, __assign({}, register("slug", {
                                            required: "Slug is required",
                                            pattern: {
                                                value: VALID_WORKSPACE_SLUG_CHARS,
                                                message: "Slug can only contain lowercase letters, numbers, and hyphens",
                                            },
                                        }), { placeholder: "my-project", className: "flex-grow", error: !!errors.slug, onChange: handleSlugChange }))] }), errors.slug && _jsx("p", { className: "mt-1 text-sm text-red-500", children: errors.slug.message })] }), _jsx(Button, { className: "self-end", type: "submit", disabled: editProjectMutation.isPending || !watchSlug || !watchName || !isSlugAvailable || !hasChanges, children: editProjectMutation.isPending ? _jsx(LoadingSpinner, { size: "xs", variant: "background" }) : "Save Changes" })] }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx("label", { className: "font-medium", children: "Linked Repositories" }), projectRepos === null || projectRepos === void 0 ? void 0 : projectRepos.map(function (repo) { return (_jsxs("div", { className: "flex items-center justify-between rounded-md border border-border bg-background p-2 pl-4", children: [_jsxs("div", { className: "flex items-center gap-2", children: [_jsx(SiGithub, { className: "size-4" }), _jsxs("span", { children: [repo.owner, "/", repo.name] })] }), _jsx(Button, { variant: "ghost", size: "icon-sm", onClick: function () {
                                        if (window.confirm("Are you sure you want to unlink this repository?")) {
                                            unlinkRepositoryMutation.mutate({
                                                projectId: project.id,
                                                repoId: repo.id,
                                            });
                                        }
                                    }, disabled: unlinkRepositoryMutation.isPending, children: unlinkRepositoryMutation.isPending ? (_jsx(LoadingSpinner, { size: "xs" })) : (_jsx(XIcon, { className: "text-destructive size-4" })) })] }, repo.id)); }), (projectRepos === null || projectRepos === void 0 ? void 0 : projectRepos.length) === 0 && (_jsxs("div", { className: "flex flex-col gap-4 rounded-lg border border-border p-4", children: [_jsx("p", { className: "text-sm text-secondary", children: "No repositories linked to this project" }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(ConnectGithubCard, {}), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(SelectDropdown, { options: ownerOptions, value: selectedOwner, onChange: handleOwnerChange, placeholder: "Select organization/owner", className: "w-full" }), _jsx(SelectDropdown, { options: repoOptions, value: "", onChange: function (value) {
                                                        connectRepositoryMutation.mutate(value);
                                                    }, placeholder: "Select repository", className: "w-full", disabled: !selectedOwner || connectRepositoryMutation.isPending })] })] })] }))] })] }) }));
};
