import { useEffect, useRef } from "react";
export var useClickOutside = function (handler) {
    var ref = useRef(null);
    useEffect(function () {
        var handleClickOutside = function (event) {
            if (ref.current && !ref.current.contains(event.target)) {
                handler();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return function () {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handler]);
    return ref;
};
