import { create } from "zustand";
export var useThoughtStore = create(function (set) { return ({
    currentContent: null,
    setCurrentContent: function (content) { return set({ currentContent: content }); },
    selectionToEdit: null,
    setSelectionToEdit: function (selection) { return set({ selectionToEdit: selection }); },
    lastLocalThoughtTitleTs: null,
    setLastLocalThoughtTitleTs: function (ts) { return set({ lastLocalThoughtTitleTs: ts }); },
    lastLocalThoughtContentTs: null,
    setLastLocalThoughtContentTs: function (ts) { return set({ lastLocalThoughtContentTs: ts }); },
    isAiSuggestionLoading: false,
    setIsAiSuggestionLoading: function (isLoading) { return set({ isAiSuggestionLoading: isLoading }); },
    commentFilter: null,
    setCommentFilter: function (filter) {
        return set({ commentFilter: filter, feedMode: filter ? "selectedComments" : "default", activeThreadCommentId: null });
    },
    feedMode: "default",
    setFeedMode: function (mode) { return set({ feedMode: mode }); },
    activeThreadCommentId: null,
    setActiveThreadCommentId: function (id) {
        return set({ activeThreadCommentId: id, feedMode: id ? "thread" : "default", commentFilter: null });
    },
    isAiWriting: false,
    setIsAiWriting: function (isWriting) { return set({ isAiWriting: isWriting }); },
    reset: function () {
        return set({
            currentContent: null,
            selectionToEdit: null,
            lastLocalThoughtTitleTs: null,
            lastLocalThoughtContentTs: null,
            isAiSuggestionLoading: false,
            commentFilter: null,
            feedMode: "default",
            activeThreadCommentId: null,
            isAiWriting: false,
        });
    },
}); });
