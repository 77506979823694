var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ThoughtSignals, ellipsizeText } from "@cloudy/utils/common";
import DragHandle from "@tiptap-pro/extension-drag-handle-react";
import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import { EditorContent, Extension, useEditor } from "@tiptap/react";
import { GripVertical } from "lucide-react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useHotkeys } from "react-hotkeys-hook";
import { Navigate, useParams } from "react-router-dom";
import { useAsync, useLocalStorage, useMount, usePrevious, useUnmount, useUpdateEffect } from "react-use";
import { MainLayout } from "src/components/MainLayout";
import { useUserRecord } from "src/stores/user";
import { useWorkspace } from "src/stores/workspace";
import { cn } from "src/utils";
import { makeProjectHomeUrl } from "src/utils/projects";
import { makeHeadTitle } from "src/utils/strings";
import { useSave } from "src/utils/useSave";
import { useTitleStore } from "src/views/thoughtDetail/titleStore";
import { useSidebarContext } from "../navigation/SidebarProvider";
import { useProject } from "../projects/ProjectContext";
import { ControlColumn } from "./ControlColumn";
import { ControlRow } from "./ControlRow";
import { DocumentLoadingPlaceholder } from "./DocumentLoadingPlaceholder";
import { EditorBubbleMenu } from "./EditorBubbleMenu";
import { EditorErrorBoundary } from "./EditorErrorBoundary";
import { FooterRow } from "./FooterRow";
import { TitleArea } from "./TitleArea";
import { ChatSectionView } from "./chatSection/ChatSectionView";
import { createFileHandlerExtension } from "./fileHandlerExtension";
import { useDefaultThreadId, useEditThought, useGenerateDocument, useThought, useThoughtChannelListeners, } from "./hooks";
import { updateMentionNodeNames } from "./mention";
import { AiGenerationContext, ThoughtContext } from "./thoughtContext";
import { useThoughtStore } from "./thoughtStore";
import { clearAllApplyMarks, clearAllEditMarks, tiptapExtensions, wrapSelectionAroundWords, } from "./tiptap";
import { useYProvider } from "./yProvider";
export var ThoughtDetailView = function () {
    var thoughtId = useParams().thoughtId;
    return _jsx(ThoughtDetailInner, { thoughtId: thoughtId }, thoughtId);
};
var ThoughtDetailInner = function (_a) {
    var thoughtId = _a.thoughtId;
    var workspace = useWorkspace();
    var project = useProject();
    var _b = useThought(thoughtId), thought = _b.data, isLoading = _b.isLoading;
    var previousThought = usePrevious(thought);
    var title = useTitleStore(function (s) { return s.title; });
    var headTitle = title ? makeHeadTitle(ellipsizeText(title, 16)) : makeHeadTitle("New Thought");
    if ((!thought && previousThought) || (!isLoading && !thought)) {
        if (project) {
            return _jsx(Navigate, { to: makeProjectHomeUrl(workspace.slug, project.slug) });
        }
        return _jsx(Navigate, { to: "/" });
    }
    return (_jsx(EditorErrorBoundary, { children: _jsxs(MainLayout, { className: "no-scrollbar relative flex h-full w-screen flex-col overflow-hidden px-0 md:w-full md:px-0 lg:px-0", children: [_jsx(Helmet, { children: _jsx("title", { children: headTitle }) }), thought && _jsx(ThoughtContent, { thoughtId: thoughtId, thought: thought }, thoughtId)] }) }));
};
var ThoughtContent = function (_a) {
    var _b, _c, _d;
    var thoughtId = _a.thoughtId, thought = _a.thought;
    useThoughtChannelListeners(thoughtId);
    var userRecord = useUserRecord();
    var editThought = useEditThought(thoughtId).mutateAsync;
    var _e = useLocalStorage("hideControlColumn", false), hideControlColumn = _e[0], setHideControlColumn = _e[1];
    var _f = useState(false), isAiWriting = _f[0], setIsAiWriting = _f[1];
    var _g = useState(false), isEditingDisabled = _g[0], setIsEditingDisabled = _g[1];
    var _h = useState(null), previewingKey = _h[0], setPreviewingKey = _h[1];
    var _j = useState(false), isShowingAiEditorMenu = _j[0], setShowAiEditorMenu = _j[1];
    var _k = useState(false), isShowingAiSelectionMenu = _k[0], setIsShowingAiSelectionMenu = _k[1];
    var _l = useState(null), threadId = _l[0], setThreadId = _l[1];
    var onChange = useSave(editThought, { debounceDurationMs: thoughtId ? 500 : 0 }).onChange;
    var disableUpdatesRef = useRef(false);
    var storedContentRef = useRef(null);
    var contentAfterApplyRef = useRef(null);
    var setIsAiSuggestionLoading = useThoughtStore().setIsAiSuggestionLoading;
    var setIsSidebarCollapsed = useSidebarContext({ isFixed: isShowingAiEditorMenu }).setIsSidebarCollapsed;
    var _m = useYProvider(thoughtId, disableUpdatesRef), isLoading = _m.isLoading, isConnected = _m.isConnected, ydoc = _m.ydoc, provider = _m.provider;
    var editor = useEditor({
        editorProps: {
            attributes: { class: "main-editor doc-editor" },
        },
        extensions: __spreadArray(__spreadArray([], tiptapExtensions, true), [
            createFileHandlerExtension(thoughtId),
            Collaboration.configure({
                document: ydoc,
            }),
            CollaborationCursor.configure({
                provider: provider,
                user: {
                    name: (_b = userRecord.name) !== null && _b !== void 0 ? _b : userRecord.email,
                    color: "#b694ff",
                },
            }),
            Extension.create({
                name: "hotkeys",
                addKeyboardShortcuts: function () {
                    return {
                        "Mod-o": function () {
                            showAiEditor();
                            return true;
                        },
                        "Mod-k": function () {
                            showAiSelectionMenu();
                            return true;
                        },
                        Escape: function () {
                            hideAiEditor();
                            return true;
                        },
                    };
                },
            }),
        ], false),
        content: "",
        onUpdate: function (_a) {
            var transaction = _a.transaction;
            if (transaction.getMeta("y-sync$")) {
                // Ignore y-sync updates
                return;
            }
            onUpdate();
        },
        autofocus: !thoughtId,
        editable: !isEditingDisabled,
    });
    useEffect(function () {
        if (isConnected && thought.content && !(editor === null || editor === void 0 ? void 0 : editor.getText())) {
            editor === null || editor === void 0 ? void 0 : editor.commands.setContent(thought.content);
        }
        if (editor) {
            updateMentionNodeNames(editor);
            if (!isConnected) {
                // Blur on disconnect
                editor.commands.blur();
            }
        }
    }, [isConnected]);
    var onUpdate = useCallback(function (_a) {
        var _b = _a === void 0 ? {} : _a, _c = _b.force, force = _c === void 0 ? false : _c;
        if (force || (isConnected && !disableUpdatesRef.current)) {
            var content = editor === null || editor === void 0 ? void 0 : editor.getHTML();
            var contentMd = editor === null || editor === void 0 ? void 0 : editor.storage.markdown.getMarkdown();
            var contentPlainText = editor === null || editor === void 0 ? void 0 : editor.getText();
            var ts = new Date();
            onChange({ content: content, contentMd: contentMd, contentPlainText: contentPlainText, ts: ts });
        }
    }, [isConnected, editor, onChange, disableUpdatesRef]);
    var storeContentIfNeeded = useCallback(function () {
        var _a;
        if (!storedContentRef.current) {
            storedContentRef.current = (_a = editor === null || editor === void 0 ? void 0 : editor.getJSON()) !== null && _a !== void 0 ? _a : null;
        }
    }, [editor]);
    var storeContentAsApplyContent = useCallback(function () {
        var _a;
        contentAfterApplyRef.current = (_a = editor === null || editor === void 0 ? void 0 : editor.getJSON()) !== null && _a !== void 0 ? _a : null;
    }, [editor]);
    var restoreFromLastContent = useCallback(function () {
        if (storedContentRef.current) {
            editor === null || editor === void 0 ? void 0 : editor.commands.setContent(storedContentRef.current);
            storedContentRef.current = null;
            contentAfterApplyRef.current = null;
        }
    }, [editor]);
    var clearStoredContent = useCallback(function () {
        storedContentRef.current = null;
    }, []);
    var clearApplyContent = useCallback(function () {
        contentAfterApplyRef.current = null;
    }, []);
    var convertSelectionToEditMark = useCallback(function () {
        if (!editor)
            return;
        var selection = wrapSelectionAroundWords(editor);
        editor.chain().setTextSelection(selection).setMark("editHighlight").run();
    }, [editor]);
    var showAiSelectionMenu = useCallback(function () {
        if (!editor)
            return;
        disableUpdatesRef.current = true;
        if (editor.view.state.selection.content().size > 0) {
            convertSelectionToEditMark();
            setIsShowingAiSelectionMenu(true);
        }
    }, [editor, convertSelectionToEditMark]);
    var showAiEditor = useCallback(function () {
        if (!editor)
            return;
        disableUpdatesRef.current = true;
        setIsSidebarCollapsed(true);
        setShowAiEditorMenu(true);
    }, [editor, setIsSidebarCollapsed]);
    var onStartAiEdits = useCallback(function () {
        if (!editor)
            return;
        setIsAiWriting(true);
        setIsEditingDisabled(true);
    }, [editor, setIsAiWriting]);
    var onFinishAiEdits = useCallback(function () {
        if (!editor)
            return;
        setIsAiWriting(false);
    }, [editor, setIsAiWriting]);
    var applySuggestedChanges = useCallback(function () {
        var _a;
        if (!editor) {
            return;
        }
        editor.commands.setContent((_a = contentAfterApplyRef.current) !== null && _a !== void 0 ? _a : "");
        clearAllApplyMarks(editor);
        setPreviewingKey(null);
        setIsEditingDisabled(false);
        clearApplyContent();
        clearStoredContent();
        onFinishAiEdits();
        disableUpdatesRef.current = false;
        onUpdate();
    }, [editor, clearStoredContent, clearApplyContent, onFinishAiEdits, onUpdate]);
    var hideAiEditor = useCallback(function () {
        if (!editor)
            return;
        setShowAiEditorMenu(false);
        restoreFromLastContent();
        clearApplyContent();
        clearStoredContent();
        clearAllEditMarks(editor);
        onFinishAiEdits();
        disableUpdatesRef.current = false;
    }, [editor, restoreFromLastContent, clearStoredContent, clearApplyContent, onFinishAiEdits]);
    var hideAiSelectionMenu = useCallback(function () {
        if (!editor)
            return;
        setIsShowingAiSelectionMenu(false);
        restoreFromLastContent();
        clearApplyContent();
        clearStoredContent();
        clearAllEditMarks(editor);
        onFinishAiEdits();
        disableUpdatesRef.current = false;
    }, [editor, restoreFromLastContent, clearStoredContent, clearApplyContent, onFinishAiEdits]);
    useEffect(function () {
        var _a;
        var signals = (_a = thought === null || thought === void 0 ? void 0 : thought.signals) !== null && _a !== void 0 ? _a : [];
        if (signals.includes(ThoughtSignals.AI_SUGGESTIONS)) {
            setIsAiSuggestionLoading(true);
        }
        else {
            setIsAiSuggestionLoading(false);
        }
    }, [setIsAiSuggestionLoading, thought === null || thought === void 0 ? void 0 : thought.signals]);
    useHotkeys("mod+o", function (e) {
        e.preventDefault();
        e.stopPropagation();
        showAiEditor();
    });
    return (_jsx(ThoughtContext.Provider, { value: {
            thoughtId: thoughtId,
            editor: editor,
            disableUpdatesRef: disableUpdatesRef,
            onUpdate: onUpdate,
            isDocumentLoading: isLoading,
            isConnected: isConnected,
            isEditingDisabled: isEditingDisabled,
            setIsEditingDisabled: setIsEditingDisabled,
            previewingKey: previewingKey,
            setPreviewingKey: setPreviewingKey,
            storeContentIfNeeded: storeContentIfNeeded,
            storeContentAsApplyContent: storeContentAsApplyContent,
            restoreFromLastContent: restoreFromLastContent,
            clearStoredContent: clearStoredContent,
            clearApplyContent: clearApplyContent,
            hideControlColumn: hideControlColumn,
            setHideControlColumn: setHideControlColumn,
            setShowAiEditorMenu: setShowAiEditorMenu,
            isShowingAiEditorMenu: isShowingAiEditorMenu,
            showAiEditor: showAiEditor,
            hideAiEditor: hideAiEditor,
            applySuggestedChanges: applySuggestedChanges,
            isAiWriting: isAiWriting,
            setIsAiWriting: setIsAiWriting,
            onStartAiEdits: onStartAiEdits,
            onFinishAiEdits: onFinishAiEdits,
            threadId: threadId,
            setThreadId: setThreadId,
            convertSelectionToEditMark: convertSelectionToEditMark,
            isShowingAiSelectionMenu: isShowingAiSelectionMenu,
            hideAiSelectionMenu: hideAiSelectionMenu,
            showAiSelectionMenu: showAiSelectionMenu,
        }, children: _jsxs("div", { className: "flex h-full flex-row", children: [_jsx("div", { className: cn("relative w-[33vw] shrink-0 transition-[width] duration-300 ease-in-out", !isShowingAiEditorMenu && "w-0"), children: isShowingAiEditorMenu && _jsx(ChatSectionView, {}) }), _jsx("div", { className: "no-scrollbar relative flex w-full flex-grow flex-col lg:flex-row", children: _jsxs(AiDocumentGeneration, { thought: thought, children: [_jsx(EditorView, { thoughtId: thoughtId, remoteTitle: (_c = thought === null || thought === void 0 ? void 0 : thought.title) !== null && _c !== void 0 ? _c : undefined, latestRemoteTitleTs: (_d = thought === null || thought === void 0 ? void 0 : thought.title_ts) !== null && _d !== void 0 ? _d : undefined, onChange: onChange }), _jsx(ControlColumn, { thoughtId: thoughtId })] }) })] }) }));
};
var AiDocumentGeneration = function (_a) {
    var thought = _a.thought, children = _a.children;
    var _b = useContext(ThoughtContext), onStartAiEdits = _b.onStartAiEdits, onFinishAiEdits = _b.onFinishAiEdits, isAiWriting = _b.isAiWriting, onUpdate = _b.onUpdate, showAiEditor = _b.showAiEditor, setThreadId = _b.setThreadId;
    var defaultThreadId = useDefaultThreadId().data;
    var generateDocumentMutation = useGenerateDocument();
    var hasGenerated = useRef(false);
    useAsync(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(!hasGenerated.current && thought.generation_prompt && !thought.generated_at && defaultThreadId)) return [3 /*break*/, 2];
                    hasGenerated.current = true;
                    showAiEditor();
                    setThreadId(defaultThreadId);
                    onStartAiEdits();
                    return [4 /*yield*/, generateDocumentMutation.mutateAsync(thought.id)];
                case 1:
                    _a.sent();
                    onFinishAiEdits();
                    onUpdate({ force: true });
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [thought === null || thought === void 0 ? void 0 : thought.id, defaultThreadId]);
    return (_jsx(AiGenerationContext.Provider, { value: { isGenerating: isAiWriting && !generateDocumentMutation.hasStarted && !thought.generated_at }, children: children }));
};
var EditorView = function (_a) {
    var thoughtId = _a.thoughtId, remoteTitle = _a.remoteTitle, latestRemoteTitleTs = _a.latestRemoteTitleTs, onChange = _a.onChange;
    var _b = useContext(ThoughtContext), editor = _b.editor, isConnected = _b.isConnected, isDocumentLoading = _b.isDocumentLoading, hideControlColumn = _b.hideControlColumn, isAiWriting = _b.isAiWriting;
    var isGenerating = useContext(AiGenerationContext).isGenerating;
    var _c = useThoughtStore(), lastLocalThoughtTitleTs = _c.lastLocalThoughtTitleTs, setCurrentContent = _c.setCurrentContent, setLastLocalThoughtContentTs = _c.setLastLocalThoughtContentTs, setLastLocalThoughtTitleTs = _c.setLastLocalThoughtTitleTs;
    var _d = useTitleStore(), title = _d.title, setTitle = _d.setTitle, saveTitleKey = _d.saveTitleKey;
    useMount(function () {
        setTitle(remoteTitle !== null && remoteTitle !== void 0 ? remoteTitle : "");
    });
    useUnmount(function () {
        setTitle("");
    });
    useUpdateEffect(function () {
        // If the remote title has changed, update the local title
        if ((latestRemoteTitleTs && lastLocalThoughtTitleTs && new Date(latestRemoteTitleTs) > lastLocalThoughtTitleTs) ||
            !lastLocalThoughtTitleTs) {
            setTitle(remoteTitle !== null && remoteTitle !== void 0 ? remoteTitle : "");
        }
    }, [latestRemoteTitleTs]);
    useUnmount(function () {
        setCurrentContent(null);
        setLastLocalThoughtContentTs(null);
    });
    useUpdateEffect(function () {
        var ts = new Date();
        onChange({ title: title, ts: ts });
        setLastLocalThoughtTitleTs(ts);
    }, [saveTitleKey]);
    var handleChangeTitle = function (title) {
        var ts = new Date();
        setTitle(title);
        onChange({ title: title, ts: ts });
        setLastLocalThoughtTitleTs(ts);
    };
    return (_jsxs("div", { className: "no-scrollbar relative box-border flex flex-grow flex-col items-center overflow-y-scroll", children: [_jsx("nav", { className: "sticky top-[-1px] z-30 -mr-2 w-full bg-background px-6 py-2 md:top-0 md:py-3", children: _jsx(ControlRow, { thoughtId: thoughtId, editor: editor }) }), _jsxs("div", { className: cn("-ml-8 box-border flex w-full max-w-screen-lg grow flex-col px-3 md:pl-16 md:pt-16 lg:flex-1", hideControlColumn ? "lg:pr-16" : "lg:pr-4"), children: [_jsx(TitleArea, { title: title, onChange: handleChangeTitle }), _jsxs("div", { 
                        // On larger screens, we need left padding to avoid some characters being cut off
                        className: "relative flex flex-row md:pl-[2px]", children: [editor && thoughtId && _jsx(EditorBubbleMenu, {}), editor && thoughtId && (_jsx("div", { children: _jsx(DragHandle, { editor: editor, tippyOptions: { offset: [-4, 4], zIndex: 10 }, children: _jsx("div", { className: "hidden cursor-grab flex-row items-center rounded border border-transparent px-0.5 py-1 hover:border-border hover:bg-card active:cursor-grabbing active:bg-accent/20 md:flex", children: _jsx(GripVertical, { className: "h-5 w-5 text-tertiary" }) }) }) })), isDocumentLoading ? (_jsx("div", { className: "w-full pl-8", children: _jsx(DocumentLoadingPlaceholder, {}) })) : (_jsx(EditorContent, { editor: editor, className: cn("w-full", (isAiWriting || !isConnected) && "pointer-events-none opacity-70", isGenerating && "opacity-0") })), isGenerating && _jsx("div", { className: "absolute left-8 top-0 animate-pulse text-tertiary", children: "Generating..." })] }), _jsx("div", { className: "h-[75dvh]" })] }), _jsx(FooterRow, {})] }));
};
