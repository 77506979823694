var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MessageCircleHeartIcon, SendHorizonalIcon } from "lucide-react";
import { usePostHog } from "posthog-js/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";
import { Button } from "./Button";
import { Dropdown } from "./Dropdown";
export var FeedbackDropdown = function () {
    var posthog = usePostHog();
    var _a = useForm(), register = _a.register, handleSubmit = _a.handleSubmit, reset = _a.reset;
    var _b = useState(false), didSubmit = _b[0], setDidSubmit = _b[1];
    var onSubmit = function (data) {
        posthog.capture("survey sent", {
            $survey_id: process.env.NODE_ENV === "production"
                ? "0191ceaa-219b-0000-fd42-ccb028308020"
                : "0191ceaa-f303-0000-5adb-b85aeb76a3ba",
            $survey_response: data.feedback,
        });
        setDidSubmit(true);
        reset();
    };
    return (_jsx(Dropdown, { trigger: _jsxs("div", { children: [_jsx(Button, { variant: "ghost", size: "icon", className: "flex md:hidden", children: _jsx(MessageCircleHeartIcon, { className: "size-6" }) }), _jsxs(Button, { variant: "outline", size: "sm", className: "hidden w-full md:flex", children: [_jsx(MessageCircleHeartIcon, { className: "size-4" }), _jsx("span", { children: "Give feedback" })] })] }), className: "w-screen md:max-w-[28rem]", onClose: function () { return setDidSubmit(false); }, children: didSubmit ? (_jsxs("div", { className: "flex flex-col gap-2 p-4", children: [_jsx("h3", { className: "font-semibold", children: "Thank you for your feedback!" }), _jsx("p", { className: "mb-2 text-sm text-secondary", children: "We'll use this to improve Cloudy." })] })) : (_jsxs("form", { onSubmit: handleSubmit(onSubmit), className: "flex flex-col gap-2 p-4", children: [_jsx("h3", { className: "font-semibold", children: "What can we do to improve Cloudy?" }), _jsx("p", { className: "mb-2 text-sm text-secondary", children: "Got a feature request? Did something go wrong? Got any great ideas? We'd love to hear them." }), _jsx(TextareaAutosize, __assign({}, register("feedback", { required: true }), { className: "no-scrollbar min-h-36 w-full resize-none appearance-none rounded border border-border bg-card px-3 py-2 text-sm outline-none", placeholder: "Share your thoughts...", rows: 4 })), _jsxs(Button, { type: "submit", className: "mt-2 w-full", children: [_jsx(SendHorizonalIcon, { className: "size-4" }), _jsx("span", { children: "Submit feedback" })] })] })) }));
};
