var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RefreshCwIcon } from "lucide-react";
import { Component } from "react";
import { Button } from "src/components/Button";
var EditorErrorBoundary = /** @class */ (function (_super) {
    __extends(EditorErrorBoundary, _super);
    function EditorErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { hasError: false };
        return _this;
    }
    EditorErrorBoundary.getDerivedStateFromError = function (_) {
        return { hasError: true };
    };
    EditorErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        console.error("Editor error:", error, errorInfo);
    };
    EditorErrorBoundary.prototype.render = function () {
        var _this = this;
        if (this.state.hasError) {
            return (_jsxs("div", { className: "flex h-full flex-col items-center justify-center gap-4", children: [_jsx("img", { src: "/cringe-cat.jpg", className: "h-48 w-96", alt: "Oh no! Cringe" }), _jsxs("div", { className: "flex flex-col items-center gap-2", children: [_jsx("p", { className: "text-center", children: "Oh no. Something went wrong here." }), _jsxs("p", { className: "text-center text-sm text-secondary", children: ["We've been notified, email us at", " ", _jsx("a", { href: "mailto:founders@usecloudy.com", className: "text-accent hover:underline", children: "founders@usecloudy.com" }), " ", "or dm", " ", _jsx("a", { href: "https://x.com/jennmueng", className: "text-accent hover:underline", children: "@jennmueng" }), " ", "on X if this persists."] })] }), _jsxs(Button, { onClick: function () {
                            _this.setState({ hasError: false });
                            window.location.reload();
                        }, children: [_jsx(RefreshCwIcon, { className: "h-4 w-4" }), _jsx("span", { children: "Refresh page" })] })] }));
        }
        return this.props.children;
    };
    return EditorErrorBoundary;
}(Component));
export { EditorErrorBoundary };
