var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RepositoryProvider, VALID_WORKSPACE_SLUG_CHARS, checkIfSlugIsAvailable, createNonConflictingSlug, } from "@cloudy/utils/common";
import { useMutation } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import posthog from "posthog-js";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useMount } from "react-use";
import { apiClient } from "src/api/client";
import { supabase } from "src/clients/supabase";
import { Button } from "src/components/Button";
import { Input } from "src/components/Input";
import LoadingSpinner from "src/components/LoadingSpinner";
import { MainLayout } from "src/components/MainLayout";
import { SelectDropdown } from "src/components/SelectDropdown";
import { useUserRecord } from "src/stores/user";
import { useWorkspace, useWorkspaceGithubInstallations } from "src/stores/workspace";
import { makeProjectHomeUrl } from "src/utils/projects";
import { ConnectGithubCard } from "../github/ConnectGithubCard";
import { useCreateProject, useUserProjects } from "./hooks";
var useCreateNonConflictingSlug = function () {
    return useMutation({
        mutationFn: function (name) { return __awaiter(void 0, void 0, void 0, function () {
            var slug;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, createNonConflictingSlug(name, supabase)];
                    case 1:
                        slug = _a.sent();
                        return [2 /*return*/, slug];
                }
            });
        }); },
    });
};
var useCheckSlugAvailability = function () {
    return useMutation({
        mutationFn: function (slug) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, checkIfSlugIsAvailable(slug, supabase)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        }); },
    });
};
var useWorkspaceRepos = function (workspaceId) {
    return useQuery({
        queryKey: ["workspace-repos", workspaceId],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!workspaceId)
                            return [2 /*return*/, { repositories: [] }];
                        return [4 /*yield*/, apiClient.get("/api/integrations/github/all-workspace-repos", {
                                params: { workspaceId: workspaceId },
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        }); },
        enabled: !!workspaceId,
    });
};
export var NewProjectView = function () {
    var workspace = useWorkspace();
    var userRecord = useUserRecord();
    var searchParams = useSearchParams()[0];
    var userProjects = useUserProjects().data;
    var installations = useWorkspaceGithubInstallations().data;
    var nameFromParams = searchParams.get("name");
    var shouldSetDefaults = !!nameFromParams;
    var _a = useState(null), isSlugAvailable = _a[0], setIsSlugAvailable = _a[1];
    var _b = useForm({
        defaultValues: {
            name: shouldSetDefaults ? nameFromParams || (userRecord.name ? "".concat(userRecord.name, "'s Project") : "") : "",
        },
    }), register = _b.register, handleSubmit = _b.handleSubmit, watch = _b.watch, setValue = _b.setValue, errors = _b.formState.errors;
    var createProjectMutation = useCreateProject();
    var slugMutation = useCreateNonConflictingSlug();
    var checkSlugAvailabilityMutation = useCheckSlugAvailability();
    var navigate = useNavigate();
    var userHasProjects = userProjects && userProjects.length > 0;
    useMount(function () { return __awaiter(void 0, void 0, void 0, function () {
        var defaultName, defaultSlug;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!shouldSetDefaults) return [3 /*break*/, 2];
                    defaultName = nameFromParams || userRecord.name;
                    if (!defaultName) return [3 /*break*/, 2];
                    return [4 /*yield*/, slugMutation.mutateAsync(defaultName)];
                case 1:
                    defaultSlug = _a.sent();
                    setValue("slug", defaultSlug);
                    setIsSlugAvailable(true);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); });
    var reposData = useWorkspaceRepos(workspace === null || workspace === void 0 ? void 0 : workspace.id).data;
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var repo, repositoryConnection, projectSlug;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    repo = reposData === null || reposData === void 0 ? void 0 : reposData.repositories.find(function (repo) { return repo.fullName === data.githubRepo; });
                    if (repo) {
                        repositoryConnection = {
                            provider: RepositoryProvider.GITHUB,
                            external_id: String(repo.id),
                            name: repo.name,
                            owner: repo.fullName.split("/")[0],
                            installation_id: String(repo.installationId),
                            default_branch: repo.defaultBranch,
                        };
                    }
                    return [4 /*yield*/, createProjectMutation.mutateAsync(__assign(__assign({}, data), { repositoryConnection: repositoryConnection }))];
                case 1:
                    projectSlug = (_a.sent()).projectSlug;
                    posthog.capture("project_created", {
                        project_id: projectSlug,
                        user_id: userRecord.id,
                        is_setup: !userHasProjects,
                        has_github_repo: !!data.githubRepo,
                    });
                    navigate(makeProjectHomeUrl(workspace.slug, projectSlug));
                    return [2 /*return*/];
            }
        });
    }); };
    var watchSlug = watch("slug");
    var watchName = watch("name");
    var handleSlugChange = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var newSlug, available;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    newSlug = e.target.value
                        .toLowerCase()
                        .replace(/\s/g, "-")
                        .replace(/[^a-z0-9-]/g, "");
                    setValue("slug", newSlug);
                    if (!newSlug) return [3 /*break*/, 2];
                    return [4 /*yield*/, checkSlugAvailabilityMutation.mutateAsync(newSlug)];
                case 1:
                    available = _a.sent();
                    setIsSlugAvailable(available);
                    return [3 /*break*/, 3];
                case 2:
                    setIsSlugAvailable(null);
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var _c = useState(""), selectedOwner = _c[0], setSelectedOwner = _c[1];
    // Group repositories by owner
    var reposByOwner = useMemo(function () {
        if (!(reposData === null || reposData === void 0 ? void 0 : reposData.repositories))
            return [];
        var ownerMap = new Map();
        reposData.repositories.forEach(function (repo) {
            var owner = repo.fullName.split("/")[0];
            if (!ownerMap.has(owner)) {
                ownerMap.set(owner, []);
            }
            ownerMap.get(owner).push(repo);
        });
        return Array.from(ownerMap.entries()).map(function (_a) {
            var login = _a[0], repositories = _a[1];
            return ({
                login: login,
                repositories: repositories,
            });
        });
    }, [reposData === null || reposData === void 0 ? void 0 : reposData.repositories]);
    // Create options for owner dropdown
    var ownerOptions = reposByOwner.map(function (owner) { return ({
        value: owner.login,
        label: owner.login,
        disabled: false,
    }); });
    // Create options for repository dropdown
    var repoOptions = useMemo(function () {
        var owner = reposByOwner.find(function (o) { return o.login === selectedOwner; });
        if (!owner)
            return [];
        return owner.repositories.map(function (repo) { return ({
            value: repo.fullName,
            label: repo.name,
            disabled: false,
        }); });
    }, [reposByOwner, selectedOwner]);
    // Handle owner selection
    var handleOwnerChange = function (value) {
        setSelectedOwner(value);
        setValue("githubRepo", ""); // Clear repo selection when owner changes
    };
    return (_jsx(MainLayout, { className: "flex h-screen flex-col items-center justify-center", children: _jsxs("div", { className: "flex w-full max-w-md flex-col gap-4 rounded-md border border-border p-6", children: [_jsx("h1", { className: "font-display text-2xl font-bold", children: "Create a project" }), _jsxs("form", { onSubmit: handleSubmit(onSubmit), className: "flex flex-col gap-4", children: [_jsxs("div", { className: "flex flex-col gap-1", children: [_jsx("label", { htmlFor: "name", className: "font-medium", children: "Project Name" }), _jsx(Input, __assign({}, register("name", { required: "Workspace name is required" }), { placeholder: "Brain Fog Inc.", error: !!errors.name })), errors.name && _jsx("p", { className: "mt-1 text-sm text-red-500", children: errors.name.message })] }), _jsxs("div", { className: "flex flex-col gap-1", children: [_jsx("label", { htmlFor: "slug", className: "font-medium", children: "Project Slug" }), _jsx("div", { className: "flex items-center", children: _jsx(Input, __assign({ prefix: "/workspaces/".concat(workspace.slug, "/projects/") }, register("slug", {
                                        required: "Slug is required",
                                        pattern: {
                                            value: VALID_WORKSPACE_SLUG_CHARS,
                                            message: "Slug can only contain lowercase letters, numbers, and hyphens",
                                        },
                                        validate: function (value) { return isSlugAvailable === true || "This slug is already taken"; },
                                    }), { placeholder: "brain-fog-inc", className: "flex-grow", error: !!errors.slug, onChange: handleSlugChange })) }), errors.slug && _jsx("p", { className: "mt-1 text-sm text-red-500", children: errors.slug.message }), isSlugAvailable === true && _jsx("p", { className: "mt-1 text-xs text-green-600", children: "This slug is available" }), isSlugAvailable === false && _jsx("p", { className: "mt-1 text-xs text-red-600", children: "This slug is already taken" })] }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsxs("div", { className: "flex flex-col gap-1", children: [_jsx("label", { htmlFor: "githubRepo", className: "font-medium", children: "Connect GitHub Repository (optional)" }), _jsx("span", { className: "text-xs text-secondary", children: "Connect a GitHub repository to your project to create & link documents with code. Soon, we will keep your documents up to date with the latest code." })] }), _jsx(ConnectGithubCard, {}), installations && installations.length > 0 && (_jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(SelectDropdown, { options: ownerOptions, value: selectedOwner, onChange: handleOwnerChange, placeholder: "Select organization/owner", className: "w-full" }), _jsx(SelectDropdown, { options: repoOptions, value: watch("githubRepo") || "", onChange: function (value) { return setValue("githubRepo", value); }, placeholder: "Select repository", className: "w-full", disabled: !selectedOwner })] }))] }), _jsx(Button, { type: "submit", disabled: createProjectMutation.isPending || !watchSlug || !watchName || !isSlugAvailable, children: createProjectMutation.isPending ? _jsx(LoadingSpinner, { size: "xs", variant: "background" }) : "Create Project" })] })] }) }));
};
