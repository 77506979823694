var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMutation } from "@tanstack/react-query";
import { ArrowLeftIcon, SparklesIcon } from "lucide-react";
import { useState } from "react";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import Markdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { apiClient } from "src/api/client";
import { Button } from "src/components/Button";
import { Input } from "src/components/Input";
import LoadingSpinner from "src/components/LoadingSpinner";
import { MainLayout } from "src/components/MainLayout";
import { RotatingLoadingMessage } from "src/components/RotatingLoadingMessage";
import { NameAndSlugFields } from "./Fields";
import { useCreateWorkspace, useUserWorkspaces } from "./hooks";
var useScrapeSite = function () {
    return useMutation({
        mutationFn: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, apiClient.get("/api/ai/scrape-site", {
                            params: {
                                url: "https://".concat(data.websiteUrl),
                            },
                        })];
                    case 1:
                        response = _c.sent();
                        if (((_a = response.data) === null || _a === void 0 ? void 0 : _a.name) === null || ((_b = response.data) === null || _b === void 0 ? void 0 : _b.welcomeMessage) === null) {
                            throw new Error("Failed to scrape the website. Please check the URL and try again.");
                        }
                        return [2 /*return*/, response.data];
                }
            });
        }); },
    });
};
export var WorkspaceWebsiteOnboardingView = function () {
    var _a = useState("initial"), status = _a[0], setStatus = _a[1];
    var _b = useState(null), welcomeMessage = _b[0], setWelcomeMessage = _b[1];
    var _c = useState(null), isSlugAvailable = _c[0], setIsSlugAvailable = _c[1];
    var userWorkspaces = useUserWorkspaces().data;
    var userHasWorkspaces = !!(userWorkspaces === null || userWorkspaces === void 0 ? void 0 : userWorkspaces.length);
    var _d = useForm(), register = _d.register, handleSubmit = _d.handleSubmit, errors = _d.formState.errors, setValue = _d.setValue;
    var createWorkspaceMutation = useCreateWorkspace();
    var scrapeSiteMutation = useScrapeSite();
    var navigate = useNavigate();
    var handlePaste = useCallback(function (e) {
        e.preventDefault();
        var pastedText = e.clipboardData.getData("text");
        var urlWithoutProtocol = pastedText.replace(/^(https?:\/\/)/, "");
        setValue("websiteUrl", urlWithoutProtocol);
    }, [setValue]);
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, name, welcomeMessage_1, missionBlurb, collectionNames, error_1, urlParams;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(status === "initial")) return [3 /*break*/, 5];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, scrapeSiteMutation.mutateAsync({
                            websiteUrl: data.websiteUrl,
                        })];
                case 2:
                    _a = _b.sent(), name = _a.name, welcomeMessage_1 = _a.welcomeMessage, missionBlurb = _a.missionBlurb, collectionNames = _a.collectionNames;
                    setValue("name", name);
                    setValue("slug", name.toLowerCase().replace(/[^a-z0-9-]/g, "-"));
                    setValue("missionBlurb", missionBlurb);
                    setValue("collectionNames", collectionNames);
                    setWelcomeMessage(welcomeMessage_1);
                    setStatus("ready");
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _b.sent();
                    // Error handling is now managed by React Query
                    console.error("Error scraping website:", error_1);
                    return [3 /*break*/, 4];
                case 4: return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, createWorkspaceMutation.mutateAsync({
                        name: data.name,
                        slug: data.slug,
                        missionBlurb: data.missionBlurb,
                    })];
                case 6:
                    _b.sent();
                    urlParams = new URLSearchParams();
                    urlParams.set("initialCollections", JSON.stringify(data.collectionNames));
                    navigate("/onboarding/workspaces/".concat(data.slug, "/initial-collections?").concat(urlParams.toString()));
                    _b.label = 7;
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var handleSkip = function () {
        navigate("/onboarding/workspaces/new");
    };
    var handleSlugChange = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            // Add your slug availability check logic here
            setIsSlugAvailable(true); // Placeholder
            return [2 /*return*/];
        });
    }); };
    var handleBack = function () {
        setStatus("initial");
        setValue("websiteUrl", "");
        setValue("name", "");
        setValue("slug", "");
        setValue("missionBlurb", null);
        setValue("collectionNames", []);
        setIsSlugAvailable(null);
    };
    var isLoading = scrapeSiteMutation.isPending || createWorkspaceMutation.isPending;
    return (_jsx(MainLayout, { className: "flex h-screen flex-col items-center justify-center", children: _jsxs("div", { className: "flex w-full max-w-md flex-col gap-4 rounded-md border border-border p-6", children: [_jsx("h1", { className: "font-display text-2xl font-bold", children: "Create a workspace with your website" }), status === "initial" ? (_jsx("p", { className: "text-sm text-secondary", children: "Provide your website to pre-populate your workspace. Cloudy will visit your website to build an understanding of what your company does." })) : (_jsxs(_Fragment, { children: [welcomeMessage ? (_jsx("p", { className: "text-sm text-secondary", children: _jsx(Markdown, { children: welcomeMessage +
                                    " We've built an understanding of your company's mission, industry, and more." }) })) : null, _jsxs("p", { className: "text-sm text-secondary", children: [welcomeMessage ? "" : "Great! ", "First, we've pre-populated some information based on your website. Please review and adjust if needed."] })] })), _jsxs("form", { onSubmit: handleSubmit(onSubmit), className: "flex flex-col gap-4", children: [status === "initial" ? (_jsxs("div", { className: "flex flex-col gap-1", children: [_jsx("label", { htmlFor: "websiteUrl", className: "font-medium", children: "Website URL" }), _jsx(Input, __assign({}, register("websiteUrl", {
                                    pattern: {
                                        value: /^([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
                                        message: "Please enter a valid URL",
                                    },
                                }), { prefix: "https://", placeholder: "example.com", error: !!errors.websiteUrl, onPaste: handlePaste })), errors.websiteUrl && _jsx("p", { className: "mt-1 text-sm text-red-500", children: errors.websiteUrl.message }), scrapeSiteMutation.isError && (_jsx("p", { className: "mt-1 text-xs text-red-500", children: "Failed to read from the website. Please check the URL and try again." }))] })) : (_jsx(NameAndSlugFields, { register: register, errors: errors, isSlugAvailable: isSlugAvailable, handleSlugChange: handleSlugChange })), _jsx(Button, { type: "submit", disabled: isLoading, children: isLoading ? (_jsxs(_Fragment, { children: [_jsx(LoadingSpinner, { size: "xs", variant: "background" }), _jsx("span", { children: _jsx(RotatingLoadingMessage, {}) })] })) : status === "initial" ? (_jsxs(_Fragment, { children: [_jsx(SparklesIcon, { className: "size-4" }), _jsx("span", { children: "Read from website" })] })) : (_jsxs(_Fragment, { children: [_jsx(SparklesIcon, { className: "size-4" }), _jsx("span", { children: "Create Workspace" })] })) }), status === "ready" && (_jsx(Button, { type: "button", variant: "secondary", onClick: handleBack, children: "Back" }))] }), status === "initial" && (_jsxs(_Fragment, { children: [_jsx(Button, { variant: "secondary", onClick: handleSkip, type: "button", children: "Create manually" }), userHasWorkspaces && (_jsx(Link, { to: "/", children: _jsxs(Button, { variant: "ghost", className: "w-full text-secondary", children: [_jsx(ArrowLeftIcon, { className: "size-4" }), _jsx("span", { children: "Cancel workspace creation" })] }) }))] }))] }) }));
};
