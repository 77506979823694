import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronsDownIcon, ChevronsRightIcon } from "lucide-react";
import { useContext } from "react";
import { Button } from "src/components/Button";
import { cn } from "src/utils";
import { RelatedNotes } from "./RelatedNotes";
import { LinkedFiles } from "./rightSidebar/LinkedFiles";
import { ThoughtContext } from "./thoughtContext";
export var ControlColumn = function (_a) {
    var thoughtId = _a.thoughtId;
    var _b = useContext(ThoughtContext), hideControlColumn = _b.hideControlColumn, setHideControlColumn = _b.setHideControlColumn;
    return (_jsx("div", { className: cn("no-scrollbar relative box-border flex w-full shrink-0 overflow-y-scroll border-t border-border transition-all ease-in-out md:pt-0 lg:sticky lg:top-0 lg:h-screen lg:border-l", hideControlColumn
            ? "h-0 max-h-0 min-h-0 w-0 opacity-0"
            : "h-auto max-h-screen min-h-[40vh] opacity-100 lg:w-[24rem] xl:w-[28rem]"), children: _jsxs("div", { className: "absolute top-0 flex w-screen flex-col gap-4 px-4 pt-4 lg:w-[24rem] xl:w-[28rem]", children: [_jsx("div", { className: "hidden justify-center md:justify-end lg:flex lg:justify-start", children: _jsxs(Button, { variant: "outline", className: "text-secondary", size: "sm", onClick: function () { return setHideControlColumn(true); }, children: [_jsx(ChevronsRightIcon, { className: "hidden size-5 lg:block" }), _jsx(ChevronsDownIcon, { className: "block size-5 lg:hidden" }), _jsx("span", { children: "Hide panel" })] }) }), _jsxs("div", { className: "flex w-full flex-col gap-4 md:flex-row lg:flex-col", children: [_jsx(LinkedFiles, {}), _jsx("div", { className: "flex w-full flex-col gap-4 md:w-1/2 lg:w-full", children: _jsx(RelatedNotes, { thoughtId: thoughtId }) })] }), _jsx("div", { className: "h-8 shrink-0" })] }) }));
};
