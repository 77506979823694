var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { handleSupabaseError } from "@cloudy/utils/common";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { Navigate } from "react-router-dom";
import { supabase } from "src/clients/supabase";
import { useUser, useUserOptions, useUserRecord } from "src/stores/user";
import { LoadingView } from "../loading/LoadingView";
export var RedirectToDefaultOrg = function () {
    var _a, _b, _c;
    var user = useUser();
    var userOptions = useUserOptions();
    var userRecord = useUserRecord();
    var _d = useQuery({
        queryKey: ["workspaces"],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var orgs, _a, pendingInvites, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase.from("workspace_users").select("workspaces(slug)").eq("user_id", user.id)];
                    case 1:
                        orgs = _a.apply(void 0, [_c.sent()]);
                        _b = handleSupabaseError;
                        return [4 /*yield*/, supabase.from("user_pending_invites").select("id").eq("user_id", user.id)];
                    case 2:
                        pendingInvites = _b.apply(void 0, [_c.sent()]);
                        return [2 /*return*/, { orgs: orgs, pendingInvites: pendingInvites }];
                }
            });
        }); },
        staleTime: 0,
    }), data = _d.data, isLoading = _d.isLoading;
    var lastOpenedWorkspaceSlug = userOptions.get("last_opened_workspace");
    var wsSlug = lastOpenedWorkspaceSlug !== null && lastOpenedWorkspaceSlug !== void 0 ? lastOpenedWorkspaceSlug : (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.orgs) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.workspaces) === null || _c === void 0 ? void 0 : _c.slug;
    var validSlugs = useMemo(function () {
        return new Set(data === null || data === void 0 ? void 0 : data.orgs.flatMap(function (org) { return (org.workspaces ? [org.workspaces.slug] : []); }));
    }, [data]);
    useEffect(function () {
        if (!isLoading && lastOpenedWorkspaceSlug && !validSlugs.has(lastOpenedWorkspaceSlug)) {
            userOptions.set("last_opened_workspace", null);
        }
    }, [lastOpenedWorkspaceSlug, validSlugs, isLoading, userOptions]);
    if (userRecord.is_pending) {
        return _jsx(Navigate, { to: "/auth/complete-account-setup" });
    }
    if (data === null || data === void 0 ? void 0 : data.pendingInvites.length) {
        return _jsx(Navigate, { to: "/auth/invite-accept?inviteId=".concat(data.pendingInvites.at(0).id) });
    }
    if (isLoading || !data || (wsSlug && !validSlugs.has(wsSlug))) {
        return _jsx(LoadingView, {});
    }
    if (!wsSlug || validSlugs.size === 0) {
        return _jsx(Navigate, { to: "/onboarding/workspaces/new" });
    }
    return _jsx(Navigate, { to: "/workspaces/".concat(wsSlug) });
};
