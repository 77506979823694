var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { apiClient } from "src/api/client";
import { Button } from "src/components/Button";
import LoadingSpinner from "src/components/LoadingSpinner";
import { MainLayout } from "src/components/MainLayout";
import { useUser } from "src/stores/user";
import { getAllUserWorkspaces } from "src/stores/workspace";
export var ConnectGithubToWorkspaceView = function () {
    var user = useUser();
    var searchParams = useSearchParams()[0];
    var navigate = useNavigate();
    var allUserWorkspaces = useQuery({
        queryKey: ["all-user-workspaces"],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAllUserWorkspaces(user.id)];
                case 1: return [2 /*return*/, _a.sent()];
            }
        }); }); },
    }).data;
    var code = searchParams.get("code");
    var installationId = searchParams.get("installation_id");
    var _a = useQuery({
        queryKey: ["github-verification", code, installationId],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, apiClient.get("/api/integrations/github/verify-installation", {
                            params: {
                                code: code,
                                installationId: installationId,
                            },
                        })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        }); },
        retry: false,
        refetchOnWindowFocus: false,
        enabled: !!(code && installationId),
    }), verificationData = _a.data, isLoading = _a.isLoading, isError = _a.isError;
    var connectWorkspaceMutation = useMutation({
        mutationFn: function (workspaceId) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!verificationData || !installationId)
                            return [2 /*return*/];
                        return [4 /*yield*/, apiClient.post("/api/integrations/github/connect-workspace", {
                                workspaceId: workspaceId,
                                installationId: installationId,
                                accessToken: verificationData.accessToken,
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); },
    });
    var handleWorkspaceSelect = function (workspace) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, connectWorkspaceMutation.mutateAsync(workspace.id)];
                case 1:
                    _a.sent();
                    navigate("/workspaces/".concat(workspace.slug));
                    return [2 /*return*/];
            }
        });
    }); };
    if (isError) {
        return (_jsx(MainLayout, { className: "flex h-screen flex-col items-center justify-center", children: _jsx("div", { className: "flex flex-col items-center gap-4", children: _jsx("p", { className: "text-secondary", children: "Failed to verify the GitHub app installation, try uninstalling and reinstalling the GitHub app." }) }) }));
    }
    if (isLoading || !verificationData) {
        return (_jsx(MainLayout, { className: "flex h-screen flex-col items-center justify-center", children: _jsxs("div", { className: "flex flex-col items-center gap-4", children: [_jsx(LoadingSpinner, { size: "lg" }), _jsx("p", { className: "text-secondary", children: "Verifying GitHub installation..." })] }) }));
    }
    if (connectWorkspaceMutation.isPending) {
        return (_jsx(MainLayout, { className: "flex h-screen flex-col items-center justify-center", children: _jsxs("div", { className: "flex flex-col items-center gap-4", children: [_jsx(LoadingSpinner, { size: "lg" }), _jsx("p", { className: "text-secondary", children: "Connecting GitHub to workspace..." })] }) }));
    }
    return (_jsx(MainLayout, { className: "flex h-screen flex-col items-center justify-center", children: _jsxs("div", { className: "flex w-full max-w-md flex-col gap-4 rounded-md border border-border p-6", children: [_jsx("h1", { className: "font-display text-2xl font-bold", children: "Connect GitHub to Workspace" }), _jsxs("p", { className: "text-sm text-secondary", children: ["Select which workspace you'd like to connect the GitHub installation for", " ", _jsx("span", { className: "font-medium", children: verificationData.account.login }), " to:"] }), _jsx("div", { className: "flex flex-col gap-2", children: allUserWorkspaces === null || allUserWorkspaces === void 0 ? void 0 : allUserWorkspaces.map(function (workspace) { return (_jsx(Button, { variant: "outline", className: "justify-start", onClick: function () { return handleWorkspaceSelect(workspace); }, children: workspace.name }, workspace.id)); }) })] }) }));
};
