var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { handleSupabaseError } from "@cloudy/utils/common";
import * as Sentry from "@sentry/react";
import debounce from "debounce";
import { EventEmitter } from "events";
import { fromUint8Array, toUint8Array } from "js-base64";
import { Awareness, applyAwarenessUpdate, encodeAwarenessUpdate, removeAwarenessStates } from "y-protocols/awareness";
import * as Y from "yjs";
var SupabaseProvider = /** @class */ (function (_super) {
    __extends(SupabaseProvider, _super);
    function SupabaseProvider(supabase, config) {
        var _a;
        var _this = _super.call(this) || this;
        _this.configuration = {
            name: "",
            // @ts-ignore
            document: undefined,
            // @ts-ignore
            awareness: undefined,
            databaseDetails: {
                schema: "",
                table: "",
                updateColumns: { name: "", content: "" },
                conflictColumns: "",
            },
            disableUpdatesRef: { current: false },
        };
        _this.channel = null;
        _this.awareness = null;
        _this.version = 0;
        _this.callbacks = {};
        _this.isConnected = false;
        _this.setConfiguration(config);
        _this.configuration.document = config.document ? config.document : new Y.Doc();
        // @ts-ignore
        _this.awareness = config.awareness ? config.awareness : new Awareness(_this.configuration.document);
        _this.supabase = supabase;
        _this.on("connect", _this.onConnect);
        _this.on("disconnect", _this.onDisconnect);
        _this.debouncedDocumentUpdateHandler = debounce(_this.documentUpdateHandler.bind(_this), 50);
        _this.debouncedAwarenessUpdateHandler = debounce(_this.onAwarenessUpdate.bind(_this), 50);
        _this.document.on("update", _this.debouncedDocumentUpdateHandler);
        (_a = _this.awareness) === null || _a === void 0 ? void 0 : _a.on("update", _this.debouncedAwarenessUpdateHandler);
        _this.connect();
        if (typeof window !== "undefined") {
            window.addEventListener("beforeunload", _this.removeSelfFromAwarenessOnUnload);
        }
        else if (typeof process !== "undefined") {
            process.on("exit", function () { return _this.removeSelfFromAwarenessOnUnload; });
        }
        return _this;
    }
    SupabaseProvider.prototype.setConfiguration = function (configuration) {
        if (configuration === void 0) { configuration = {}; }
        this.configuration = __assign(__assign({}, this.configuration), configuration);
    };
    Object.defineProperty(SupabaseProvider.prototype, "document", {
        get: function () {
            return this.configuration.document;
        },
        enumerable: false,
        configurable: true
    });
    SupabaseProvider.prototype.documentUpdateHandler = function (update, origin) {
        return __awaiter(this, void 0, void 0, function () {
            var dbDocument, res;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (origin === this) {
                            return [2 /*return*/];
                        }
                        if (this.configuration.disableUpdatesRef.current) {
                            console.log("updates disabled");
                            return [2 /*return*/];
                        }
                        dbDocument = fromUint8Array(Y.encodeStateAsUpdate(this.document));
                        return [4 /*yield*/, this.supabase.from(this.configuration.databaseDetails.table).upsert((_a = {},
                                _a[this.configuration.databaseDetails.updateColumns.name] = this.configuration.id,
                                _a[this.configuration.databaseDetails.updateColumns.content] = dbDocument,
                                _a), this.configuration.databaseDetails.conflictColumns
                                ? { onConflict: this.configuration.databaseDetails.conflictColumns }
                                : {})];
                    case 1:
                        res = _b.sent();
                        if (res.status === 201 || res.status === 200) {
                            this.emit("save", this.version);
                            return [2 /*return*/, this.channel.send({
                                    type: "broadcast",
                                    event: "update",
                                    payload: { dbDocument: dbDocument },
                                })];
                        }
                        else {
                            return [2 /*return*/, Error("Document not stored due to error: ".concat(res.error))];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SupabaseProvider.prototype.onAwarenessUpdate = function (_a, origin) {
        var added = _a.added, updated = _a.updated, removed = _a.removed;
        var changedClients = added.concat(updated).concat(removed);
        var awarenessUpdate = encodeAwarenessUpdate(this.awareness, changedClients);
        if (this.channel) {
            this.channel.send({
                type: "broadcast",
                event: "awareness",
                payload: { awareness: fromUint8Array(awarenessUpdate) },
            });
        }
    };
    SupabaseProvider.prototype.removeSelfFromAwarenessOnUnload = function () {
        if (this.awareness) {
            removeAwarenessStates(this.awareness, [this.document.clientID], "window unload");
        }
    };
    SupabaseProvider.prototype.onConnect = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, _a, _b, dbDocument, awarenessUpdate;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        console.log("onConnect");
                        _a = handleSupabaseError;
                        return [4 /*yield*/, this.supabase
                                .from(this.configuration.databaseDetails.table)
                                .select("".concat(this.configuration.databaseDetails.updateColumns.content))
                                .eq(this.configuration.databaseDetails.updateColumns.name, this.configuration.id)
                                .maybeSingle()];
                    case 1:
                        data = _a.apply(void 0, [_d.sent()]);
                        if (!!data) return [3 /*break*/, 3];
                        _b = handleSupabaseError;
                        return [4 /*yield*/, this.supabase
                                .from(this.configuration.databaseDetails.table)
                                .insert((_c = {},
                                _c[this.configuration.databaseDetails.updateColumns.name] = this.configuration.id,
                                _c[this.configuration.databaseDetails.updateColumns.content] = "",
                                _c))
                                .select()
                                .single()];
                    case 2:
                        data = _b.apply(void 0, [_d.sent()]);
                        _d.label = 3;
                    case 3:
                        if (data && data[this.configuration.databaseDetails.updateColumns.content]) {
                            try {
                                dbDocument = toUint8Array(data[this.configuration.databaseDetails.updateColumns.content]);
                                this.version++;
                                Y.applyUpdate(this.document, dbDocument);
                            }
                            catch (error) {
                                console.error(error);
                            }
                        }
                        this.emit("synced");
                        this.isConnected = true;
                        if (this.awareness && this.awareness.getLocalState() !== null) {
                            awarenessUpdate = encodeAwarenessUpdate(this.awareness, [this.document.clientID]);
                            this.emit("awareness", awarenessUpdate);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SupabaseProvider.prototype.connect = function () {
        console.log("connecting");
        this.channel = this.supabase.channel(this.configuration.name);
        this.startSync();
    };
    SupabaseProvider.prototype.startSync = function () {
        var _this = this;
        this.channel.on("broadcast", { event: "update" }, function (event) {
            _this.onReceiveUpdate(event);
        })
            .on("broadcast", { event: "awareness" }, function (_a) {
            var payload = _a.payload;
            var update = toUint8Array(payload.awareness);
            applyAwarenessUpdate(_this.awareness, update, _this);
        })
            .subscribe(function (status, err) {
            console.log("subscribing", status, err);
            switch (status) {
                case "SUBSCRIBED":
                    _this.emit("connect", _this);
                    break;
                case "CHANNEL_ERROR":
                    // this.emit('error', this);
                    Sentry.captureException(err);
                    _this.emit("disconnect", _this);
                    break;
                case "TIMED_OUT":
                    _this.emit("disconnect", _this);
                    break;
                case "CLOSED":
                    _this.emit("disconnect", _this);
                    break;
                default:
                    break;
            }
        });
    };
    SupabaseProvider.prototype.onReceiveUpdate = function (_a) {
        var event = _a.event, payload = _a.payload;
        var update = toUint8Array(payload.dbDocument);
        try {
            this.version++;
            Y.applyUpdate(this.document, update, this);
        }
        catch (error) {
            console.error(error);
        }
    };
    SupabaseProvider.prototype.disconnect = function () {
        if (this.channel) {
            this.supabase.removeChannel(this.channel);
            this.channel = null;
        }
    };
    SupabaseProvider.prototype.onDisconnect = function () {
        var _this = this;
        console.log("onDisconnect");
        this.emit("status", [{ status: "disconnected" }]);
        if (this.awareness) {
            var states = Array.from(this.awareness.getStates().keys()).filter(function (client) { return client !== _this.document.clientID; });
            removeAwarenessStates(this.awareness, states, this);
        }
    };
    SupabaseProvider.prototype.destroy = function () {
        var _this = this;
        var _a;
        console.log("destroy");
        this.removeAllListeners();
        this.disconnect();
        this.document.off("update", this.debouncedDocumentUpdateHandler);
        (_a = this.awareness) === null || _a === void 0 ? void 0 : _a.off("update", this.debouncedAwarenessUpdateHandler);
        if (typeof window !== "undefined") {
            window.removeEventListener("beforeunload", this.removeSelfFromAwarenessOnUnload);
        }
        else if (typeof process !== "undefined") {
            // @ts-ignore
            process.off("exit", function () { return _this.removeSelfFromAwarenessOnUnload; });
        }
        if (this.channel) {
            this.disconnect();
        }
    };
    return SupabaseProvider;
}(EventEmitter));
export { SupabaseProvider };
