import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState } from "react";
import { useLocalStorage, useLocation } from "react-use";
export var SidebarContext = createContext({
    isSidebarCollapsed: false,
    setIsSidebarCollapsed: function () { },
    isSidebarFixed: false,
    setIsSidebarFixed: function () { },
    isMobileSidebarOpen: false,
    setIsMobileSidebarOpen: function () { },
});
export var SidebarProvider = function (_a) {
    var children = _a.children;
    var _b = useLocalStorage("isSidebarCollapsed", false), isSidebarCollapsed = _b[0], setIsSidebarCollapsed = _b[1];
    var _c = useState(false), isMobileSidebarOpen = _c[0], setIsMobileSidebarOpen = _c[1];
    var _d = useState(false), isSidebarFixed = _d[0], setIsSidebarFixed = _d[1];
    var location = useLocation();
    useEffect(function () {
        setIsMobileSidebarOpen(false);
    }, [location.pathname]);
    return (_jsx(SidebarContext.Provider, { value: {
            isSidebarCollapsed: isSidebarCollapsed,
            setIsSidebarCollapsed: setIsSidebarCollapsed,
            isMobileSidebarOpen: isMobileSidebarOpen,
            setIsMobileSidebarOpen: setIsMobileSidebarOpen,
            isSidebarFixed: isSidebarFixed,
            setIsSidebarFixed: setIsSidebarFixed,
        }, children: children }));
};
export var useSidebarContext = function (_a) {
    var _b = _a === void 0 ? {} : _a, isFixed = _b.isFixed;
    var context = useContext(SidebarContext);
    useEffect(function () {
        if (typeof isFixed === "boolean") {
            context.setIsSidebarFixed(isFixed);
        }
    }, [isFixed, context]);
    return context;
};
