import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { FileSearch } from "./FileSearch";
export var AiTextAreaMentionHandler = forwardRef(function (_a, ref) {
    var query = _a.query, command = _a.command;
    var fileSearchRef = useRef(null);
    useImperativeHandle(ref, function () { return ({
        onKeyDown: function (_a) {
            var _b;
            var event = _a.event, hide = _a.hide;
            if (event.key === "Escape") {
                hide();
                return true;
            }
            return (_b = fileSearchRef.current) === null || _b === void 0 ? void 0 : _b.onKeyDown({ event: event, hide: hide });
        },
    }); });
    return (_jsx("div", { className: "pointer-events-auto overflow-visible rounded-md border border-border bg-background shadow-md", children: _jsx(FileSearch, { ref: fileSearchRef, query: query, onSelect: function (file) {
                command({
                    id: file.path,
                    label: file.fileName,
                    url: file.fileUrl,
                });
            }, shouldMention: true }) }));
});
