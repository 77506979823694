var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Hotkey } from "@cloudy/ui";
import { useQuery } from "@tanstack/react-query";
import { ChevronRightIcon, SearchIcon, TriangleAlertIcon, XIcon } from "lucide-react";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { apiClient } from "src/api/client";
import { Button } from "src/components/Button";
import { cn } from "src/utils";
import { useProject } from "../projects/ProjectContext";
import { useAiTextAreaContext } from "./AiTextAreaContext";
import { FILE_REFERENCE_LIMIT } from "./constants";
var buildFileTree = function (files) {
    var tree = [];
    var itemsByPath = {};
    // Sort function to be used at each level
    var sortItems = function (items) {
        return items.sort(function (a, b) {
            if (a.type === "directory" && b.type !== "directory")
                return -1;
            if (a.type !== "directory" && b.type === "directory")
                return 1;
            return a.fileName.localeCompare(b.fileName);
        });
    };
    // First convert all items to FileTreeItems
    files.forEach(function (file) {
        var item = __assign(__assign({}, file), { fileName: file.path.split("/").pop(), children: file.type === "directory" ? [] : undefined });
        itemsByPath[file.path] = item;
    });
    // Then build the tree structure
    files.forEach(function (file) {
        var _a;
        var parentPath = file.path.split("/").slice(0, -1).join("/");
        if (parentPath && itemsByPath[parentPath]) {
            (_a = itemsByPath[parentPath].children) === null || _a === void 0 ? void 0 : _a.push(itemsByPath[file.path]);
        }
        else {
            tree.push(itemsByPath[file.path]);
        }
    });
    // Sort all levels of the tree
    var sortTree = function (items) {
        sortItems(items);
        items.forEach(function (item) {
            if (item.children) {
                sortTree(item.children);
            }
        });
    };
    sortTree(tree);
    return tree;
};
var useRepoPaths = function () {
    var project = useProject();
    return useQuery({
        queryKey: [project === null || project === void 0 ? void 0 : project.id, "repoFileAutocomplete"],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var results;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!project) {
                            throw new Error("Project not loaded");
                        }
                        return [4 /*yield*/, apiClient.get("/api/integrations/github/repo-files", {
                                params: {
                                    projectId: project.id,
                                },
                            })];
                    case 1:
                        results = _a.sent();
                        return [2 /*return*/, buildFileTree(results.data.paths)];
                }
            });
        }); },
        enabled: !!project,
    });
};
var FileTreeItemComponent = function (_a) {
    var item = _a.item, _b = _a.depth, depth = _b === void 0 ? 0 : _b, expandedDirs = _a.expandedDirs, onToggle = _a.onToggle, onSelect = _a.onSelect, selectedFiles = _a.selectedFiles, onRemove = _a.onRemove, keyboardSelectedPath = _a.keyboardSelectedPath, scrollRef = _a.scrollRef;
    var paddingLeft = "".concat(depth * 1.25 + 0.25, "rem");
    var isExpanded = expandedDirs.has(item.path);
    var isDirectory = item.type === "directory";
    var isSelected = selectedFiles.has(item.path);
    var itemRef = useRef(null);
    useEffect(function () {
        if (keyboardSelectedPath === item.path && itemRef.current && scrollRef.current) {
            var itemElement = itemRef.current;
            var scrollContainer = scrollRef.current;
            var itemRect = itemElement.getBoundingClientRect();
            var containerRect = scrollContainer.getBoundingClientRect();
            if (itemRect.bottom > containerRect.bottom) {
                itemElement.scrollIntoView({ block: "nearest" });
            }
            else if (itemRect.top < containerRect.top) {
                itemElement.scrollIntoView({ block: "nearest" });
            }
        }
    }, [keyboardSelectedPath, item.path]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { ref: itemRef, className: cn("relative flex cursor-pointer flex-row items-center gap-1 rounded px-2 py-0.5 text-sm hover:bg-card", item.path === keyboardSelectedPath ? "bg-accent/10" : isSelected ? "bg-card/50" : ""), style: { paddingLeft: paddingLeft }, onClick: function () { return (isDirectory ? onToggle(item.path) : onSelect(item)); }, children: [depth > 0 && (_jsx(_Fragment, { children: __spreadArray([], Array(depth), true).map(function (_, index) { return (_jsx("div", { className: "absolute left-0 h-full w-px bg-border", style: { left: "".concat(index * 1.125 + 0.875, "rem") } }, index)); }) })), _jsx("div", { children: isDirectory ? (_jsx(ChevronRightIcon, { className: "size-4 transition-transform ".concat(isExpanded ? "rotate-90" : "") })) : (_jsx("div", { className: "h-4 w-2" })) }), _jsx("span", { className: "shrink-0", children: item.fileName }), _jsx("span", { className: "flex-1 truncate text-xs text-tertiary", children: item.path }), isSelected && !isDirectory && (_jsx(Button, { size: "icon-xs", variant: "ghost", className: "text-secondary hover:bg-red-600/20 hover:text-red-600", onClick: function (e) {
                            e.stopPropagation();
                            onRemove(item);
                        }, children: _jsx(XIcon, { className: "size-4" }) }))] }), isExpanded && item.children && (_jsx("div", { className: "relative flex flex-col gap-0.5", children: item.children.map(function (child) { return (_jsx(FileTreeItemComponent, { item: child, depth: depth + 1, expandedDirs: expandedDirs, onToggle: onToggle, onSelect: onSelect, selectedFiles: selectedFiles, onRemove: onRemove, keyboardSelectedPath: keyboardSelectedPath, scrollRef: scrollRef }, child.path)); }) }))] }));
};
var LoadingState = function () {
    return (_jsx("div", { className: "flex flex-col gap-0.5 px-2", children: __spreadArray([], Array(3), true).map(function (_, i) { return (_jsxs("div", { className: "flex h-7 animate-pulse items-center gap-2 rounded bg-card/50 px-2", children: [_jsx("div", { className: "h-4 w-4 rounded bg-card" }), _jsx("div", { className: "h-4 w-24 rounded bg-card" })] }, i)); }) }));
};
var flattenTree = function (tree, expandedDirs) {
    var flattened = [];
    var traverse = function (items) {
        items.forEach(function (item) {
            flattened.push(item);
            if (item.type === "directory" && expandedDirs.has(item.path) && item.children) {
                traverse(item.children);
            }
        });
    };
    traverse(tree);
    return flattened;
};
export var FileSearch = forwardRef(function (_a, ref) {
    var query = _a.query, onSelect = _a.onSelect, shouldMention = _a.shouldMention;
    var _b = useAiTextAreaContext(), fileReferences = _b.fileReferences, setFileReferences = _b.setFileReferences;
    var _c = useRepoPaths(), repoPaths = _c.data, isLoading = _c.isLoading;
    var _d = useState(new Set()), expandedDirs = _d[0], setExpandedDirs = _d[1];
    var selectedFiles = useMemo(function () { return new Set(fileReferences.map(function (f) { return f.path; })); }, [fileReferences]);
    var isAtFileLimit = fileReferences.length >= FILE_REFERENCE_LIMIT;
    var filteredFiles = useMemo(function () {
        if (!repoPaths)
            return [];
        if (query) {
            var searchResults_1 = [];
            var search_1 = function (items) {
                items.forEach(function (item) {
                    if (item.path.toLowerCase().includes(query.toLowerCase())) {
                        searchResults_1.push(item);
                    }
                    if (item.children) {
                        search_1(item.children);
                    }
                });
            };
            search_1(repoPaths);
            return searchResults_1;
        }
        return repoPaths;
    }, [repoPaths, query]);
    var toggleDirectory = function (path) {
        setExpandedDirs(function (prev) {
            var next = new Set(prev);
            if (next.has(path)) {
                next.delete(path);
            }
            else {
                next.add(path);
            }
            return next;
        });
    };
    var handleSelect = function (file) {
        if (file.type !== "directory" && !selectedFiles.has(file.path) && !isAtFileLimit) {
            setFileReferences(__spreadArray(__spreadArray([], fileReferences, true), [
                __assign(__assign({}, file), { mentioned: shouldMention }),
            ], false));
            onSelect === null || onSelect === void 0 ? void 0 : onSelect(file);
        }
    };
    var handleRemove = function (file) {
        setFileReferences(fileReferences.filter(function (f) { return f.path !== file.path; }));
    };
    var _e = useState(null), keyboardSelectedPath = _e[0], setKeyboardSelectedPath = _e[1];
    // Get flattened list of visible items for keyboard navigation
    var flattenedItems = useMemo(function () {
        return flattenTree(filteredFiles, expandedDirs);
    }, [filteredFiles, expandedDirs]);
    var handleArrowDown = function () {
        var currentIndex = keyboardSelectedPath
            ? flattenedItems.findIndex(function (item) { return item.path === keyboardSelectedPath; })
            : -1;
        var nextIndex = currentIndex + 1;
        if (nextIndex < flattenedItems.length) {
            setKeyboardSelectedPath(flattenedItems[nextIndex].path);
        }
    };
    var handleArrowUp = function () {
        var currentIndex = keyboardSelectedPath
            ? flattenedItems.findIndex(function (item) { return item.path === keyboardSelectedPath; })
            : 0;
        var nextIndex = currentIndex - 1;
        if (nextIndex >= 0) {
            setKeyboardSelectedPath(flattenedItems[nextIndex].path);
        }
    };
    var handleArrowRight = function () {
        if (!keyboardSelectedPath)
            return;
        var selectedItem = flattenedItems.find(function (item) { return item.path === keyboardSelectedPath; });
        if ((selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.type) === "directory") {
            setExpandedDirs(function (prev) { return new Set(__spreadArray(__spreadArray([], Array.from(prev), true), [selectedItem.path], false)); });
        }
    };
    var handleArrowLeft = function () {
        if (!keyboardSelectedPath)
            return;
        var selectedItem = flattenedItems.find(function (item) { return item.path === keyboardSelectedPath; });
        if ((selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.type) === "directory" && expandedDirs.has(selectedItem.path)) {
            setExpandedDirs(function (prev) {
                var next = new Set(prev);
                next.delete(selectedItem.path);
                return next;
            });
        }
    };
    var handleEnter = function () {
        if (!keyboardSelectedPath)
            return;
        var selectedItem = flattenedItems.find(function (item) { return item.path === keyboardSelectedPath; });
        if (!selectedItem)
            return;
        if (selectedItem.type === "directory") {
            toggleDirectory(selectedItem.path);
        }
        else {
            if (selectedFiles.has(selectedItem.path)) {
                handleRemove(selectedItem);
            }
            else {
                handleSelect(selectedItem);
            }
        }
    };
    useImperativeHandle(ref, function () { return ({
        onKeyDown: function (_a) {
            var event = _a.event, hide = _a.hide;
            switch (event.key) {
                case "ArrowDown":
                    handleArrowDown();
                    return true;
                case "ArrowUp":
                    handleArrowUp();
                    return true;
                case "ArrowRight":
                    handleArrowRight();
                    return true;
                case "ArrowLeft":
                    handleArrowLeft();
                    return true;
                case "Enter":
                    handleEnter();
                    return true;
                default:
                    return false;
            }
        },
    }); });
    // Update keyboardSelectedPath when filteredFiles changes
    useEffect(function () {
        if (filteredFiles.length > 0) {
            setKeyboardSelectedPath(filteredFiles[0].path);
        }
        else {
            setKeyboardSelectedPath(null);
        }
    }, [filteredFiles]);
    var scrollContainerRef = useRef(null);
    return (_jsxs(_Fragment, { children: [_jsx("div", { ref: scrollContainerRef, className: "no-scrollbar pointer-events-auto flex max-h-[40vh] min-h-36 w-[28rem] flex-col gap-0.5 overflow-y-auto overscroll-contain px-2 py-2", children: isLoading ? (_jsx(LoadingState, {})) : filteredFiles.length > 0 ? (filteredFiles.map(function (file) { return (_jsx(FileTreeItemComponent, { item: file, expandedDirs: expandedDirs, onToggle: toggleDirectory, onSelect: handleSelect, selectedFiles: selectedFiles, onRemove: handleRemove, keyboardSelectedPath: keyboardSelectedPath, scrollRef: scrollContainerRef }, file.path)); })) : query ? (_jsxs("div", { className: "flex flex-col items-center justify-center gap-2 py-8", children: [_jsx(SearchIcon, { className: "size-5 text-tertiary" }), _jsxs("div", { className: "text-sm text-tertiary", children: ["No results found for ", _jsxs("span", { className: "text-primary", children: ["\"", query, "\""] })] })] })) : null }), _jsxs("div", { className: "flex w-full flex-col gap-2 border-t border-border px-3 py-2", children: [isAtFileLimit && (_jsxs("div", { className: "flex w-full items-center gap-1", children: [_jsx(TriangleAlertIcon, { className: "size-4 text-red-600" }), _jsx("span", { className: "text-xs text-red-600", children: "Maximum of 8 files reached" })] })), _jsxs("div", { className: "flex w-full items-center gap-3", children: [_jsx("div", { className: "text-xs text-tertiary", children: "Type to search" }), _jsxs("div", { className: "flex items-center gap-1", children: [_jsx(Hotkey, { keys: ["up", "down"] }), _jsx("span", { className: "text-xs text-tertiary", children: "Move selection" })] }), _jsxs("div", { className: "flex items-center gap-1", children: [_jsx(Hotkey, { keys: ["Enter"] }), _jsx("span", { className: "text-xs text-tertiary", children: "Select file/Open folder" })] })] })] })] }));
});
