import FileHandler from "@tiptap-pro/extension-file-handler";
import { upload } from "@vercel/blob/client";
import { apiClient } from "src/api/client";
export var makeFilePath = function (thoughtId, attachmentId, fileName) {
    var fileExtension = fileName.split(".").pop();
    return "user-content/thoughts/".concat(thoughtId, "/").concat(attachmentId, ".").concat(fileExtension);
};
export var createFileHandlerExtension = function (thoughtId) {
    var handleUpload = function (currentEditor, files, pos) {
        files.forEach(function (file) {
            var attachmentId = crypto.randomUUID();
            if (pos) {
                currentEditor.commands.insertContentAt(pos, {
                    type: "pending-attachment",
                    attrs: {
                        attachmentId: attachmentId,
                    },
                });
            }
            else {
                currentEditor.commands.insertContent({
                    type: "pending-attachment",
                    attrs: {
                        attachmentId: attachmentId,
                    },
                });
            }
            upload(makeFilePath(thoughtId, attachmentId, file.name), file, {
                access: "public",
                handleUploadUrl: apiClient.defaults.baseURL + "/api/thoughts/".concat(thoughtId, "/attachments/upload"),
                clientPayload: JSON.stringify({
                    Authorization: apiClient.defaults.headers.common.Authorization,
                }),
            }).then(function (attachment) {
                var pendingAttachments = currentEditor.$nodes("pending-attachment");
                var pendingAttachment = pendingAttachments === null || pendingAttachments === void 0 ? void 0 : pendingAttachments.find(function (node) { return node.node.attrs.attachmentId === attachmentId; });
                if (!pendingAttachment) {
                    return;
                }
                currentEditor.state.doc.forEach(function (node, offset) {
                    if (node.attrs.attachmentId === attachmentId) {
                        currentEditor
                            .chain()
                            .setNodeSelection(offset)
                            .deleteSelection()
                            .insertContent({
                            type: "image",
                            attrs: {
                                src: attachment.url,
                            },
                        })
                            .run();
                    }
                });
            });
        });
    };
    return FileHandler.configure({
        allowedMimeTypes: ["image/png", "image/jpeg", "image/gif", "image/webp"],
        onDrop: function (currentEditor, files, pos) {
            handleUpload(currentEditor, files, pos);
        },
        onPaste: function (currentEditor, files, htmlContent) {
            if (htmlContent) {
                return;
            }
            handleUpload(currentEditor, files);
        },
    });
};
