var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Node, mergeAttributes } from "@tiptap/core";
import { PluginKey } from "@tiptap/pm/state";
import { ReactRenderer } from "@tiptap/react";
import Suggestion from "@tiptap/suggestion";
import tippy from "tippy.js";
import { AiTextAreaMentionHandler } from "./AiTextAreaMentionHandler";
export var aiTextAreaMention = {
    render: function () {
        var reactRenderer;
        var popup;
        return {
            onStart: function (props) {
                if (!props.clientRect) {
                    return;
                }
                reactRenderer = new ReactRenderer(AiTextAreaMentionHandler, {
                    props: props,
                    editor: props.editor,
                });
                popup = tippy("body", {
                    getReferenceClientRect: props.clientRect,
                    appendTo: function () { return document.body; },
                    content: reactRenderer.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: "manual",
                    placement: "bottom-start",
                    maxWidth: "none",
                    theme: "mention-popup",
                    popperOptions: {
                        strategy: "fixed",
                        modifiers: [
                            {
                                name: "flip",
                                options: {
                                    fallbackPlacements: ["top-start"],
                                },
                            },
                            {
                                name: "preventOverflow",
                                options: {
                                    mainAxis: false, // Allows the popup to overflow the viewport
                                },
                            },
                        ],
                    },
                    onCreate: function (instance) {
                        instance.popper.classList.add("pointer-events-auto");
                        instance.popper.classList.add("overflow-visible");
                        // Allow wheel events to propagate to scrollable content
                        instance.popper.addEventListener("wheel", function (e) {
                            e.stopPropagation();
                        }, { passive: true });
                    },
                });
            },
            onUpdate: function (props) {
                reactRenderer.updateProps(props);
                if (!props.clientRect) {
                    return;
                }
                popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                });
            },
            onKeyDown: function (props) {
                var _a;
                var hide = function () {
                    popup[0].hide();
                };
                // @ts-ignore
                return (_a = reactRenderer.ref) === null || _a === void 0 ? void 0 : _a.onKeyDown(__assign(__assign({}, props), { hide: hide }));
            },
            onExit: function () {
                popup[0].destroy();
                reactRenderer.destroy();
            },
        };
    },
};
/**
 * The plugin key for the mention plugin.
 * @default 'mention'
 */
export var MentionPluginKey = new PluginKey("mention");
/**
 * This extension allows you to insert mentions into the editor.
 * @see https://www.tiptap.dev/api/extensions/mention
 */
export var AiTextAreaMention = Node.create({
    name: "mention",
    addOptions: function () {
        var _this = this;
        return {
            HTMLAttributes: {},
            renderText: function (_a) {
                var _b;
                var options = _a.options, node = _a.node;
                return "".concat(options.suggestion.char).concat((_b = node.attrs.label) !== null && _b !== void 0 ? _b : node.attrs.id);
            },
            deleteTriggerWithBackspace: false,
            renderHTML: function (_a) {
                var _b;
                var options = _a.options, node = _a.node;
                return [
                    "a",
                    mergeAttributes({ class: "mention", href: node.attrs.url }, this.HTMLAttributes, options.HTMLAttributes),
                    "".concat(options.suggestion.char).concat((_b = node.attrs.label) !== null && _b !== void 0 ? _b : node.attrs.id),
                ];
            },
            suggestion: {
                char: "@",
                pluginKey: MentionPluginKey,
                command: function (_a) {
                    var _b, _c;
                    var editor = _a.editor, range = _a.range, props = _a.props;
                    // increase range.to by one when the next node is of type "text"
                    // and starts with a space character
                    var nodeAfter = editor.view.state.selection.$to.nodeAfter;
                    var overrideSpace = (_b = nodeAfter === null || nodeAfter === void 0 ? void 0 : nodeAfter.text) === null || _b === void 0 ? void 0 : _b.startsWith(" ");
                    if (overrideSpace) {
                        range.to += 1;
                    }
                    editor
                        .chain()
                        .focus()
                        .insertContentAt(range, [
                        {
                            type: _this.name,
                            attrs: props,
                        },
                        {
                            type: "text",
                            text: " ",
                        },
                    ])
                        .run();
                    (_c = window.getSelection()) === null || _c === void 0 ? void 0 : _c.collapseToEnd();
                },
                allow: function (_a) {
                    var state = _a.state, range = _a.range;
                    var $from = state.doc.resolve(range.from);
                    var type = state.schema.nodes[_this.name];
                    var allow = !!$from.parent.type.contentMatch.matchType(type);
                    return allow;
                },
            },
        };
    },
    group: "inline",
    inline: true,
    selectable: false,
    atom: true,
    addAttributes: function () {
        return {
            id: {
                default: null,
                parseHTML: function (element) { return element.getAttribute("data-id"); },
                renderHTML: function (attributes) {
                    if (!attributes.id) {
                        return {};
                    }
                    return {
                        "data-id": attributes.id,
                    };
                },
            },
            label: {
                default: null,
                parseHTML: function (element) { return element.getAttribute("data-label"); },
                renderHTML: function (attributes) {
                    if (!attributes.label) {
                        return {};
                    }
                    return {
                        "data-label": attributes.label,
                    };
                },
            },
            url: {
                default: null,
                parseHTML: function (element) { return element.getAttribute("href"); },
                renderHTML: function (attributes) {
                    return { href: attributes.url };
                },
            },
        };
    },
    addStorage: function () {
        return {
            markdown: {
                serialize: function (state, node) {
                    state.write("[@".concat(node.attrs.label, "](").concat(node.attrs.url, ")"));
                },
                parse: {
                // We don't parse from markdown.
                },
            },
        };
    },
    parseHTML: function () {
        return [
            {
                tag: "a[data-type=\"".concat(this.name, "\"]"),
            },
        ];
    },
    renderHTML: function (_a) {
        var node = _a.node, HTMLAttributes = _a.HTMLAttributes;
        if (this.options.renderLabel !== undefined) {
            console.warn("renderLabel is deprecated use renderText and renderHTML instead");
            return [
                "a",
                mergeAttributes({ "data-type": this.name }, this.options.HTMLAttributes, HTMLAttributes),
                this.options.renderLabel({
                    options: this.options,
                    node: node,
                }),
            ];
        }
        var mergedOptions = __assign({}, this.options);
        mergedOptions.HTMLAttributes = mergeAttributes({ "data-type": this.name }, this.options.HTMLAttributes, HTMLAttributes);
        var html = this.options.renderHTML({
            options: mergedOptions,
            node: node,
        });
        if (typeof html === "string") {
            return ["a", mergeAttributes({ "data-type": this.name }, this.options.HTMLAttributes, HTMLAttributes), html];
        }
        return html;
    },
    renderText: function (_a) {
        var node = _a.node;
        if (this.options.renderLabel !== undefined) {
            console.warn("renderLabel is deprecated use renderText and renderHTML instead");
            return this.options.renderLabel({
                options: this.options,
                node: node,
            });
        }
        return this.options.renderText({
            options: this.options,
            node: node,
        });
    },
    addKeyboardShortcuts: function () {
        var _this = this;
        return {
            Backspace: function () {
                return _this.editor.commands.command(function (_a) {
                    var tr = _a.tr, state = _a.state;
                    var isMention = false;
                    var selection = state.selection;
                    var empty = selection.empty, anchor = selection.anchor;
                    if (!empty) {
                        return false;
                    }
                    state.doc.nodesBetween(anchor - 1, anchor, function (node, pos) {
                        if (node.type.name === _this.name) {
                            isMention = true;
                            tr.insertText(_this.options.deleteTriggerWithBackspace ? "" : _this.options.suggestion.char || "", pos, pos + node.nodeSize);
                            return false;
                        }
                    });
                    return isMention;
                });
            },
        };
    },
    addProseMirrorPlugins: function () {
        return [
            Suggestion(__assign({ editor: this.editor }, this.options.suggestion)),
        ];
    },
});
