import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PlusIcon, SearchIcon } from "lucide-react";
import { useRef, useState } from "react";
import { Button } from "src/components/Button";
import { Dropdown } from "src/components/Dropdown";
import { Input } from "src/components/Input";
import { useConnectFileToDocument, useDisconnectFileFromDocument } from "../thoughtDetail/linking";
import { AiTextAreaContext, useAiTextAreaContext } from "./AiTextAreaContext";
import { FileReferencePill } from "./FileReferencePill";
import { FileSearch } from "./FileSearch";
import { FILE_REFERENCE_LIMIT } from "./constants";
export var FileReferenceRowStandalone = function (_a) {
    var disableAdd = _a.disableAdd, addButtonText = _a.addButtonText, fileReferences = _a.fileReferences, setFileReferences = _a.setFileReferences, showConnectTooltip = _a.showConnectTooltip, showUnlinkIconInsteadOfX = _a.showUnlinkIconInsteadOfX;
    return (_jsx(AiTextAreaContext.Provider, { value: { fileReferences: fileReferences, setFileReferences: setFileReferences, existingLinkedFiles: [] }, children: _jsx(FileReferenceRow, { disableAdd: disableAdd, addButtonText: addButtonText, showConnectTooltip: showConnectTooltip, showUnlinkIconInsteadOfX: showUnlinkIconInsteadOfX }) }));
};
export var FileReferenceRow = function (_a) {
    var disableAdd = _a.disableAdd, _b = _a.addButtonText, addButtonText = _b === void 0 ? "Link files" : _b, _c = _a.showConnectTooltip, showConnectTooltip = _c === void 0 ? false : _c, showUnlinkIconInsteadOfX = _a.showUnlinkIconInsteadOfX;
    var _d = useAiTextAreaContext(), fileReferences = _d.fileReferences, setFileReferences = _d.setFileReferences, existingLinkedFiles = _d.existingLinkedFiles;
    var connectFileToDocumentMutation = useConnectFileToDocument();
    var disconnectFileFromDocumentMutation = useDisconnectFileFromDocument();
    var isAtFileLimit = fileReferences.length >= FILE_REFERENCE_LIMIT;
    var handleConnectFile = function (file) {
        connectFileToDocumentMutation.mutate(file);
        setFileReferences(fileReferences.filter(function (f) { return f.path !== file.path; }));
    };
    var handleDisconnectFile = function (file) {
        disconnectFileFromDocumentMutation.mutate(file);
    };
    var totalItemCount = existingLinkedFiles.length + fileReferences.length;
    return (_jsxs("div", { className: "flex flex-row flex-wrap items-center gap-1 pt-1", children: [!disableAdd && (_jsx(Dropdown, { align: "start", className: "w-[32rem] p-0", trigger: _jsxs(Button, { size: fileReferences.length === 0 ? "xs" : "icon-xs", variant: "outline", children: [_jsx(PlusIcon, { className: "size-4" }), totalItemCount === 0 && _jsx("span", { children: addButtonText })] }), children: _jsx(FileReferenceDropdownContent, {}) })), existingLinkedFiles === null || existingLinkedFiles === void 0 ? void 0 : existingLinkedFiles.map(function (file) { return (_jsx(FileReferencePill, { path: file.path, repoFullName: file.repoFullName, fileUrl: file.fileUrl, isExisting: true, showConnectTooltip: showConnectTooltip, showUnlinkIconInsteadOfX: showUnlinkIconInsteadOfX, onDisconnect: function () { return handleDisconnectFile({ path: file.path }); } }, file.path)); }), fileReferences === null || fileReferences === void 0 ? void 0 : fileReferences.map(function (file) { return (_jsx(FileReferencePill, { path: file.path, repoFullName: file.repoFullName, fileUrl: file.fileUrl, onRemove: function () { return setFileReferences(fileReferences.filter(function (f) { return f.path !== file.path; })); }, showConnectTooltip: showConnectTooltip, showUnlinkIconInsteadOfX: showUnlinkIconInsteadOfX, onConnect: function () { return handleConnectFile(file); } }, file.path)); }), isAtFileLimit && _jsx("span", { className: "text-xs text-red-600", children: "Maximum of 8 files reached" })] }));
};
var FileReferenceDropdownContent = function () {
    var _a = useState(""), query = _a[0], setQuery = _a[1];
    var fileSearchRef = useRef(null);
    return (_jsxs("div", { className: "w-[32rem]", children: [_jsx("div", { className: "border-b border-border bg-card/50 px-1 py-1", children: _jsx(Input, { placeholder: "Search files", className: "border-none bg-transparent", prefix: _jsx(SearchIcon, { className: "mr-2 size-4" }), value: query, onChange: function (e) { return setQuery(e.target.value); }, onKeyDown: function (e) {
                        var _a;
                        (_a = fileSearchRef.current) === null || _a === void 0 ? void 0 : _a.onKeyDown({ event: e, hide: function () { } });
                    }, autoFocus: true }) }), _jsx(FileSearch, { ref: fileSearchRef, query: query })] }));
};
