var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { AccessStrategies, handleSupabaseError } from "@cloudy/utils/common";
import { useMutation, useQuery } from "@tanstack/react-query";
import { produce } from "immer";
import { queryClient } from "src/api/queryClient";
import { projectQueryKeys, thoughtQueryKeys } from "src/api/queryKeys";
import { supabase } from "src/clients/supabase";
import { useUser } from "src/stores/user";
import { useWorkspace } from "src/stores/workspace";
import { useProject } from "src/views/projects/ProjectContext";
import { deepEqual, keyBy } from "./object";
export var assignDocumentToFolder = function (docId, folderId) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, _b;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _b = (_a = supabase
                    .from("thoughts"))
                    .update;
                _c = { folder_id: folderId };
                return [4 /*yield*/, getFolderChildrenCount(folderId)];
            case 1: return [4 /*yield*/, _b.apply(_a, [(_c.index = _d.sent(), _c)])
                    .eq("id", docId)];
            case 2:
                _d.sent();
                return [2 /*return*/];
        }
    });
}); };
export var getRootFolder = function (workspaceId, projectId) { return __awaiter(void 0, void 0, void 0, function () {
    var rootFolderQuery, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                rootFolderQuery = supabase.from("folders").select("id").eq("is_root", true);
                if (projectId) {
                    rootFolderQuery = rootFolderQuery.eq("project_id", projectId);
                }
                else {
                    rootFolderQuery = rootFolderQuery.eq("workspace_id", workspaceId).is("project_id", null);
                }
                _a = handleSupabaseError;
                return [4 /*yield*/, rootFolderQuery.maybeSingle()];
            case 1: return [2 /*return*/, _a.apply(void 0, [_b.sent()])];
        }
    });
}); };
export var createRootFolder = function (workspaceId, projectId) { return __awaiter(void 0, void 0, void 0, function () {
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = handleSupabaseError;
                return [4 /*yield*/, supabase
                        .from("folders")
                        .insert({ project_id: projectId !== null && projectId !== void 0 ? projectId : null, name: "<ROOT>", is_root: true, workspace_id: workspaceId })
                        .select()
                        .single()];
            case 1: return [2 /*return*/, _a.apply(void 0, [_b.sent()])];
        }
    });
}); };
export var getFolderChildrenCount = function (folderId) { return __awaiter(void 0, void 0, void 0, function () {
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = handleSupabaseError;
                return [4 /*yield*/, supabase.rpc("get_folder_children_count", { p_folder_id: folderId })];
            case 1: return [2 /*return*/, _a.apply(void 0, [_b.sent()])];
        }
    });
}); };
export var useLibraryItems = function () {
    var workspace = useWorkspace();
    var project = useProject();
    var user = useUser();
    return useQuery({
        queryKey: projectQueryKeys.library(workspace.id, project === null || project === void 0 ? void 0 : project.id),
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var recentDocsQuery, docsQuery, foldersQuery, recentDocs, _a, docs, _b, rootFolder, folders, _c, getFolderChildren, rootItems, sharedDocs, privateDocs, workspaceDocs, items;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        recentDocsQuery = supabase
                            .from("thoughts")
                            .select("id, title, access_strategy, author_id, updated_at")
                            .order("updated_at", { ascending: false })
                            .eq("workspace_id", workspace.id)
                            .is("folder_id", null);
                        docsQuery = supabase
                            .from("thoughts")
                            .select("id, title, index, folder:folders!inner(id, is_root), access_strategy")
                            .eq("workspace_id", workspace.id)
                            .not("folder_id", "is", null);
                        foldersQuery = supabase
                            .from("folders")
                            .select("id, name, parent_id, is_root, index")
                            .eq("workspace_id", workspace.id)
                            .eq("is_root", false);
                        if (project) {
                            recentDocsQuery = recentDocsQuery.eq("project_id", project.id);
                            docsQuery = docsQuery.eq("project_id", project.id);
                            foldersQuery = foldersQuery.eq("project_id", project.id);
                        }
                        else {
                            recentDocsQuery = recentDocsQuery.is("project_id", null);
                            docsQuery = docsQuery.is("project_id", null);
                            foldersQuery = foldersQuery.is("project_id", null);
                        }
                        _a = handleSupabaseError;
                        return [4 /*yield*/, recentDocsQuery];
                    case 1:
                        recentDocs = _a.apply(void 0, [_d.sent()]);
                        _b = handleSupabaseError;
                        return [4 /*yield*/, docsQuery];
                    case 2:
                        docs = _b.apply(void 0, [_d.sent()]);
                        return [4 /*yield*/, getRootFolder(workspace.id, project === null || project === void 0 ? void 0 : project.id)];
                    case 3:
                        rootFolder = _d.sent();
                        _c = handleSupabaseError;
                        return [4 /*yield*/, foldersQuery];
                    case 4:
                        folders = _c.apply(void 0, [_d.sent()]);
                        getFolderChildren = function (folderId, depth) {
                            var parentId = folderId === rootFolder.id ? "<ROOT>" : folderId;
                            var childDocs = docs
                                .filter(function (doc) { var _a; return ((_a = doc.folder) === null || _a === void 0 ? void 0 : _a.id) === folderId; })
                                .map(function (doc) {
                                return ({
                                    id: doc.id,
                                    type: "document",
                                    name: doc.title,
                                    depth: depth,
                                    index: doc.index,
                                    parentId: parentId,
                                    accessStrategy: doc.access_strategy,
                                });
                            });
                            var childFolders = folders
                                .filter(function (folder) { return folder.parent_id === folderId; })
                                .map(function (folder) {
                                return ({
                                    id: folder.id,
                                    type: "folder",
                                    name: folder.name,
                                    depth: depth,
                                    index: folder.index,
                                    parentId: parentId,
                                });
                            });
                            var combinedItems = __spreadArray(__spreadArray([], childDocs, true), childFolders, true);
                            var sortedItemsAtThisDepth = combinedItems.sort(function (a, b) { return a.index - b.index; });
                            // Get children for each folder and insert them after their parent folder
                            var result = [];
                            for (var _i = 0, sortedItemsAtThisDepth_1 = sortedItemsAtThisDepth; _i < sortedItemsAtThisDepth_1.length; _i++) {
                                var item = sortedItemsAtThisDepth_1[_i];
                                result.push(item);
                                if (item.type === "folder") {
                                    var folderChildren = getFolderChildren(item.id, depth + 1);
                                    result.push.apply(result, folderChildren);
                                }
                            }
                            return result;
                        };
                        rootItems = rootFolder ? getFolderChildren(rootFolder.id, 0) : [];
                        sharedDocs = recentDocs
                            .filter(function (doc) { return doc.access_strategy === AccessStrategies.PRIVATE && doc.author_id !== user.id; })
                            .map(function (doc) { return ({
                            id: doc.id,
                            type: "document",
                            name: doc.title,
                            depth: 0,
                            parentId: null,
                            category: "shared",
                            accessStrategy: doc.access_strategy,
                        }); });
                        privateDocs = recentDocs
                            .filter(function (doc) { return doc.access_strategy === AccessStrategies.PRIVATE && doc.author_id === user.id; })
                            .map(function (doc) { return ({
                            id: doc.id,
                            type: "document",
                            name: doc.title,
                            depth: 0,
                            parentId: null,
                            category: "private",
                            accessStrategy: doc.access_strategy,
                        }); });
                        workspaceDocs = recentDocs
                            .filter(function (doc) { return doc.access_strategy !== AccessStrategies.PRIVATE; })
                            .map(function (doc) { return ({
                            id: doc.id,
                            type: "document",
                            name: doc.title,
                            depth: 0,
                            parentId: null,
                            category: "workspace",
                            accessStrategy: doc.access_strategy,
                        }); });
                        items = __spreadArray(__spreadArray(__spreadArray(__spreadArray([], rootItems, true), sharedDocs, true), privateDocs, true), workspaceDocs, true);
                        return [2 /*return*/, items];
                }
            });
        }); },
        initialData: [],
    });
};
export var useSetLibraryItems = function () {
    var workspace = useWorkspace();
    var project = useProject();
    return useMutation({
        mutationFn: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
            var prevItems, newItems, rootFolder, deRootedPrevItems, deRootedNewItems, prevItemsById, itemsToUpdate, docsToUpdate, foldersToUpdate, _a, _b, _c, _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        prevItems = payload.prevItems, newItems = payload.newItems;
                        return [4 /*yield*/, getRootFolder(workspace.id, project === null || project === void 0 ? void 0 : project.id)];
                    case 1:
                        rootFolder = _f.sent();
                        if (!rootFolder) {
                            return [2 /*return*/];
                        }
                        deRootedPrevItems = prevItems.map(function (item) {
                            return produce(item, function (draft) {
                                if (draft.parentId === "<ROOT>") {
                                    draft.parentId = rootFolder.id;
                                }
                            });
                        });
                        deRootedNewItems = newItems.map(function (item) {
                            return produce(item, function (draft) {
                                if (draft.parentId === "<ROOT>") {
                                    draft.parentId = rootFolder.id;
                                }
                            });
                        });
                        prevItemsById = keyBy(deRootedPrevItems, "id");
                        itemsToUpdate = deRootedNewItems.filter(function (item) {
                            var prevItem = prevItemsById[item.id];
                            if (deepEqual(prevItem, item))
                                return false;
                            return true;
                        });
                        docsToUpdate = itemsToUpdate.filter(function (item) { return item.type === "document"; });
                        foldersToUpdate = itemsToUpdate.filter(function (item) { return item.type === "folder"; });
                        _b = (_a = Promise).all;
                        _c = handleSupabaseError;
                        return [4 /*yield*/, supabase.from("thoughts").upsert(docsToUpdate.map(function (doc) { return ({
                                id: doc.id,
                                folder_id: doc.parentId,
                                index: doc.index,
                                workspace_id: workspace.id,
                                project_id: project === null || project === void 0 ? void 0 : project.id,
                            }); }))];
                    case 2:
                        _d = [
                            _c.apply(void 0, [_f.sent()])
                        ];
                        _e = handleSupabaseError;
                        return [4 /*yield*/, supabase.from("folders").upsert(foldersToUpdate.map(function (folder) { return ({
                                id: folder.id,
                                index: folder.index,
                                parent_id: folder.parentId,
                                project_id: project === null || project === void 0 ? void 0 : project.id,
                                workspace_id: workspace.id,
                            }); }))];
                    case 3: return [4 /*yield*/, _b.apply(_a, [_d.concat([
                                _e.apply(void 0, [_f.sent()])
                            ])])];
                    case 4:
                        _f.sent();
                        return [2 /*return*/];
                }
            });
        }); },
        onMutate: function (_a) {
            var newItems = _a.newItems;
            queryClient.setQueryData(projectQueryKeys.library(workspace.id, project === null || project === void 0 ? void 0 : project.id), newItems);
        },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: projectQueryKeys.library(workspace.id, project === null || project === void 0 ? void 0 : project.id) });
        },
    });
};
export var useMakeInitialLibrary = function () {
    var workspace = useWorkspace();
    var project = useProject();
    return useMutation({
        mutationFn: function (initialDocId) { return __awaiter(void 0, void 0, void 0, function () {
            var rootFolder;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getRootFolder(workspace.id, project === null || project === void 0 ? void 0 : project.id)];
                    case 1:
                        rootFolder = _a.sent();
                        if (!!rootFolder) return [3 /*break*/, 3];
                        return [4 /*yield*/, createRootFolder(workspace.id, project === null || project === void 0 ? void 0 : project.id)];
                    case 2:
                        rootFolder = _a.sent();
                        _a.label = 3;
                    case 3:
                        if (!initialDocId) return [3 /*break*/, 5];
                        return [4 /*yield*/, assignDocumentToFolder(initialDocId, rootFolder.id)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        }); },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: projectQueryKeys.library(workspace.id, project === null || project === void 0 ? void 0 : project.id) });
        },
    });
};
export var useMoveOutOfLibrary = function () {
    var workspace = useWorkspace();
    var project = useProject();
    return useMutation({
        mutationFn: function (docId) { return __awaiter(void 0, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase.from("thoughts").update({ folder_id: null }).eq("id", docId).select()];
                    case 1:
                        _a.apply(void 0, [_b.sent()]);
                        return [2 /*return*/];
                }
            });
        }); },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: projectQueryKeys.library(workspace.id, project === null || project === void 0 ? void 0 : project.id) });
        },
    });
};
export var useCreateFolder = function () {
    var workspace = useWorkspace();
    var project = useProject();
    return useMutation({
        mutationFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var rootFolder, _a, _b, _c;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0: return [4 /*yield*/, getRootFolder(workspace.id, project === null || project === void 0 ? void 0 : project.id)];
                    case 1:
                        rootFolder = _e.sent();
                        if (!rootFolder)
                            return [2 /*return*/];
                        _a = handleSupabaseError;
                        _c = (_b = supabase.from("folders")).insert;
                        _d = {
                            project_id: project === null || project === void 0 ? void 0 : project.id,
                            name: "New Folder",
                            is_root: false,
                            parent_id: rootFolder.id
                        };
                        return [4 /*yield*/, getFolderChildrenCount(rootFolder.id)];
                    case 2: return [4 /*yield*/, _c.apply(_b, [(_d.index = _e.sent(),
                                _d.workspace_id = workspace.id,
                                _d)])];
                    case 3:
                        _a.apply(void 0, [_e.sent()]);
                        return [2 /*return*/];
                }
            });
        }); },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: projectQueryKeys.library(workspace.id, project === null || project === void 0 ? void 0 : project.id) });
        },
    });
};
export var syncItemIndices = function (items) {
    return produce(items, function (draft) {
        // Create a map to store indices for each folder level
        var folderIndices = new Map();
        // Helper to get the current index for a parent and increment it
        var getNextIndex = function (parentId) {
            var _a;
            var key = parentId !== null && parentId !== void 0 ? parentId : "<ROOT>";
            var currentIndex = (_a = folderIndices.get(key)) !== null && _a !== void 0 ? _a : 0;
            folderIndices.set(key, currentIndex + 1);
            return currentIndex;
        };
        // Process items in order, maintaining hierarchy
        for (var i = 0; i < draft.length; i++) {
            var item = draft[i];
            var parentId = item.parentId;
            // Set the index for this item
            item.index = getNextIndex(parentId);
            // If this is a folder, ensure all its immediate children come next
            if (item.type === "folder") {
                // Find all immediate children of this folder
                var insertPosition = i + 1;
                var j = insertPosition;
                // Collect and move all immediate children right after their parent
                while (j < draft.length) {
                    if (draft[j].parentId === item.id) {
                        // If not already in the right position, move it
                        if (j !== insertPosition) {
                            var child = draft.splice(j, 1)[0];
                            draft.splice(insertPosition, 0, child);
                        }
                        insertPosition++;
                    }
                    j++;
                }
            }
        }
    });
};
export var useRenameItem = function () {
    var workspace = useWorkspace();
    var project = useProject();
    return useMutation({
        mutationFn: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
            var id, name, type, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        id = payload.id, name = payload.name, type = payload.type;
                        if (!(type === "document")) return [3 /*break*/, 2];
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase.from("thoughts").update({ title: name }).eq("id", id)];
                    case 1:
                        _a.apply(void 0, [_c.sent()]);
                        return [3 /*break*/, 4];
                    case 2:
                        _b = handleSupabaseError;
                        return [4 /*yield*/, supabase.from("folders").update({ name: name }).eq("id", id)];
                    case 3:
                        _b.apply(void 0, [_c.sent()]);
                        _c.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); },
        onSuccess: function (_, payload) {
            queryClient.invalidateQueries({ queryKey: projectQueryKeys.library(workspace.id, project === null || project === void 0 ? void 0 : project.id) });
            if (payload.type === "document") {
                queryClient.invalidateQueries({ queryKey: thoughtQueryKeys.thoughtDetail(payload.id) });
            }
        },
    });
};
export var useDeleteItem = function () {
    var workspace = useWorkspace();
    var project = useProject();
    return useMutation({
        mutationFn: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
            var id, type, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        id = payload.id, type = payload.type;
                        if (!(type === "document")) return [3 /*break*/, 2];
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase.from("thoughts").delete().eq("id", id)];
                    case 1:
                        _a.apply(void 0, [_c.sent()]);
                        return [3 /*break*/, 4];
                    case 2:
                        _b = handleSupabaseError;
                        return [4 /*yield*/, supabase.from("folders").delete().eq("id", id)];
                    case 3:
                        _b.apply(void 0, [_c.sent()]);
                        _c.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); },
        onSuccess: function (_, payload) {
            queryClient.invalidateQueries({ queryKey: projectQueryKeys.library(workspace.id, project === null || project === void 0 ? void 0 : project.id) });
            if (payload.type === "document") {
                queryClient.invalidateQueries({ queryKey: thoughtQueryKeys.thoughtDetail(payload.id) });
            }
        },
    });
};
