var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { handleSupabaseError } from "@cloudy/utils/common";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { queryClient } from "src/api/queryClient";
import { thoughtQueryKeys } from "src/api/queryKeys";
import { supabase } from "src/clients/supabase";
var useRelatedThoughts = function (thoughtId) {
    useEffect(function () {
        if (!thoughtId) {
            return;
        }
        var thoughtLinksChannel = supabase
            .channel("thoughtLinks")
            .on("postgres_changes", {
            event: "*",
            schema: "public",
            table: "thought_links",
            filter: "linked_from=eq.".concat(thoughtId),
        }, function () {
            queryClient.invalidateQueries({
                queryKey: thoughtQueryKeys.relatedThoughts(thoughtId),
            });
        })
            .subscribe();
        return function () {
            thoughtLinksChannel.unsubscribe();
        };
    }, [thoughtId]);
    return useQuery({
        queryKey: thoughtQueryKeys.relatedThoughts(thoughtId),
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var linkedFromData, _a, linkedToData, _b, linkedData;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!thoughtId) {
                            return [2 /*return*/, {
                                    linkedData: [],
                                    relatedData: [],
                                }];
                        }
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase
                                .from("thought_links")
                                .select("\n                        id,\n                        thought:thoughts!linked_to (\n                            id,\n                            title,\n                            content_md,\n                            content_plaintext,\n                            created_at,\n                            updated_at,\n                            collection_thoughts (\n                                collections (\n                                    id,\n                                    title\n                                )\n                            )\n                        )\n                    ")
                                .eq("linked_from", thoughtId)];
                    case 1:
                        linkedFromData = _a.apply(void 0, [_c.sent()]);
                        _b = handleSupabaseError;
                        return [4 /*yield*/, supabase
                                .from("thought_links")
                                .select("\n                        id,\n                        thought:thoughts!linked_from (\n                            id,\n                            title,\n                            content_md,\n                            content_plaintext,\n                            created_at,\n                            updated_at,\n                            collection_thoughts (\n                                collections (\n                                    id,\n                                    title\n                                )\n                            )\n                        )\n                    ")
                                .eq("linked_to", thoughtId)];
                    case 2:
                        linkedToData = _b.apply(void 0, [_c.sent()]);
                        linkedData = __spreadArray(__spreadArray([], linkedFromData, true), linkedToData, true).flatMap(function (d) { return d.thought; })
                            .map(function (thought) {
                            var _a;
                            return (__assign(__assign({}, thought), { isLinkedManually: true, collections: (_a = thought.collection_thoughts) === null || _a === void 0 ? void 0 : _a.map(function (c) {
                                    var _a, _b, _c;
                                    return ({
                                        id: (_a = c.collections) === null || _a === void 0 ? void 0 : _a.id,
                                        title: (_c = (_b = c.collections) === null || _b === void 0 ? void 0 : _b.title) !== null && _c !== void 0 ? _c : null,
                                    });
                                }).filter(function (c) { return c.id; }) }));
                        })
                            .sort(function (a, b) {
                            return b.updated_at.localeCompare(a.updated_at);
                        });
                        return [2 /*return*/, {
                                linkedData: linkedData,
                            }];
                }
            });
        }); },
        throwOnError: true,
    });
};
export var RelatedNotes = function (_a) {
    var _b;
    var thoughtId = _a.thoughtId;
    var _c = useRelatedThoughts(thoughtId), relatedThoughts = _c.data, isLoading = _c.isLoading;
    var manuallyLinked = (_b = relatedThoughts === null || relatedThoughts === void 0 ? void 0 : relatedThoughts.linkedData) !== null && _b !== void 0 ? _b : [];
    // TODO: Reimplement
    // return (
    // 	<div className="flex w-full flex-col gap-2 rounded-md border border-border p-4">
    // 		{isLoading ? (
    // 			<div className="flex w-full justify-center py-4">
    // 				<LoadingSpinner size="sm" />
    // 			</div>
    // 		) : (
    // 			<RelatedNotesSection
    // 				title="Linked Docs"
    // 				thoughts={manuallyLinked}
    // 				icon={<LinkIcon className="mr-1 size-4 text-secondary" />}
    // 				emptyMessage="No linked docs"
    // 			/>
    // 		)}
    // 	</div>
    // );
    return null;
};
// const RelatedNotesSection = ({
// 	title,
// 	thoughts,
// 	icon,
// 	emptyMessage,
// 	isLoading,
// }: {
// 	title: string;
// 	thoughts:
// 		| NonNullable<ReturnType<typeof useRelatedThoughts>["data"]>["linkedData"]
// 		| NonNullable<ReturnType<typeof useRelatedThoughts>["data"]>["relatedData"];
// 	icon: React.ReactNode;
// 	emptyMessage: string;
// 	isLoading?: boolean;
// }) => {
// 	const [showAll, setShowAll] = useState(false);
// 	const displayedThoughts = showAll ? thoughts : thoughts?.slice(0, 4);
// 	return (
// 		<div className="mb-4">
// 			<h5 className="mb-2 flex items-center text-sm font-medium text-secondary">
// 				{icon}
// 				{title}
// 				{isLoading && <LoadingSpinner size="xs" className="ml-2" />}
// 			</h5>
// 			<div className="flex w-full flex-col">
// 				{thoughts && thoughts.length > 0 ? (
// 					<>
// 						{displayedThoughts?.map(thought => (
// 							<ThoughtCard key={thought.id} thought={thought} variant="compact" hoursOnlyForUpdatedAt={false} />
// 						))}
// 						{thoughts.length > 4 ? (
// 							!showAll ? (
// 								<Button
// 									variant="ghost"
// 									size="sm"
// 									className="mt-2 self-start text-secondary"
// 									onClick={() => setShowAll(true)}>
// 									<ChevronDownIcon className="size-4" />
// 									<span>Show more</span>
// 								</Button>
// 							) : (
// 								<Button
// 									variant="ghost"
// 									size="sm"
// 									className="mt-2 self-start text-secondary"
// 									onClick={() => setShowAll(false)}>
// 									<ChevronUpIcon className="size-4" />
// 									<span>Show less</span>
// 								</Button>
// 							)
// 						) : null}
// 					</>
// 				) : (
// 					<div className="text-sm text-tertiary">{emptyMessage}</div>
// 				)}
// 			</div>
// 		</div>
// 	);
// };
