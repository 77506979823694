var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var collectionQueryKeys = {
    collectionDetail: function (collectionId) { return ["collection", collectionId]; },
    collectionDetailThoughts: function (collectionId) {
        return collectionId ? ["collection", "thoughts", collectionId] : ["collection", "thoughts"];
    },
    collectionDetailSubCollections: function (collectionId) {
        return collectionId ? ["collection", "subCollections", collectionId] : ["collection", "subCollections"];
    },
    collectionDetailParents: function (collectionId) {
        return collectionId ? ["collection", "parents", collectionId] : ["collection", "parents"];
    },
    workspaceCollections: function (workspaceId) { return [workspaceId, "collections"]; },
};
export var thoughtQueryKeys = {
    thoughtDetail: function (thoughtId) { return ["thought", thoughtId !== null && thoughtId !== void 0 ? thoughtId : "new"]; },
    workspaceSidebarLatestThoughts: function (workspaceId) { return [workspaceId, "latestThoughts"]; },
    workspaceHomeThoughts: function (workspaceId) { return [workspaceId, "homeThoughts"]; },
    relatedThoughts: function (thoughtId) { return ["relatedThoughts", thoughtId]; },
    sharedWith: function (thoughtId) { return ["thought", thoughtId, "sharedWith"]; },
    existingLinkedFiles: function (thoughtId) { return ["thought", thoughtId, "existingLinkedFiles"]; },
    threadsForDoc: function (docId) { return ["threadsForDoc", docId]; },
    defaultThreadId: function (docId) { return ["defaultThreadId", docId]; },
    recentChanges: function (thoughtId) { return ["recentChanges", thoughtId]; },
};
export var topicQueryKeys = {
    topics: function () { return ["topics"]; },
    topicMatches: function (topicId) { return ["topic", "matches", topicId]; },
};
export var userQueryKeys = {
    userRecord: function (userId) { return (userId ? ["userRecord", userId] : ["userRecord"]); },
    userProfile: function (userId) { return (userId ? ["userProfile", userId] : ["userProfile"]); },
};
export var paymentsQueryKeys = {
    customerStatus: function (wsSlug) {
        return wsSlug ? ["payments", "customers", "status", wsSlug] : ["payments", "customers", "status"];
    },
};
export var commentThreadQueryKeys = {
    comment: function (commentId) { return ["comment", commentId, "comment"]; },
    threadComments: function (commentId) { return ["comment", commentId, "threadComments"]; },
    temporaryComment: function (commentId) { return ["comment", commentId, "temporaryComment"]; },
};
export var chatThreadQueryKeys = {
    thread: function (threadId) { return ["chatThread", threadId]; },
};
export var workspaceQueryKeys = {
    allProjects: function (workspaceId) { return ["workspace", workspaceId, "projects"]; },
    workspaceGithubInstallations: function (workspaceId) { return ["workspace", workspaceId, "github", "installations"]; },
};
export var projectQueryKeys = {
    library: function (workspaceId, projectId) { return ["workspace", workspaceId, "project", projectId, "library"]; },
    repos: function (projectId) { return ["project", projectId, "repos"]; },
};
export var thoughtKeys = {
    all: ["thoughts"],
    lists: function () { return __spreadArray(__spreadArray([], thoughtKeys.all, true), ["list"], false); },
    list: function (filters) { return __spreadArray(__spreadArray([], thoughtKeys.lists(), true), [{ filters: filters }], false); },
    details: function () { return __spreadArray(__spreadArray([], thoughtKeys.all, true), ["detail"], false); },
    detail: function (id) { return __spreadArray(__spreadArray([], thoughtKeys.details(), true), [id], false); },
};
