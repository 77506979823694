var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ChatRole, handleSupabaseError } from "@cloudy/utils/common";
import { useMutation } from "@tanstack/react-query";
import { SparklesIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { supabase } from "src/clients/supabase";
import { Button } from "src/components/Button";
import { Dialog, DialogContent } from "src/components/Dialog";
import { useUser } from "src/stores/user";
import { useWorkspace } from "src/stores/workspace";
import { makeProjectDocUrl } from "src/utils/thought";
import { AiTextArea } from "src/views/aiTextArea/AiTextArea";
import { useProject } from "../projects/ProjectContext";
var useCreateNoteWithGeneration = function () {
    var workspace = useWorkspace();
    var project = useProject();
    var user = useUser();
    return useMutation({
        mutationFn: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
            var doc, _a, _b, thread, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (!project) {
                            throw new Error("Project not loaded");
                        }
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase
                                .from("thoughts")
                                .insert({
                                generation_prompt: payload.prompt,
                                workspace_id: workspace.id,
                                project_id: project.id,
                                author_id: user.id,
                            })
                                .select()
                                .single()];
                    case 1:
                        doc = _a.apply(void 0, [_e.sent()]);
                        _b = handleSupabaseError;
                        return [4 /*yield*/, supabase.from("document_repo_links").insert(payload.references.map(function (ref) { return ({
                                document_id: doc.id,
                                path: ref.path,
                                repo_connection_id: ref.repoConnectionId,
                                type: ref.type,
                            }); }))];
                    case 2:
                        _b.apply(void 0, [_e.sent()]);
                        _c = handleSupabaseError;
                        return [4 /*yield*/, supabase
                                .from("chat_threads")
                                .insert({
                                document_id: doc.id,
                                workspace_id: workspace.id,
                                is_default: true,
                            })
                                .select("id")
                                .single()];
                    case 3:
                        thread = _c.apply(void 0, [_e.sent()]);
                        _d = handleSupabaseError;
                        return [4 /*yield*/, supabase.from("chat_messages").insert([
                                {
                                    thread_id: thread.id,
                                    role: ChatRole.User,
                                    user_id: user.id,
                                    content: payload.prompt,
                                },
                                {
                                    thread_id: thread.id,
                                    role: ChatRole.Assistant,
                                    content: "I've created your document. You can edit it now.",
                                    completed_at: new Date().toISOString(),
                                },
                            ])];
                    case 4:
                        _d.apply(void 0, [_e.sent()]);
                        return [2 /*return*/, doc];
                }
            });
        }); },
    });
};
export var GenerateDoc = function () {
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var createNoteWithGeneration = useCreateNoteWithGeneration();
    var navigate = useNavigate();
    var location = useLocation();
    var workspace = useWorkspace();
    var project = useProject();
    // Add effect to close dialog on location change
    useEffect(function () {
        setIsOpen(false);
    }, [location]);
    var handleSubmit = function (prompt, references) { return __awaiter(void 0, void 0, void 0, function () {
        var thought;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!project) {
                        throw new Error("Project not loaded");
                    }
                    return [4 /*yield*/, createNoteWithGeneration.mutateAsync({
                            prompt: prompt,
                            references: references,
                        })];
                case 1:
                    thought = _a.sent();
                    setIsOpen(false);
                    navigate(makeProjectDocUrl(workspace.slug, project.slug, thought.id));
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: function () { return setIsOpen(true); }, size: "icon", className: "shrink-0 text-accent", variant: "outline", children: _jsx(SparklesIcon, { className: "size-4" }) }), _jsx(Dialog, { open: isOpen, onOpenChange: setIsOpen, children: _jsxs(DialogContent, { className: "max-w-[calc(100vw-2rem)] gap-0 overflow-hidden p-0 md:max-w-lg", children: [_jsx("div", { className: "flex w-full items-center justify-between gap-2 border-b border-border px-4 py-2 font-medium", children: _jsxs("div", { className: "flex items-center gap-1 text-sm", children: [_jsx(SparklesIcon, { className: "size-4 text-accent" }), _jsx("span", { children: "Generate Document" })] }) }), _jsx("div", { className: "p-4", children: _jsx(AiTextArea, { onSubmit: handleSubmit, onCancel: function () { return setIsOpen(false); }, placeholder: "Describe the document you want to generate", submitButtonText: "Generate" }) })] }) })] }));
};
