var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// Inspired/plagiarized from
// https://github.com/ueberdosis/tiptap/issues/333#issuecomment-1056434177
import TipTapImage from "@tiptap/extension-image";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import { MoveDiagonal2Icon } from "lucide-react";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
var useEvent = function (handler) {
    var handlerRef = useRef(null);
    useLayoutEffect(function () {
        handlerRef.current = handler;
    }, [handler]);
    return useCallback(function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (handlerRef.current === null) {
            throw new Error("Handler is not assigned");
        }
        return handlerRef.current.apply(handlerRef, args);
    }, []);
};
var MIN_WIDTH = 60;
var ResizableImageTemplate = function (_a) {
    var node = _a.node, updateAttributes = _a.updateAttributes;
    var containerRef = useRef(null);
    var imgRef = useRef(null);
    var _b = useState(false), editing = _b[0], setEditing = _b[1];
    var _c = useState(), resizingStyle = _c[0], setResizingStyle = _c[1];
    // Lots of work to handle "not" div click events.
    useEffect(function () {
        var handleClickOutside = function (event) {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setEditing(false);
            }
        };
        // Add click event listener and remove on cleanup
        document.addEventListener("click", handleClickOutside);
        return function () {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [editing]);
    var handleMouseDown = useEvent(function (event) {
        if (!imgRef.current)
            return;
        event.preventDefault();
        var direction = event.currentTarget.dataset.direction || "--";
        var initialXPosition = event.clientX;
        var currentWidth = imgRef.current.width;
        var newWidth = currentWidth;
        var transform = direction[1] === "w" ? -1 : 1;
        var removeListeners = function () {
            window.removeEventListener("mousemove", mouseMoveHandler);
            window.removeEventListener("mouseup", removeListeners);
            updateAttributes({ width: newWidth });
            setResizingStyle(undefined);
        };
        var mouseMoveHandler = function (event) {
            newWidth = Math.max(currentWidth + transform * (event.clientX - initialXPosition), MIN_WIDTH);
            setResizingStyle({ width: newWidth });
            // If mouse is up, remove event listeners
            if (!event.buttons)
                removeListeners();
        };
        window.addEventListener("mousemove", mouseMoveHandler);
        window.addEventListener("mouseup", removeListeners);
    });
    var dragCornerButton = function (direction) { return (_jsx("div", { role: "button", tabIndex: 0, onMouseDown: handleMouseDown, "data-direction": direction, className: "size-[20px] translate-x-[7px] translate-y-[7px] cursor-grab rounded-sm border-2 border-accent bg-background text-accent hover:bg-accent hover:text-background active:cursor-grabbing active:bg-accent/60 active:text-background", style: __assign(__assign({ position: "absolute" }, { n: { top: 0 }, s: { bottom: 0 } }[direction[0]]), { w: { left: 0 }, e: { right: 0 } }[direction[1]]), children: _jsx(MoveDiagonal2Icon, { className: "size-[16px]" }) })); };
    return (_jsxs(NodeViewWrapper, { ref: containerRef, as: "div", draggable: true, "data-drag-handle": true, onClick: function () { return setEditing(true); }, onBlur: function () { return setEditing(false); }, style: {
            position: "relative",
            display: "inline-block",
            // Weird! Basically tiptap/prose wraps this in a span and the line height causes an annoying buffer.
            lineHeight: "0px",
        }, children: [_jsx("img", __assign({}, node.attrs, { ref: imgRef, style: __assign(__assign({}, resizingStyle), { cursor: "default" }) })), editing && (_jsxs(_Fragment, { children: [[
                        { left: 0, top: 0, height: "100%", width: "1px" },
                        { right: 0, top: 0, height: "100%", width: "1px" },
                        { top: 0, left: 0, width: "100%", height: "1px" },
                        { bottom: 0, left: 0, width: "100%", height: "1px" },
                    ].map(function (style, i) { return (_jsx("div", { className: "bg-accent/40", style: __assign({ position: "absolute" }, style) }, i)); }), dragCornerButton("se")] }))] }));
};
var ResizableImageExtension = TipTapImage.extend({
    addAttributes: function () {
        var _a;
        return __assign(__assign({}, (_a = this.parent) === null || _a === void 0 ? void 0 : _a.call(this)), { width: { renderHTML: function (_a) {
                    var width = _a.width;
                    return ({ width: width });
                } }, height: { renderHTML: function (_a) {
                    var height = _a.height;
                    return ({ height: height });
                } } });
    },
    addNodeView: function () {
        return ReactNodeViewRenderer(ResizableImageTemplate);
    },
}).configure({ inline: true });
export default ResizableImageExtension;
