export var ThoughtSignals;
(function (ThoughtSignals) {
    ThoughtSignals["AI_THOUGHT_UPDATE"] = "ai_thought_update";
    ThoughtSignals["AI_SUGGESTIONS"] = "ai_suggestions";
    ThoughtSignals["EMBEDDING_UPDATE"] = "embedding_update";
})(ThoughtSignals || (ThoughtSignals = {}));
export var AccessStrategies;
(function (AccessStrategies) {
    AccessStrategies["PUBLIC"] = "public";
    AccessStrategies["PRIVATE"] = "private";
    AccessStrategies["WORKSPACE"] = "workspace";
})(AccessStrategies || (AccessStrategies = {}));
