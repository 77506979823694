var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@tanstack/react-query";
import { FileIcon, SearchIcon, TriangleAlertIcon, XCircleIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import { supabase } from "src/clients/supabase";
import { Button } from "src/components/Button";
import { Dialog, DialogContent } from "src/components/Dialog";
import LoadingSpinner from "src/components/LoadingSpinner";
import { useWorkspace, useWorkspaceStore } from "src/stores/workspace";
import { cn } from "src/utils";
import { useBreakpoint } from "src/utils/tailwind";
import { makeDocUrl } from "src/utils/thought";
import { useSearchBarStore } from "./searchBarStore";
var useSearchThoughts = function (query) {
    var workspace = useWorkspace();
    return useQuery({
        enabled: !!query && query.length > 1,
        queryKey: ["thoughts", query],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a, data, error;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, supabase
                            .rpc("search_docs", {
                            search_query: query.replaceAll(" ", "+"),
                            p_workspace_id: workspace.id,
                        })
                            .order("doc_updated_at", { ascending: false })];
                    case 1:
                        _a = _b.sent(), data = _a.data, error = _a.error;
                        if (error)
                            throw error;
                        return [2 /*return*/, data.map(function (t) { return ({
                                id: t.doc_id,
                                title: t.doc_title,
                                content_md: t.doc_content_md,
                                content_plaintext: t.doc_content_plaintext,
                                updated_at: t.doc_updated_at,
                                project_id: t.doc_project_id,
                                project_name: t.project_name,
                                project_slug: t.project_slug,
                            }); })];
                }
            });
        }); },
        retry: false,
    });
};
var SearchBar = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose;
    var _b = useState(""), query = _b[0], setQuery = _b[1];
    var navigate = useNavigate();
    var workspace = useWorkspaceStore(function (_a) {
        var workspace = _a.workspace;
        return workspace;
    });
    var isMdBreakpoint = useBreakpoint("md");
    var listRef = useRef([]);
    var listContainerRef = useRef(null);
    var _c = useSearchThoughts(query), data = _c.data, isFetching = _c.isFetching, isError = _c.isError;
    var _d = useState(null), activeIndex = _d[0], setActiveIndex = _d[1];
    var handleInputChange = function (e) {
        setQuery(e.target.value);
    };
    var handleResultClick = function (docId, projectSlug) {
        if (!(workspace === null || workspace === void 0 ? void 0 : workspace.slug))
            return;
        navigate(makeDocUrl({ workspaceSlug: workspace.slug, docId: docId, projectSlug: projectSlug }));
        handleClose();
    };
    var handleInputKeyDown = function (e) {
        if (!(workspace === null || workspace === void 0 ? void 0 : workspace.slug)) {
            return;
        }
        if (e.key === "Enter") {
            e.preventDefault();
            if (activeIndex !== null && data) {
                var thought = data[activeIndex];
                if (thought) {
                    handleResultClick(thought.id, thought.project_slug);
                    onClose();
                }
            }
        }
        else if (e.key === "ArrowDown") {
            e.preventDefault();
            if (data) {
                setActiveIndex(function (prev) { return (prev === null || prev === data.length - 1 ? 0 : prev + 1); });
            }
        }
        else if (e.key === "ArrowUp") {
            e.preventDefault();
            if (data) {
                setActiveIndex(function (prev) { return (prev === null || prev === 0 ? data.length - 1 : prev - 1); });
            }
        }
    };
    var handleClose = function () {
        onClose();
        setQuery("");
        setActiveIndex(null);
    };
    var handleClearQuery = function () {
        setQuery("");
        setActiveIndex(null);
    };
    useEffect(function () {
        var _a;
        if (activeIndex !== null && listRef.current[activeIndex]) {
            (_a = listRef.current[activeIndex]) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
            });
        }
    }, [activeIndex]);
    var renderSearchResults = function () {
        if (!workspace) {
            return;
        }
        if (isError) {
            return (_jsxs("div", { className: "flex items-center gap-2 p-3 text-sm text-red-600", children: [_jsx(TriangleAlertIcon, { className: "size-4" }), _jsx("span", { children: "Something went wrong. Please try again." })] }));
        }
        if (!data || data.length === 0) {
            return query.length > 2 ? (_jsx("div", { className: "p-3 text-sm text-secondary", children: "No results found" })) : (_jsx("div", { className: "p-3 text-sm text-tertiary", children: "Continue typing to search" }));
        }
        return (_jsx("div", { ref: listContainerRef, className: "no-scrollbar max-h-[300px] w-full overflow-y-auto p-4", children: data.map(function (thought, index) { return (_jsxs("div", { ref: function (node) {
                    listRef.current[index] = node;
                }, onClick: function () { return handleResultClick(thought.id, thought.project_slug); }, className: cn("flex cursor-pointer items-center gap-2 rounded px-3 py-1 hover:bg-card", activeIndex === index && "bg-accent/10"), children: [_jsx(FileIcon, { className: "h-4 w-4 flex-shrink-0 text-secondary" }), _jsxs("div", { className: "flex min-w-0 flex-1 flex-col", children: [_jsx("span", { className: "text-xs text-secondary", children: thought.project_name ? "Project: ".concat(thought.project_name) : "Workspace: ".concat(workspace.name) }), _jsx("span", { className: cn("truncate text-sm", thought.title ? "font-medium" : "text-primary/80"), children: thought.title || thought.content_plaintext || thought.content_md })] })] }, thought.id)); }) }));
    };
    return (_jsx(Dialog, { open: isOpen, onOpenChange: function (open) {
            if (!open) {
                handleClose();
            }
        }, children: _jsx(DialogContent, { className: "max-w-[calc(100vw-2rem)] overflow-hidden p-0 md:max-w-lg", position: "top", offset: isMdBreakpoint ? "33%" : "8%", children: _jsxs("div", { className: "relative flex w-full flex-col items-center", children: [_jsxs("div", { className: "relative flex w-full items-center", children: [_jsx("div", { className: "absolute left-3 top-0 flex h-full items-center", children: _jsx(SearchIcon, { className: "size-5 text-secondary" }) }), _jsx(TextareaAutosize, { className: "min-h-10 w-full resize-none bg-white/30 py-4 pl-11 pr-16 font-sans outline-none hover:outline-none focus:outline-none dark:bg-card", placeholder: "Search for docs...", value: query, onChange: handleInputChange, onKeyDown: handleInputKeyDown }), _jsxs("div", { className: "absolute right-3 top-0 flex h-full items-center gap-2", children: [isFetching && _jsx(LoadingSpinner, { size: "xs" }), query.length > 0 && (_jsx(Button, { onClick: handleClearQuery, variant: "ghost", size: "icon-sm", className: "text-secondary", children: _jsx(XCircleIcon, { className: "size-4" }) }))] })] }), query.length > 0 && renderSearchResults()] }) }) }));
};
export var SearchBarControl = function () {
    var _a = useSearchBarStore(), isOpen = _a.isOpen, setIsOpen = _a.setIsOpen;
    return _jsx(SearchBar, { isOpen: isOpen, onClose: function () { return setIsOpen(false); } });
};
