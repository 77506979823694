var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { checkIfSlugIsAvailable, createNonConflictingSlug } from "@cloudy/utils/common";
import { useMutation } from "@tanstack/react-query";
import { ArrowLeftIcon } from "lucide-react";
import posthog from "posthog-js";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useMount } from "react-use";
import { supabase } from "src/clients/supabase";
import { Button } from "src/components/Button";
import LoadingSpinner from "src/components/LoadingSpinner";
import { MainLayout } from "src/components/MainLayout";
import { useUserRecord } from "src/stores/user";
import { makeWorkspaceHomeUrl } from "src/utils/workspaces";
import { NameAndSlugFields } from "./Fields";
import { useCreateWorkspace, useUserWorkspaces } from "./hooks";
var useCreateNonConflictingSlug = function () {
    return useMutation({
        mutationFn: function (name) { return __awaiter(void 0, void 0, void 0, function () {
            var slug;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, createNonConflictingSlug(name, supabase)];
                    case 1:
                        slug = _a.sent();
                        return [2 /*return*/, slug];
                }
            });
        }); },
    });
};
var useCheckSlugAvailability = function () {
    return useMutation({
        mutationFn: function (slug) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, checkIfSlugIsAvailable(slug, supabase)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        }); },
    });
};
export var NewWorkspaceView = function () {
    var userRecord = useUserRecord();
    var userWorkspaces = useUserWorkspaces().data;
    // const nameFromParams = searchParams.get("name");
    var shouldSetDefaults = !userWorkspaces;
    var _a = useState(null), isSlugAvailable = _a[0], setIsSlugAvailable = _a[1];
    var _b = useForm({
        defaultValues: {
            name: shouldSetDefaults ? (userRecord.name ? "".concat(userRecord.name, "'s Space") : "") : "",
        },
    }), register = _b.register, handleSubmit = _b.handleSubmit, watch = _b.watch, setValue = _b.setValue, errors = _b.formState.errors;
    var createWorkspaceMutation = useCreateWorkspace();
    var slugMutation = useCreateNonConflictingSlug();
    var checkSlugAvailabilityMutation = useCheckSlugAvailability();
    var navigate = useNavigate();
    var userHasWorkspaces = userWorkspaces && userWorkspaces.length > 0;
    useMount(function () { return __awaiter(void 0, void 0, void 0, function () {
        var defaultName, defaultSlug;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!shouldSetDefaults) return [3 /*break*/, 2];
                    defaultName = userRecord.name;
                    if (!defaultName) return [3 /*break*/, 2];
                    return [4 /*yield*/, slugMutation.mutateAsync(defaultName)];
                case 1:
                    defaultSlug = _a.sent();
                    setValue("slug", defaultSlug);
                    setIsSlugAvailable(true);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); });
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var wsSlug;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, createWorkspaceMutation.mutateAsync(data)];
                case 1:
                    wsSlug = (_a.sent()).wsSlug;
                    posthog.capture("workspace_created", {
                        workspace_id: wsSlug,
                        user_id: userRecord.id,
                        is_setup: !userHasWorkspaces,
                    });
                    navigate(makeWorkspaceHomeUrl(wsSlug));
                    return [2 /*return*/];
            }
        });
    }); };
    var watchSlug = watch("slug");
    var watchName = watch("name");
    var handleSlugChange = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var newSlug, available;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    newSlug = e.target.value
                        .toLowerCase()
                        .replace(/\s/g, "-") // Replace spaces with hyphens
                        .replace(/[^a-z0-9-]/g, "");
                    setValue("slug", newSlug);
                    if (!newSlug) return [3 /*break*/, 2];
                    return [4 /*yield*/, checkSlugAvailabilityMutation.mutateAsync(newSlug)];
                case 1:
                    available = _a.sent();
                    setIsSlugAvailable(available);
                    return [3 /*break*/, 3];
                case 2:
                    setIsSlugAvailable(null);
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    // const handleCreateWithWebsite = () => {
    // 	navigate("/onboarding/workspaces/new/website-onboarding");
    // };
    return (_jsx(MainLayout, { className: "flex h-screen flex-col items-center justify-center", children: _jsxs("div", { className: "flex w-full max-w-md flex-col gap-4 rounded-md border border-border p-6", children: [_jsx("h1", { className: "font-display text-2xl font-bold", children: "Create a workspace" }), _jsx("p", { className: "text-sm text-secondary", children: "A workspace can be a space for your team to collaborate or it can just be for you. Don't worry, you can change the name or slug anytime." }), _jsxs("form", { onSubmit: handleSubmit(onSubmit), className: "flex flex-col gap-4", children: [_jsx(NameAndSlugFields, { register: register, errors: errors, isSlugAvailable: isSlugAvailable, handleSlugChange: handleSlugChange }), _jsx(Button, { type: "submit", disabled: createWorkspaceMutation.isPending || !watchSlug || !watchName || !isSlugAvailable, children: createWorkspaceMutation.isPending ? (_jsx(LoadingSpinner, { size: "xs", variant: "background" })) : ("Create Workspace") })] }), userHasWorkspaces && (_jsx(Link, { to: "/", children: _jsxs(Button, { variant: "ghost", className: "w-full text-secondary", children: [_jsx(ArrowLeftIcon, { className: "size-4" }), _jsx("span", { children: "Cancel workspace creation" })] }) }))] }) }));
};
