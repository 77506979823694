var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ChatRole, extractInnerTextFromXml, handleSupabaseError, } from "@cloudy/utils/common";
import { useMutation } from "@tanstack/react-query";
import { diffLines } from "diff";
import { AlertCircleIcon, CheckCircle2Icon, ChevronsLeftIcon, XCircleIcon } from "lucide-react";
import posthog from "posthog-js";
import React, { useContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { apiClient } from "src/api/client";
import { queryClient } from "src/api/queryClient";
import { chatThreadQueryKeys } from "src/api/queryKeys";
import { supabase } from "src/clients/supabase";
import { Button } from "src/components/Button";
import { CopyButton } from "src/components/CopyButton";
import LoadingSpinner from "src/components/LoadingSpinner";
import { useWorkspace } from "src/stores/workspace";
import { cn } from "src/utils";
import { simpleHash } from "src/utils/hash";
import { ThoughtContext } from "../thoughtContext";
import { ChatMessageContext } from "./chat";
import { showDiffInEditor } from "./utils";
var useApplySuggestion = function () {
    var workspace = useWorkspace();
    var _a = useContext(ThoughtContext), editor = _a.editor, thoughtId = _a.thoughtId;
    return useMutation({
        mutationFn: function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var originalSnippet, replacementSnippet, contentMd, result;
            var suggestionContent = _b.suggestionContent;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        originalSnippet = extractInnerTextFromXml(suggestionContent, "original_content").trim();
                        replacementSnippet = extractInnerTextFromXml(suggestionContent, "replacement_content").trim();
                        contentMd = editor.storage.markdown.getMarkdown();
                        originalSnippet = originalSnippet.replaceAll("\\`\\`\\`", "```");
                        replacementSnippet = replacementSnippet.replaceAll("\\`\\`\\`", "```");
                        if (!contentMd.includes(originalSnippet)) return [3 /*break*/, 1];
                        editor === null || editor === void 0 ? void 0 : editor.commands.setContent(contentMd.replace(originalSnippet, replacementSnippet));
                        posthog.capture("normal_replace_used", {
                            workspace_id: workspace.id,
                            workspace_slug: workspace.slug,
                        });
                        return [3 /*break*/, 3];
                    case 1:
                        console.log("Will need advanced replace.", contentMd, originalSnippet);
                        posthog.capture("advanced_replace_used", {
                            workspace_id: workspace.id,
                            workspace_slug: workspace.slug,
                        });
                        return [4 /*yield*/, apiClient.post("/api/ai/advanced-apply-change", {
                                suggestionContent: suggestionContent,
                                documentId: thoughtId,
                            })];
                    case 2:
                        result = _c.sent();
                        if (result.data.originalSnippet && result.data.replacementSnippet) {
                            editor === null || editor === void 0 ? void 0 : editor.commands.setContent(contentMd.replace(result.data.originalSnippet, result.data.replacementSnippet));
                        }
                        else {
                            throw new Error("Advanced replace failed.");
                        }
                        _c.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); },
        throwOnError: false,
    });
};
var useMarkAsApplied = function () {
    return useMutation({
        mutationFn: function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var appliedSuggestionHashes, _c, threadId, _d;
            var messageId = _b.messageId, suggestionHash = _b.suggestionHash;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _c = handleSupabaseError;
                        return [4 /*yield*/, supabase.from("chat_messages").select("applied_suggestion_hashes").eq("id", messageId).single()];
                    case 1:
                        appliedSuggestionHashes = _c.apply(void 0, [_e.sent()]).applied_suggestion_hashes;
                        _d = handleSupabaseError;
                        return [4 /*yield*/, supabase
                                .from("chat_messages")
                                .update({
                                applied_suggestion_hashes: __spreadArray(__spreadArray([], appliedSuggestionHashes, true), [suggestionHash], false),
                            })
                                .eq("id", messageId)
                                .select("thread_id")
                                .single()];
                    case 2:
                        threadId = _d.apply(void 0, [_e.sent()]).thread_id;
                        return [2 /*return*/, threadId];
                }
            });
        }); },
        onSuccess: function (threadId) {
            queryClient.invalidateQueries({ queryKey: chatThreadQueryKeys.thread(threadId) });
        },
    });
};
var makeSuggestionHash = function (suggestionContent) {
    return suggestionContent && suggestionContent.length > 0 ? simpleHash(suggestionContent) : "";
};
export var SuggestionContent = function (_a) {
    var _b;
    var children = _a.children;
    var message = useContext(ChatMessageContext).message;
    var _c = useContext(ThoughtContext), previewingKey = _c.previewingKey, storeContentIfNeeded = _c.storeContentIfNeeded, storeContentAsApplyContent = _c.storeContentAsApplyContent, setPreviewingKey = _c.setPreviewingKey, setIsEditingDisabled = _c.setIsEditingDisabled, restoreFromLastContent = _c.restoreFromLastContent, clearStoredContent = _c.clearStoredContent, editor = _c.editor, disableUpdatesRef = _c.disableUpdatesRef, applySuggestedChanges = _c.applySuggestedChanges, onStartAiEdits = _c.onStartAiEdits, onFinishAiEdits = _c.onFinishAiEdits;
    var applySuggestionMutation = useApplySuggestion();
    var markAsAppliedMutation = useMarkAsApplied();
    var content = React.Children.map(children, function (child) {
        if (React.isValidElement(child) && child.type === "code") {
            return child.props.children;
        }
        return child;
    });
    var suggestionContent = (_b = content === null || content === void 0 ? void 0 : content.at(0)) === null || _b === void 0 ? void 0 : _b.trim();
    var suggestionHash = useMemo(function () { return makeSuggestionHash(suggestionContent); }, [suggestionContent]);
    var isApplied = message.applied_suggestion_hashes.includes(suggestionHash);
    var isGenerating = message.role === ChatRole.Assistant && !message.completed_at;
    var currentIsPreviewing = previewingKey === suggestionHash;
    var applySuggestion = function () { return __awaiter(void 0, void 0, void 0, function () {
        var existingContentMd, error_1, newContentMd;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!suggestionContent || !editor) {
                        return [2 /*return*/];
                    }
                    if (!(typeof suggestionContent === "string")) return [3 /*break*/, 5];
                    disableUpdatesRef.current = true;
                    storeContentIfNeeded();
                    setPreviewingKey(suggestionHash);
                    onStartAiEdits();
                    existingContentMd = (_a = editor === null || editor === void 0 ? void 0 : editor.storage.markdown.getMarkdown()) !== null && _a !== void 0 ? _a : "";
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, applySuggestionMutation.mutateAsync({ suggestionContent: suggestionContent })];
                case 2:
                    _c.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _c.sent();
                    toast.error("Failed to apply suggestion");
                    setPreviewingKey(null);
                    return [3 /*break*/, 4];
                case 4:
                    newContentMd = (_b = editor === null || editor === void 0 ? void 0 : editor.storage.markdown.getMarkdown()) !== null && _b !== void 0 ? _b : "";
                    storeContentAsApplyContent();
                    showDiffInEditor(existingContentMd, newContentMd, editor);
                    onFinishAiEdits();
                    _c.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var confirmSuggestion = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    applySuggestedChanges();
                    return [4 /*yield*/, markAsAppliedMutation.mutateAsync({ messageId: message.id, suggestionHash: suggestionHash })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var revertSuggestion = function () {
        setPreviewingKey(null);
        setIsEditingDisabled(false);
        restoreFromLastContent();
        clearStoredContent();
        disableUpdatesRef.current = false;
    };
    // Parse the suggestionContent for original and replacement content
    var parseSuggestionContent = function (content) {
        var originalMatch = content.match(/<original_content>([\s\S]*?)<\/original_content>/);
        var replacementMatch = content.match(/<replacement_content>([\s\S]*?)<\/replacement_content>/);
        var originalContent = originalMatch ? originalMatch[1] : "";
        var replacementContent = replacementMatch ? replacementMatch[1] : "";
        originalContent = originalContent.replaceAll("\\`\\`\\`", "```");
        replacementContent = replacementContent = replacementContent.replaceAll("\\`\\`\\`", "```");
        return {
            original: originalContent,
            replacement: replacementContent,
        };
    };
    var _d = useMemo(function () { return parseSuggestionContent(suggestionContent || ""); }, [suggestionContent]), original = _d.original, replacement = _d.replacement;
    var diff = useMemo(function () { return diffLines(original, replacement); }, [original, replacement]);
    var canApply = useMemo(function () { var _a; return original && ((_a = editor === null || editor === void 0 ? void 0 : editor.storage.markdown.getMarkdown()) === null || _a === void 0 ? void 0 : _a.includes(original)); }, [original, editor]);
    // Add state for active tab
    var _e = useState("diff"), activeTab = _e[0], setActiveTab = _e[1];
    return (_jsxs("pre", { className: "my-1 rounded bg-card !px-0 !py-2 !font-sans", children: [_jsxs("div", { className: "flex border-b px-4", children: [_jsx("button", { className: "px-4 py-2 ".concat(activeTab === "diff" ? "border-b-2 border-accent text-accent" : "text-gray-500"), onClick: function () { return setActiveTab("diff"); }, children: "Diff" }), _jsx("button", { className: "px-4 py-2 ".concat(activeTab === "replacement" ? "border-b-2 border-accent text-accent" : "text-gray-500"), onClick: function () { return setActiveTab("replacement"); }, children: "Result" })] }), activeTab === "diff" ? (_jsx("p", { className: "text-wrap break-words px-4 font-mono text-xs", children: diff.map(function (part, index) { return (_jsx("span", { className: cn(part.added
                        ? "bg-green-200 dark:bg-green-800"
                        : part.removed
                            ? "bg-red-200 line-through dark:bg-red-800"
                            : ""), children: part.value }, index)); }) })) : (_jsx("p", { className: "text-wrap break-words px-4", children: replacement })), _jsx("div", { className: "mt-2 flex w-full flex-row items-center gap-1.5 px-4", children: isGenerating ? (_jsx(LoadingSpinner, { size: "xs" })) : currentIsPreviewing && !applySuggestionMutation.isPending ? (_jsxs(_Fragment, { children: [_jsxs(Button, { size: "sm", variant: "default", className: "bg-green-600 hover:bg-green-600/80", onClick: confirmSuggestion, children: [_jsx(CheckCircle2Icon, { className: "size-4" }), _jsx("span", { children: "Accept" })] }), _jsxs(Button, { size: "sm", variant: "destructive", onClick: revertSuggestion, children: [_jsx(XCircleIcon, { className: "size-4" }), _jsx("span", { children: "Reject" })] })] })) : (_jsxs(_Fragment, { children: [isApplied ? (_jsxs(Button, { size: "sm", variant: "outline", className: "bg-background text-accent", onClick: function () { }, disabled: true, children: [_jsx(CheckCircle2Icon, { className: "size-4" }), _jsx("span", { children: "Applied" })] })) : (_jsxs(Button, { size: "sm", variant: "outline", className: "bg-background text-accent hover:bg-accent/20", onClick: applySuggestion, disabled: applySuggestionMutation.isPending, children: [applySuggestionMutation.isPending ? (_jsx(LoadingSpinner, { size: "xs" })) : (_jsx(ChevronsLeftIcon, { className: "size-4" })), _jsx("span", { children: "Apply" })] })), _jsx(CopyButton, { textToCopy: replacement, size: "sm", variant: "outline", className: "bg-background" }), !canApply && !isApplied && (_jsxs("div", { className: "flex flex-row items-center gap-1", children: [_jsx(AlertCircleIcon, { className: "size-4 text-tertiary" }), _jsx("span", { className: "text-xs text-tertiary", children: "This apply may take longer." })] }))] })) })] }));
};
