export var ChatRole;
(function (ChatRole) {
    ChatRole["User"] = "user";
    ChatRole["Assistant"] = "assistant";
})(ChatRole || (ChatRole = {}));
export var ChatThreadType;
(function (ChatThreadType) {
    ChatThreadType["Default"] = "default";
    ChatThreadType["DocumentUpdate"] = "document-update";
})(ChatThreadType || (ChatThreadType = {}));
