var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { cva } from "class-variance-authority";
import React, { useState } from "react";
import { Button } from "src/components/Button";
import { cn } from "../utils";
export var Dropdown = function (_a) {
    var trigger = _a.trigger, children = _a.children, _b = _a.align, align = _b === void 0 ? "end" : _b, _c = _a.side, side = _c === void 0 ? "bottom" : _c, className = _a.className, onClose = _a.onClose;
    var _d = useState(false), open = _d[0], setOpen = _d[1];
    return (_jsxs(DropdownMenu.Root, { open: open, onOpenChange: function (open) {
            setOpen(open);
            if (!open) {
                onClose === null || onClose === void 0 ? void 0 : onClose();
            }
        }, children: [_jsx(DropdownMenu.Trigger, { asChild: true, children: trigger }), _jsx(DropdownMenu.Portal, { children: _jsx(DropdownMenu.Content, { align: align, sideOffset: 5, side: side, className: cn("z-50 min-w-[8rem] overflow-hidden rounded-md border border-border bg-background p-1 shadow-md animate-in data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2", className), children: typeof children === "function"
                        ? children({
                            open: open,
                            close: function () {
                                setOpen(false);
                            },
                        })
                        : children }) })] }));
};
var dropdownItemVariants = cva("relative flex cursor-pointer select-none items-center gap-2 rounded px-2 py-1.5 text-sm outline-none transition-colors data-[disabled]:pointer-events-none data-[disabled]:opacity-50", {
    variants: {
        variant: {
            default: "",
            ghost: "focus:bg-accent focus:text-background",
        },
        disableHover: {
            true: "",
            false: "",
        },
    },
    compoundVariants: [
        {
            variant: "default",
            disableHover: false,
            className: "focus:bg-card",
        },
        {
            variant: "ghost",
            disableHover: false,
            className: "hover:bg-accent",
        },
    ],
    defaultVariants: {
        variant: "default",
        disableHover: false,
    },
});
export var DropdownItem = React.forwardRef(function (_a, ref) {
    var className = _a.className, variant = _a.variant, disableHover = _a.disableHover, props = __rest(_a, ["className", "variant", "disableHover"]);
    return (_jsx(DropdownMenu.Item, __assign({ ref: ref, className: cn(dropdownItemVariants({ variant: variant, disableHover: disableHover }), className) }, props)));
});
DropdownItem.displayName = "DropdownItem";
export var DropdownItemButton = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (_jsx(DropdownMenu.Item, { asChild: true, children: _jsx(Button, __assign({ ref: ref, className: cn("w-full justify-start", className) }, props)) }));
});
DropdownItemButton.displayName = "DropdownItemButton";
export var DropdownSeparator = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (_jsx(DropdownMenu.Separator, __assign({ ref: ref, className: cn("-mx-1 my-1 h-px bg-border", className) }, props)));
});
DropdownSeparator.displayName = "DropdownSeparator";
