var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { handleSupabaseError } from "@cloudy/utils/common";
import { useMutation } from "@tanstack/react-query";
import { PlusIcon, XIcon } from "lucide-react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { queryClient } from "src/api/queryClient";
import { collectionQueryKeys } from "src/api/queryKeys";
import { supabase } from "src/clients/supabase";
import { Button } from "src/components/Button";
import { Input } from "src/components/Input";
import { MainLayout } from "src/components/MainLayout";
import { useWorkspace } from "src/stores/workspace";
var defaultCollections = ["Ideation", "Tech Specs", "Interviews"];
var useCreateCollections = function () {
    var workspace = useWorkspace();
    return useMutation({
        mutationFn: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase.from("collections").insert(data.collections.map(function (collection) { return ({
                                title: collection.name,
                                workspace_id: workspace.id,
                            }); }))];
                    case 1:
                        _a.apply(void 0, [_c.sent()]);
                        _b = handleSupabaseError;
                        return [4 /*yield*/, supabase.from("workspaces").update({ onboarding_status: "done" }).eq("id", workspace.id)];
                    case 2:
                        _b.apply(void 0, [_c.sent()]);
                        return [2 /*return*/];
                }
            });
        }); },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: collectionQueryKeys.workspaceCollections(workspace.id) });
        },
    });
};
var useSkipOnboarding = function () {
    var workspace = useWorkspace();
    return useMutation({
        mutationFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase.from("workspaces").update({ onboarding_status: "done" }).eq("id", workspace.id)];
                    case 1:
                        _a.apply(void 0, [_b.sent()]);
                        return [2 /*return*/];
                }
            });
        }); },
    });
};
export var InitialCollectionsView = function () {
    var searchParams = useSearchParams()[0];
    var initialCollections = searchParams.get("initialCollections")
        ? JSON.parse(searchParams.get("initialCollections"))
        : defaultCollections;
    var navigate = useNavigate();
    var workspace = useWorkspace();
    var _a = useForm({
        defaultValues: {
            collections: initialCollections.map(function (name) { return ({ name: name }); }),
        },
    }), control = _a.control, handleSubmit = _a.handleSubmit, register = _a.register;
    var _b = useFieldArray({
        control: control,
        name: "collections",
    }), fields = _b.fields, append = _b.append, remove = _b.remove;
    var createCollectionsMutation = useCreateCollections();
    var skipOnboardingMutation = useSkipOnboarding();
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, createCollectionsMutation.mutateAsync(data)];
                case 1:
                    _a.sent();
                    navigate("/workspaces/".concat(workspace.slug));
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSkip = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, skipOnboardingMutation.mutateAsync()];
                case 1:
                    _a.sent();
                    navigate("/workspaces/".concat(workspace.slug));
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(MainLayout, { className: "flex h-screen flex-col items-center justify-center", children: _jsxs("div", { className: "flex w-full max-w-md flex-col gap-4 rounded-md border border-border p-6", children: [_jsx("h1", { className: "font-display text-2xl font-bold", children: "Create initial collections" }), _jsx("p", { className: "text-sm text-secondary", children: "Start with some common collections for your notes or create your own. You can always add or remove collections later." }), _jsxs("form", { onSubmit: handleSubmit(onSubmit), className: "flex flex-col gap-4", children: [_jsxs("div", { className: "mb-4 flex flex-col gap-2", children: [fields.map(function (field, index) { return (_jsxs("div", { className: "flex items-center gap-2", children: [_jsx(Input, __assign({}, register("collections.".concat(index, ".name"), { required: true }), { placeholder: "Collection name" })), _jsx(Button, { type: "button", variant: "ghost", size: "icon", onClick: function () { return remove(index); }, className: "text-secondary", children: _jsx(XIcon, { className: "size-4" }) })] }, field.id)); }), _jsxs(Button, { type: "button", variant: "secondary", onClick: function () { return append({ name: "" }); }, className: "flex items-center justify-start", children: [_jsx(PlusIcon, { className: "size-4" }), "Add Collection"] })] }), _jsx(Button, { type: "submit", disabled: createCollectionsMutation.isPending, children: createCollectionsMutation.isPending ? "Creating..." : "Create Collections" }), _jsx(Button, { type: "button", variant: "secondary", onClick: handleSkip, disabled: skipOnboardingMutation.isPending, children: skipOnboardingMutation.isPending ? "Skipping..." : "Skip for now" })] })] }) }));
};
