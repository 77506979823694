import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SiGithub } from "@icons-pack/react-simple-icons";
import { ExternalLinkIcon, LinkIcon, UnlinkIcon, XIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "src/components/Button";
import { Tooltip, TooltipContent, TooltipTrigger } from "src/components/Tooltip";
export var FileReferencePill = function (_a) {
    var repoFullName = _a.repoFullName, path = _a.path, fileUrl = _a.fileUrl, onRemove = _a.onRemove, isExisting = _a.isExisting, onConnect = _a.onConnect, onDisconnect = _a.onDisconnect, showConnectTooltip = _a.showConnectTooltip, showUnlinkIconInsteadOfX = _a.showUnlinkIconInsteadOfX;
    return (_jsxs(Tooltip, { durationPreset: "instant", children: [_jsx(TooltipTrigger, { children: _jsxs("div", { className: "flex h-6 cursor-default flex-row items-center gap-0.5 rounded border border-border px-1.5 text-xs hover:bg-card", children: [_jsx("span", { className: "truncate", children: path.split("/").pop() }), onRemove && (_jsx(Button, { size: "icon-xs-overflow", variant: "ghost", className: "-mr-2 hover:bg-transparent hover:text-accent active:bg-transparent", onClick: onRemove, children: showUnlinkIconInsteadOfX ? _jsx(UnlinkIcon, { className: "size-3" }) : _jsx(XIcon, { className: "size-3" }) }))] }) }), _jsx(TooltipContent, { children: _jsxs("div", { className: "flex flex-col items-center gap-1", children: [_jsxs("div", { className: "flex flex-row items-center gap-1", children: [_jsx(SiGithub, { className: "size-3.5 text-secondary" }), _jsx("span", { className: "text-xs text-secondary", children: repoFullName })] }), _jsx(Link, { to: fileUrl, target: "_blank", children: _jsxs("div", { className: "flex flex-row items-center gap-1 text-accent hover:opacity-60", children: [_jsx("span", { className: "text-xs underline", children: path }), _jsx(ExternalLinkIcon, { className: "size-3" })] }) }), showConnectTooltip && (_jsx("div", { className: "mt-1", children: isExisting ? (_jsxs(Button, { size: "xs", variant: "outline", onClick: onDisconnect, children: [_jsx(UnlinkIcon, { className: "size-3" }), _jsx("span", { children: "Unlink from document" })] })) : (_jsxs(Button, { size: "xs", variant: "outline", onClick: onConnect, children: [_jsx(LinkIcon, { className: "size-3" }), _jsx("span", { children: "Connect to document" })] })) }))] }) })] }));
};
