import { useEffect, useMemo, useRef, useState } from "react";
import * as Y from "yjs";
import { supabase } from "src/clients/supabase";
import { SupabaseProvider } from "src/utils/yjsSyncProvider";
export var useYProvider = function (thoughtId, disableUpdatesRef) {
    var _a = useState(false), isConnected = _a[0], setIsConnected = _a[1];
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var providerRef = useRef(null);
    var _c = useMemo(function () {
        var doc = new Y.Doc({ guid: thoughtId });
        var newProvider = new SupabaseProvider(supabase, {
            id: thoughtId,
            name: "thought:".concat(thoughtId),
            document: doc,
            databaseDetails: {
                schema: "public",
                table: "note_contents",
                updateColumns: { name: "id", content: "content" },
                conflictColumns: "id",
            },
            disableUpdatesRef: disableUpdatesRef,
        });
        return { provider: newProvider, doc: doc };
    }, [thoughtId, disableUpdatesRef]), ydoc = _c.doc, provider = _c.provider;
    useEffect(function () {
        // Store the current object in the ref
        var previousProvider = providerRef.current;
        providerRef.current = provider;
        // Cleanup function
        return function () {
            if (previousProvider && previousProvider !== provider) {
                previousProvider.destroy();
            }
        };
    }, [provider]);
    useEffect(function () {
        provider.on("synced", function () {
            setIsConnected(true);
            setIsLoading(false);
        });
        provider.on("disconnect", function () {
            setIsConnected(false);
        });
    }, [provider]);
    return { isLoading: isLoading, isConnected: isConnected, ydoc: ydoc, provider: provider };
};
