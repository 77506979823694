var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { handleSupabaseError } from "@cloudy/utils/common";
import { Node, mergeAttributes } from "@tiptap/core";
import { PluginKey } from "@tiptap/pm/state";
import { ReactRenderer } from "@tiptap/react";
import Suggestion from "@tiptap/suggestion";
import tippy from "tippy.js";
import { supabase } from "src/clients/supabase";
import { makeThoughtLabel } from "src/utils/thought";
import { MentionHandler } from "./MentionHandler";
export var mention = {
    render: function () {
        var reactRenderer;
        var popup;
        return {
            onStart: function (props) {
                if (!props.clientRect) {
                    return;
                }
                reactRenderer = new ReactRenderer(MentionHandler, {
                    props: props,
                    editor: props.editor,
                });
                popup = tippy("body", {
                    getReferenceClientRect: props.clientRect,
                    appendTo: function () { return document.body; },
                    content: reactRenderer.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: "manual",
                    placement: "bottom-start",
                });
            },
            onUpdate: function (props) {
                reactRenderer.updateProps(props);
                if (!props.clientRect) {
                    return;
                }
                popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                });
            },
            onKeyDown: function (props) {
                var _a;
                var hide = function () {
                    popup[0].hide();
                };
                // @ts-ignore
                return (_a = reactRenderer.ref) === null || _a === void 0 ? void 0 : _a.onKeyDown(__assign(__assign({}, props), { hide: hide }));
            },
            onExit: function () {
                popup[0].destroy();
                reactRenderer.destroy();
            },
        };
    },
};
/**
 * The plugin key for the mention plugin.
 * @default 'mention'
 */
export var MentionPluginKey = new PluginKey("mention");
var getHrefFromNode = function (node) {
    return "/thoughts/".concat(node.attrs.id);
};
/**
 * This extension allows you to insert mentions into the editor.
 * @see https://www.tiptap.dev/api/extensions/mention
 */
export var Mention = Node.create({
    name: "mention",
    addOptions: function () {
        var _this = this;
        return {
            HTMLAttributes: {},
            renderText: function (_a) {
                var _b;
                var options = _a.options, node = _a.node;
                return "".concat(options.suggestion.char).concat((_b = node.attrs.label) !== null && _b !== void 0 ? _b : node.attrs.id);
            },
            deleteTriggerWithBackspace: false,
            renderHTML: function (_a) {
                var _b;
                var options = _a.options, node = _a.node;
                return [
                    "a",
                    mergeAttributes({ class: "mention", href: getHrefFromNode(node) }, this.HTMLAttributes, options.HTMLAttributes),
                    "".concat(options.suggestion.char).concat((_b = node.attrs.label) !== null && _b !== void 0 ? _b : node.attrs.id),
                ];
            },
            suggestion: {
                char: "@",
                pluginKey: MentionPluginKey,
                command: function (_a) {
                    var _b, _c;
                    var editor = _a.editor, range = _a.range, props = _a.props;
                    // increase range.to by one when the next node is of type "text"
                    // and starts with a space character
                    var nodeAfter = editor.view.state.selection.$to.nodeAfter;
                    var overrideSpace = (_b = nodeAfter === null || nodeAfter === void 0 ? void 0 : nodeAfter.text) === null || _b === void 0 ? void 0 : _b.startsWith(" ");
                    if (overrideSpace) {
                        range.to += 1;
                    }
                    editor
                        .chain()
                        .focus()
                        .insertContentAt(range, [
                        {
                            type: _this.name,
                            attrs: props,
                        },
                        {
                            type: "text",
                            text: " ",
                        },
                    ])
                        .run();
                    (_c = window.getSelection()) === null || _c === void 0 ? void 0 : _c.collapseToEnd();
                },
                allow: function (_a) {
                    var state = _a.state, range = _a.range;
                    var $from = state.doc.resolve(range.from);
                    var type = state.schema.nodes[_this.name];
                    var allow = !!$from.parent.type.contentMatch.matchType(type);
                    return allow;
                },
            },
        };
    },
    group: "inline",
    inline: true,
    selectable: false,
    atom: true,
    addAttributes: function () {
        return {
            id: {
                default: null,
                parseHTML: function (element) { return element.getAttribute("data-id"); },
                renderHTML: function (attributes) {
                    if (!attributes.id) {
                        return {};
                    }
                    return {
                        "data-id": attributes.id,
                    };
                },
            },
            label: {
                default: null,
                parseHTML: function (element) { return element.getAttribute("data-label"); },
                renderHTML: function (attributes) {
                    if (!attributes.label) {
                        return {};
                    }
                    return {
                        "data-label": attributes.label,
                    };
                },
            },
        };
    },
    addStorage: function () {
        return {
            markdown: {
                serialize: function (state, node) {
                    var _a;
                    state.write("[@".concat((_a = node.attrs.label) !== null && _a !== void 0 ? _a : node.attrs.id, "](").concat(getHrefFromNode(node), ")"));
                },
                parse: {
                // We don't parse from markdown.
                },
            },
        };
    },
    parseHTML: function () {
        return [
            {
                tag: "a[data-type=\"".concat(this.name, "\"]"),
            },
        ];
    },
    renderHTML: function (_a) {
        var node = _a.node, HTMLAttributes = _a.HTMLAttributes;
        if (this.options.renderLabel !== undefined) {
            console.warn("renderLabel is deprecated use renderText and renderHTML instead");
            return [
                "a",
                mergeAttributes({ "data-type": this.name }, this.options.HTMLAttributes, HTMLAttributes),
                this.options.renderLabel({
                    options: this.options,
                    node: node,
                }),
            ];
        }
        var mergedOptions = __assign({}, this.options);
        mergedOptions.HTMLAttributes = mergeAttributes({ "data-type": this.name }, this.options.HTMLAttributes, HTMLAttributes);
        var html = this.options.renderHTML({
            options: mergedOptions,
            node: node,
        });
        if (typeof html === "string") {
            return ["a", mergeAttributes({ "data-type": this.name }, this.options.HTMLAttributes, HTMLAttributes), html];
        }
        return html;
    },
    renderText: function (_a) {
        var node = _a.node;
        if (this.options.renderLabel !== undefined) {
            console.warn("renderLabel is deprecated use renderText and renderHTML instead");
            return this.options.renderLabel({
                options: this.options,
                node: node,
            });
        }
        return this.options.renderText({
            options: this.options,
            node: node,
        });
    },
    addKeyboardShortcuts: function () {
        var _this = this;
        return {
            Backspace: function () {
                return _this.editor.commands.command(function (_a) {
                    var tr = _a.tr, state = _a.state;
                    var isMention = false;
                    var selection = state.selection;
                    var empty = selection.empty, anchor = selection.anchor;
                    if (!empty) {
                        return false;
                    }
                    state.doc.nodesBetween(anchor - 1, anchor, function (node, pos) {
                        if (node.type.name === _this.name) {
                            isMention = true;
                            tr.insertText(_this.options.deleteTriggerWithBackspace ? "" : _this.options.suggestion.char || "", pos, pos + node.nodeSize);
                            return false;
                        }
                    });
                    return isMention;
                });
            },
        };
    },
    addProseMirrorPlugins: function () {
        return [
            Suggestion(__assign({ editor: this.editor }, this.options.suggestion)),
        ];
    },
});
export var getAllMentionNodes = function (editor) {
    var mentions = [];
    editor.view.state.doc.descendants(function (node, pos) {
        if (node.type.name === "mention") {
            mentions.push({
                pos: pos,
                id: node.attrs.id,
                label: node.attrs.label,
            });
        }
    });
    return mentions;
};
export var updateNodeAttributes = function (editor, pos, attributes) {
    var state = editor.state;
    var tr = state.tr;
    var node = state.doc.nodeAt(pos);
    if (node) {
        tr.setNodeMarkup(pos, null, __assign(__assign({}, node.attrs), attributes));
        editor.view.dispatch(tr);
    }
};
export var updateMentionNodeNames = function (editor) { return __awaiter(void 0, void 0, void 0, function () {
    var mentions, potentialThoughtLabels, _a, labelIdList;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                mentions = getAllMentionNodes(editor);
                _a = handleSupabaseError;
                return [4 /*yield*/, supabase
                        .from("thoughts")
                        .select("id, title, content_plaintext, content_md")
                        .in("id", mentions.map(function (mention) { return mention.id; }))];
            case 1:
                potentialThoughtLabels = _a.apply(void 0, [_b.sent()]);
                labelIdList = potentialThoughtLabels.map(function (thought) { return ({ id: thought.id, label: makeThoughtLabel(thought) }); });
                mentions.forEach(function (mention) {
                    var _a;
                    var label = (_a = labelIdList.find(function (label) { return label.id === mention.id; })) === null || _a === void 0 ? void 0 : _a.label;
                    if (label && mention.label !== label) {
                        updateNodeAttributes(editor, mention.pos, { label: label });
                    }
                });
                return [2 /*return*/];
        }
    });
}); };
