export var extractInnerTextFromXml = function (xml, tag, options) {
    if (options === void 0) { options = { trim: false }; }
    var regex = new RegExp("<".concat(tag, "[^>]*>(.*?)</").concat(tag, ">"), "s");
    var match = xml.match(regex);
    return (match === null || match === void 0 ? void 0 : match[1]) ? (options.trim ? match[1].trim() : match[1]) : "";
};
export var extractMultipleInnerTextFromXml = function (xml, tag, options) {
    if (options === void 0) { options = { trim: false }; }
    var regex = new RegExp("<".concat(tag, "[^>]*>(.*?)</").concat(tag, ">"), "gs");
    var matches = Array.from(xml.matchAll(regex));
    return matches.map(function (match) {
        return match[1] ? (options.trim ? match[1].trim() : match[1]) : "";
    });
};
