var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Slider, Switch } from "@cloudy/ui";
import { useQuery } from "@tanstack/react-query";
import { DownloadIcon, ShareIcon } from "lucide-react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { pdfjs } from "react-pdf";
import { apiClient } from "src/api/client";
import { Button } from "src/components/Button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "src/components/Dialog";
import { Input } from "src/components/Input";
import LoadingSpinner from "src/components/LoadingSpinner";
import { SelectDropdown } from "src/components/SelectDropdown";
pdfjs.GlobalWorkerOptions.workerSrc = "//unpkg.com/pdfjs-dist@".concat(pdfjs.version, "/build/pdf.worker.min.mjs");
export var useGeneratePDF = function (thoughtId, options) {
    var _a = useQuery({
        queryKey: ["thoughts", thoughtId, "export", JSON.stringify(options)],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, apiClient.get("/api/thoughts/".concat(thoughtId, "/export"), {
                            params: {
                                options: JSON.stringify(options),
                            },
                            headers: {
                                Accept: "application/pdf",
                            },
                            responseType: "blob", // Set responseType to 'blob'
                        })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, URL.createObjectURL(response.data)];
                }
            });
        }); },
        staleTime: 15000,
    }), data = _a.data, isLoading = _a.isLoading;
    var downloadPdf = function () {
        var baseUrl = apiClient.defaults.baseURL;
        if (baseUrl) {
            var url = new URL("/api/thoughts/".concat(thoughtId, "/export"), baseUrl);
            url.searchParams.set("options", JSON.stringify(options));
            window.location.href = url.toString();
        }
    };
    return {
        pdf: data,
        isLoading: isLoading,
        downloadPdf: downloadPdf,
    };
};
export var ExportDialog = function (_a) {
    var thoughtId = _a.thoughtId, title = _a.title;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var handleOnClose = function () {
        setIsOpen(false);
    };
    return (_jsxs(Dialog, { open: isOpen, onOpenChange: setIsOpen, children: [_jsx(DialogTrigger, { children: _jsxs(Button, { variant: "ghost", className: "w-full justify-start", size: "sm", children: [_jsx(ShareIcon, { className: "size-4" }), _jsx("span", { children: "Export to PDF" })] }) }), isOpen && _jsx(ExportDialogInner, { thoughtId: thoughtId, title: title, onClose: handleOnClose })] }));
};
var ExportDialogInner = function (_a) {
    var _b;
    var thoughtId = _a.thoughtId, title = _a.title, onClose = _a.onClose;
    var _c = useForm({
        defaultValues: {
            hideWatermark: true,
            hideTitle: false,
            colorScheme: "white",
            fontSizePt: 11,
            paperSize: "letter",
            fileName: "".concat((_b = title === null || title === void 0 ? void 0 : title.replace(/[^a-zA-Z0-9-_]/g, "_")) !== null && _b !== void 0 ? _b : "exported_note", ".pdf"),
        },
    }), watch = _c.watch, control = _c.control, setValue = _c.setValue;
    // Watch all form fields
    var exportOptions = watch();
    var _d = useGeneratePDF(thoughtId, exportOptions), pdf = _d.pdf, downloadPdf = _d.downloadPdf, isLoading = _d.isLoading;
    return (_jsxs(DialogContent, { size: "lg", children: [_jsx(DialogHeader, { children: _jsx(DialogTitle, { children: "Export Note to PDF" }) }), _jsx("div", { className: "flex h-64 w-full items-center justify-center overflow-hidden rounded-sm border border-border bg-card p-0", children: pdf && !isLoading ? _jsx("iframe", { title: "pdf", className: "h-full w-full", src: pdf }) : _jsx(LoadingSpinner, { size: "sm" }) }), _jsxs("form", { className: "mb-4 mt-4 space-y-4", children: [_jsx(FormItem, { label: "Color Scheme", htmlFor: "colorScheme", children: _jsxs("div", { className: "flex space-x-2", children: [_jsx(ColorSwatch, { scheme: "default", isSelected: exportOptions.colorScheme === "default", onClick: function () { return setValue("colorScheme", "default"); } }), _jsx(ColorSwatch, { scheme: "white", isSelected: exportOptions.colorScheme === "white", onClick: function () { return setValue("colorScheme", "white"); } })] }) }), _jsx(FormItem, { label: "Font Size", htmlFor: "fontSizePt", children: _jsx("div", { className: "w-full pl-12", children: _jsx(Controller, { render: function (_a) {
                                    var field = _a.field;
                                    return (_jsx(Slider
                                    // {...field}
                                    , { 
                                        // {...field}
                                        min: 8, max: 18, defaultValue: [11], onValueCommit: function (values) {
                                            field.onChange(values[0]);
                                        }, showValue: true }));
                                }, control: control, name: "fontSizePt" }) }) }), _jsx(FormItem, { label: "Paper Size", htmlFor: "paperSize", children: _jsx(SelectDropdown, { options: [
                                { value: "a4", label: "A4" },
                                { value: "letter", label: "Letter" },
                                { value: "legal", label: "Legal" },
                            ], value: exportOptions.paperSize, onChange: function (value) { return setValue("paperSize", value); }, className: "w-32" }) }), _jsx(FormItem, { label: "Hide Branding", htmlFor: "hideWatermark", children: _jsx(Controller, { name: "hideWatermark", control: control, render: function (_a) {
                                var field = _a.field;
                                return _jsx(Switch, { defaultChecked: true, onCheckedChange: field.onChange });
                            } }) }), _jsx(FormItem, { label: "Hide Title", htmlFor: "hideTitle", children: _jsx(Controller, { name: "hideTitle", control: control, render: function (_a) {
                                var field = _a.field;
                                return _jsx(Switch, { defaultChecked: false, onCheckedChange: field.onChange });
                            } }) }), _jsx(FormItem, { label: "File Name", htmlFor: "fileName", children: _jsx(Controller, { name: "fileName", control: control, render: function (_a) {
                                var field = _a.field;
                                return _jsx(Input, __assign({}, field, { className: "w-full", placeholder: "Enter file name" }));
                            } }) })] }), _jsxs(DialogFooter, { className: "flex-col gap-2 md:flex-row", children: [_jsx(Button, { onClick: downloadPdf, disabled: !pdf || isLoading, children: pdf && !isLoading ? (_jsxs(_Fragment, { children: [_jsx(DownloadIcon, { className: "size-4" }), _jsx("span", { children: "Download PDF" })] })) : (_jsx(LoadingSpinner, { size: "xs", variant: "background" })) }), _jsx(Button, { variant: "secondary", onClick: onClose, children: "Close" })] })] }));
};
var FormItem = function (_a) {
    var label = _a.label, htmlFor = _a.htmlFor, children = _a.children;
    return (_jsxs("div", { className: "flex h-8 items-center justify-between gap-4", children: [_jsx("label", { htmlFor: htmlFor, className: "whitespace-nowrap", children: label }), children] }));
};
var ColorSwatch = function (_a) {
    var scheme = _a.scheme, isSelected = _a.isSelected, onClick = _a.onClick;
    var bgColor = scheme === "default" ? "bg-[#FFF5E1]" : "bg-white";
    var borderColor = isSelected ? "border-accent" : "border-card";
    return (_jsx("button", { type: "button", onClick: onClick, className: "h-8 w-8 rounded-full border-4 ".concat(bgColor, " ").concat(borderColor, " flex items-center justify-center overflow-hidden") }));
};
