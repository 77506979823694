import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
var messages = [
    "Visiting your website...",
    "Reading through pages...",
    "Analyzing content...",
    "Understanding your mission...",
    "Identifying key topics...",
    "Preparing workspace details...",
];
export var RotatingLoadingMessage = function () {
    var _a = useState(0), messageIndex = _a[0], setMessageIndex = _a[1];
    useEffect(function () {
        var interval = setInterval(function () {
            setMessageIndex(function (prevIndex) { return (prevIndex + 1) % messages.length; });
        }, 2000);
        return function () { return clearInterval(interval); };
    }, []);
    return _jsx("span", { children: messages[messageIndex] });
};
