var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
import { cn } from "../utils";
export var Input = forwardRef(function (_a, ref) {
    var className = _a.className, error = _a.error, prefix = _a.prefix, props = __rest(_a, ["className", "error", "prefix"]);
    return (_jsxs("div", { className: cn("flex h-10 w-full items-center rounded-md border border-border bg-background ring-offset-background focus-within:outline-none focus-within:ring-0", error && "border-red-500 focus-within:ring-red-500", className), children: [prefix && _jsx("div", { className: "flex items-center text-nowrap pl-3 text-sm text-secondary", children: prefix }), _jsx("input", __assign({ className: cn("h-full w-full bg-transparent px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-placeholder focus:outline-none disabled:cursor-not-allowed disabled:opacity-50", prefix && "pl-0", "focus:outline-none focus:ring-0"), ref: ref }, props))] }));
});
Input.displayName = "Input";
