var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AccessStrategies } from "@cloudy/utils/common";
import { useDroppable } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { ChevronDownIcon, ChevronRightIcon, FileIcon, FileLock2Icon, FolderIcon, FolderOpenIcon, GlobeIcon, MoreHorizontalIcon, PencilIcon, TrashIcon, } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "src/components/Button";
import { cn } from "src/utils";
import { useDeleteItem, useRenameItem } from "src/utils/folders";
import { useClickOutside } from "src/utils/hooks/useClickOutside";
export var SortableItem = function (_a) {
    var id = _a.id, depth = _a.depth, type = _a.type, name = _a.name, expanded = _a.expanded, toggleFolder = _a.toggleFolder, navigateToDoc = _a.navigateToDoc, accessStrategy = _a.accessStrategy, _b = _a.isOverlay, isOverlay = _b === void 0 ? false : _b, _c = _a.isOverFolder, isOverFolder = _c === void 0 ? false : _c, _d = _a.hasAfterDroppable, hasAfterDroppable = _d === void 0 ? false : _d, _e = _a.isInLibrary, isInLibrary = _e === void 0 ? false : _e;
    var location = useLocation();
    var renameItemMutation = useRenameItem();
    var deleteItemMutation = useDeleteItem();
    var _f = useState(false), isDropdownOpen = _f[0], setIsDropdownOpen = _f[1];
    var _g = useState(false), isEditing = _g[0], setIsEditing = _g[1];
    var _h = useState(name), editedName = _h[0], setEditedName = _h[1];
    var _j = useSortable({
        id: id,
        data: { isInLibrary: isInLibrary, type: type },
        disabled: isEditing, // Add this line to disable dragging while editing
    }), attributes = _j.attributes, listeners = _j.listeners, sortableRef = _j.setNodeRef, transition = _j.transition, isDragging = _j.isDragging;
    var _k = useDroppable({ id: "before:".concat(id) }), isOverBefore = _k.isOver, droppableBeforeRef = _k.setNodeRef;
    var _l = useDroppable({
        id: id,
    }), isOver = _l.isOver, droppableRef = _l.setNodeRef;
    var _m = useDroppable({ id: "after:".concat(id) }), isOverAfter = _m.isOver, droppableAfterRef = _m.setNodeRef;
    var inputRef = useRef(null);
    var setBeforeRefs = function (node) {
        droppableBeforeRef(node);
    };
    var setRefs = function (node) {
        sortableRef(node);
        if (type === "folder") {
            droppableRef(node);
        }
    };
    var setAfterRefs = function (node) {
        droppableAfterRef(node);
    };
    var style = isOverlay
        ? {}
        : {
            transition: transition,
            opacity: isDragging ? 0.5 : 1,
            backgroundColor: isOverFolder ? "#f0f0f0" : undefined, // Highlight folder on hover
        };
    useEffect(function () {
        var _a, _b;
        if (isEditing) {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            (_b = inputRef.current) === null || _b === void 0 ? void 0 : _b.select();
        }
    }, [isEditing]);
    var handleDoubleClick = function (e) {
        e.stopPropagation();
        setIsEditing(true);
        setEditedName(name);
    };
    var handleSubmit = function () {
        if (editedName !== name) {
            renameItemMutation.mutate({ id: id, name: editedName.trim(), type: type });
        }
        setIsEditing(false);
    };
    var handleKeyDown = function (e) {
        if (e.key === "Enter") {
            handleSubmit();
        }
        else if (e.key === "Escape") {
            setIsEditing(false);
            setEditedName(name);
        }
    };
    var isCurrentlyOpen = location.pathname.includes(id);
    return (_jsxs("li", { style: style, className: "list-none", children: [isInLibrary && (_jsx("div", { ref: setBeforeRefs, className: "pointer-events-none -my-4 flex h-8 flex-col justify-center", children: isOverBefore && _jsx("div", { className: "h-0.5 bg-border", style: { marginLeft: "".concat(depth * 16 + 8, "px") } }) })), _jsxs("div", __assign({ ref: setRefs, className: cn("group/item relative flex cursor-pointer items-center gap-1.5 rounded px-2 py-1 text-sm", isDragging ? "bg-transparent" : "hover:bg-card", isOver ? "bg-card" : undefined, isEditing ? "cursor-text" : undefined, // Add cursor-text when editing
                isCurrentlyOpen ? "bg-card" : undefined), style: { paddingLeft: "".concat(depth * 1.33 + 0.5, "rem") }, onClick: function () {
                    if (!isEditing) {
                        // Only handle clicks when not editing
                        if (type === "folder" && !isDragging) {
                            toggleFolder(id);
                        }
                        else if (type === "document") {
                            navigateToDoc === null || navigateToDoc === void 0 ? void 0 : navigateToDoc(id);
                        }
                    }
                } }, (!isOverlay && !isEditing ? __assign(__assign({}, attributes), listeners) : {}), { children: [type === "folder" && (_jsx(_Fragment, { children: expanded ? (_jsxs(_Fragment, { children: [_jsx(FolderOpenIcon, { className: "size-4 shrink-0 group-hover/item:hidden" }), _jsx(ChevronDownIcon, { className: "hidden size-4 shrink-0 group-hover/item:block" })] })) : (_jsxs(_Fragment, { children: [_jsx(FolderIcon, { className: "size-4 shrink-0 group-hover/item:hidden" }), _jsx(ChevronRightIcon, { className: "hidden size-4 shrink-0 group-hover/item:block" })] })) })), type === "document" && (_jsx(_Fragment, { children: accessStrategy === AccessStrategies.PRIVATE ? (_jsx(FileLock2Icon, { className: "size-4 shrink-0" })) : accessStrategy === AccessStrategies.PUBLIC ? (_jsx(GlobeIcon, { className: "size-4 shrink-0" })) : (_jsx(FileIcon, { className: "size-4 shrink-0" })) })), isEditing ? (_jsx("input", { ref: inputRef, type: "text", value: editedName, onChange: function (e) { return setEditedName(e.target.value); }, onBlur: handleSubmit, onKeyDown: handleKeyDown, onClick: function (e) { return e.stopPropagation(); }, className: "flex-1 rounded border border-border bg-background px-1 py-0.5 text-sm focus:outline-none focus:ring-0", autoFocus: true })) : (_jsx("span", { className: cn("flex-1 truncate", !name && "text-secondary"), onDoubleClick: handleDoubleClick, children: name || "Untitled" })), _jsx(Button, { variant: "ghost", size: "icon-xs-overflow", className: "relative hidden size-5 shrink-0 rounded-sm hover:bg-border hover:text-accent group-hover/item:flex group-hover:flex", onClick: function (e) {
                            e.stopPropagation();
                            setIsDropdownOpen(true);
                        }, children: _jsx(MoreHorizontalIcon, { className: "size-4" }) }), _jsx(SortableItemDropdown, { isOpen: isDropdownOpen, setIsOpen: setIsDropdownOpen, onStartEditing: function () {
                            setIsEditing(true);
                            setEditedName(name);
                        }, onDelete: function () { return deleteItemMutation.mutate({ id: id, type: type }); } })] })), isInLibrary && hasAfterDroppable && (_jsx("div", { ref: setAfterRefs, className: "pointer-events-none -my-4 flex h-8 flex-col justify-center", children: isOverAfter && _jsx("div", { className: "h-0.5 bg-border", style: { marginLeft: "".concat(depth * 16 + 8, "px") } }) }))] }));
};
var SortableItemDropdown = function (_a) {
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, onStartEditing = _a.onStartEditing, onDelete = _a.onDelete;
    var dropdownRef = useClickOutside(function () {
        setIsOpen(false);
    });
    if (!isOpen)
        return null;
    return (_jsx("div", { className: "absolute bottom-0 left-0 h-0 w-full", children: _jsxs("div", { ref: dropdownRef, className: "absolute top-0 z-20 flex w-full flex-col rounded border border-border bg-background p-1", children: [_jsxs(SortableItemDropdownItem, { onClick: function () {
                        setIsOpen(false);
                        onStartEditing();
                    }, children: [_jsx(PencilIcon, { className: "mr-2 size-4" }), "Rename"] }), _jsxs(SortableItemDropdownItem, { onClick: function () {
                        setIsOpen(false);
                        onDelete();
                    }, children: [_jsx(TrashIcon, { className: "mr-2 size-4" }), "Delete"] })] }) }));
};
var SortableItemDropdownItem = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (_jsx(Button, __assign({ size: "sm", variant: "ghost", className: "w-full justify-start rounded-sm text-sm hover:bg-card hover:text-primary" }, props, { children: children })));
};
