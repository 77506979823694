var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChatRole } from "@cloudy/utils/common";
import { useContext, useEffect, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { cn } from "src/utils";
import { AiTextArea } from "../../aiTextArea/AiTextArea";
import { useExistingLinkedFiles } from "../hooks";
import { ThoughtContext } from "../thoughtContext";
import { ChatContent } from "./ChatContent";
import { ChatHomeView } from "./ChatHomeView";
import { useChatThread, useReplyToThread, useStartThread } from "./chat";
export var ChatSection = function () {
    var _a = useContext(ThoughtContext), editor = _a.editor, hideAiEditor = _a.hideAiEditor, thoughtId = _a.thoughtId, threadId = _a.threadId, setThreadId = _a.setThreadId;
    var existingLinkedFiles = useExistingLinkedFiles(thoughtId).data;
    var startThreadMutation = useStartThread();
    var replyToThreadMutation = useReplyToThread();
    var thread = useChatThread(threadId).data;
    var textAreaRef = useRef(null);
    var handleOnCancel = function () {
        hideAiEditor();
    };
    var handleSubmit = function (text, fileReferences) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!threadId) return [3 /*break*/, 2];
                    return [4 /*yield*/, replyToThreadMutation.mutateAsync({ threadId: threadId, content: text, fileReferences: fileReferences })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, handleStartThread(text, fileReferences)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleStartThread = function (text, fileReferences) { return __awaiter(void 0, void 0, void 0, function () {
        var id;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!editor) {
                        throw new Error("Editor is not initialized");
                    }
                    return [4 /*yield*/, startThreadMutation.mutateAsync({ content: text, fileReferences: fileReferences })];
                case 1:
                    id = (_a.sent()).id;
                    setThreadId(id);
                    return [2 /*return*/];
            }
        });
    }); };
    useHotkeys("esc", function () { return handleOnCancel(); });
    useEffect(function () {
        var _a;
        // For some reason, autofocus doesn't work and we have to manually focus the text area
        (_a = textAreaRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    var isThreadLoading = thread === null || thread === void 0 ? void 0 : thread.messages.some(function (message) { return message.role === ChatRole.Assistant && !message.completed_at; });
    return (_jsxs("div", { className: "flex w-full flex-1 flex-col overflow-hidden", children: [_jsx("div", { className: "flex flex-1 items-center justify-center overflow-hidden px-4", children: thread ? _jsx(ChatContent, { chatThread: thread }) : _jsx(ChatHomeView, {}) }), _jsx("div", { className: cn("w-full border-t border-border px-4 py-4", isThreadLoading && "pointer-events-none opacity-70"), children: _jsx(AiTextArea, { onSubmit: handleSubmit, onCancel: handleOnCancel, existingLinkedFiles: existingLinkedFiles, placeholder: "Ask a question or describe the change you want to make", submitButtonText: threadId ? "Reply in thread" : "Start new thread", addButtonText: "Files", showConnectTooltip: true }) })] }));
};
