var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChatRole, ChatThreadType, handleSupabaseError } from "@cloudy/utils/common";
import { useMutation, useQuery } from "@tanstack/react-query";
import { produce } from "immer";
import { createContext, useContext, useEffect } from "react";
import { apiClient } from "src/api/client";
import { queryClient } from "src/api/queryClient";
import { chatThreadQueryKeys, thoughtQueryKeys } from "src/api/queryKeys";
import { supabase } from "src/clients/supabase";
import { useUser } from "src/stores/user";
import { useWorkspace } from "src/stores/workspace";
import { ThoughtContext } from "../thoughtContext";
export var triggerThread = function (threadId) { return __awaiter(void 0, void 0, void 0, function () {
    var newMessage, _a, response, reader, fullText, mdContent, _b, done, value, chunk, suggestionRegex, match, suggestionContent;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = handleSupabaseError;
                return [4 /*yield*/, supabase
                        .from("chat_messages")
                        .insert({
                        thread_id: threadId,
                        role: ChatRole.Assistant,
                        content: "",
                    })
                        .select("*")
                        .single()];
            case 1:
                newMessage = _a.apply(void 0, [_d.sent()]);
                return [4 /*yield*/, fetch(apiClient.getUri({ url: "/api/ai/thread-respond" }), {
                        method: "POST",
                        // @ts-ignore
                        headers: __assign(__assign({}, apiClient.defaults.headers.common), { "Content-Type": "application/json" }),
                        body: JSON.stringify({
                            messageId: newMessage.id,
                            threadId: threadId,
                        }),
                    })];
            case 2:
                response = _d.sent();
                reader = (_c = response.body) === null || _c === void 0 ? void 0 : _c.getReader();
                if (!reader) {
                    throw new Error("Failed to get reader from response");
                }
                fullText = "";
                mdContent = "";
                _d.label = 3;
            case 3:
                if (!true) return [3 /*break*/, 5];
                return [4 /*yield*/, reader.read()];
            case 4:
                _b = _d.sent(), done = _b.done, value = _b.value;
                if (done)
                    return [3 /*break*/, 5];
                chunk = new TextDecoder().decode(value);
                fullText += chunk;
                // Remove lines that start with ```
                fullText = fullText
                    .split("\n")
                    .map(function (line) {
                    if (line.trim().startsWith("```")) {
                        return line.replace("```", "\\`\\`\\`");
                    }
                    return line;
                })
                    .join("\n");
                mdContent = fullText;
                suggestionRegex = /<suggestion>([\s\S]*?)<\/suggestion>/g;
                match = void 0;
                while ((match = suggestionRegex.exec(fullText)) !== null) {
                    suggestionContent = match[1];
                    mdContent = mdContent.replace(match[0], "```\n".concat(suggestionContent.trim(), "\n```"));
                }
                // Any remaining opening suggestion tags that don't have a closing tag should be temporarily added to the content
                if (mdContent.includes("<suggestion>")) {
                    mdContent = mdContent.replace("<suggestion>", "```");
                }
                mdContent = mdContent
                    .split("\n")
                    .map(function (line) {
                    if (line.trim().startsWith("```")) {
                        return line.trim();
                    }
                    return line;
                })
                    .join("\n");
                // eslint-disable-next-line no-loop-func
                queryClient.setQueryData(chatThreadQueryKeys.thread(threadId), function (data) {
                    return produce(data, function (draft) {
                        if (draft) {
                            var newMessages = draft.messages.filter(function (message) { return message.id !== newMessage.id; });
                            // @ts-ignore
                            newMessages.push(__assign(__assign({}, newMessage), { content: mdContent }));
                            draft.messages = newMessages;
                        }
                    });
                });
                return [3 /*break*/, 3];
            case 5: return [4 /*yield*/, supabase
                    .from("chat_messages")
                    .update({
                    content: mdContent,
                    completed_at: new Date().toISOString(),
                })
                    .eq("id", newMessage.id)];
            case 6:
                _d.sent();
                queryClient.invalidateQueries({
                    queryKey: chatThreadQueryKeys.thread(threadId),
                });
                return [2 /*return*/];
        }
    });
}); };
export var useChatThread = function (threadId) {
    useEffect(function () {
        var channel = supabase
            .channel("chat_thread")
            .on("postgres_changes", {
            event: "*",
            schema: "public",
            table: "chat_threads",
            filter: "id=eq.".concat(threadId),
        }, function () {
            queryClient.invalidateQueries({
                queryKey: chatThreadQueryKeys.thread(threadId),
            });
        })
            .subscribe();
        return function () {
            channel.unsubscribe();
        };
    }, [threadId]);
    return useQuery({
        queryKey: chatThreadQueryKeys.thread(threadId),
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var thread, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!threadId) {
                            return [2 /*return*/, null];
                        }
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase.from("chat_threads").select("*, messages:chat_messages(*)").eq("id", threadId).single()];
                    case 1:
                        thread = _a.apply(void 0, [_b.sent()]);
                        thread.messages.sort(function (a, b) { return new Date(a.created_at).getTime() - new Date(b.created_at).getTime(); });
                        return [2 /*return*/, thread];
                }
            });
        }); },
        enabled: !!threadId,
    });
};
export var ChatMessageContext = createContext({
    message: {
        id: "",
        role: "user",
        content: "",
        created_at: "",
        applied_suggestion_hashes: [],
        completed_at: null,
        thread_id: "",
        user_id: null,
        selection_text: null,
        file_references: [],
    },
});
export var useThreadsForDoc = function (docId) {
    return useQuery({
        queryKey: thoughtQueryKeys.threadsForDoc(docId),
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase
                                .from("chat_threads")
                                .select("*, first_message:chat_messages!chat_messages_thread_id_fkey(*)")
                                .eq("document_id", docId)
                                .eq("type", ChatThreadType.Default)
                                .order("created_at", { ascending: false })
                                .order("created_at", { referencedTable: "first_message", ascending: true })
                                .limit(1, { referencedTable: "first_message" })];
                    case 1: return [2 /*return*/, _a.apply(void 0, [_b.sent()])];
                }
            });
        }); },
    });
};
export var useDeleteThread = function (threadId) {
    var docId = useContext(ThoughtContext).thoughtId;
    return useMutation({
        mutationFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, supabase.from("chat_threads").delete().eq("id", threadId)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); },
        onSuccess: function () {
            queryClient.invalidateQueries({
                queryKey: chatThreadQueryKeys.thread(threadId),
            });
            queryClient.invalidateQueries({
                queryKey: thoughtQueryKeys.threadsForDoc(docId),
            });
        },
    });
};
export var getSelection = function (editor) {
    var currentMd = editor.storage.markdown.getMarkdown();
    var firstEditStart = currentMd.indexOf("<edit>");
    var lastEditEnd = currentMd.lastIndexOf("</edit>") + 7; // 7 is the length of '</edit>'
    if (firstEditStart === -1 || lastEditEnd === -1) {
        return null;
    }
    return currentMd.substring(firstEditStart, lastEditEnd).replace(/<\/?edit>/g, "");
};
export var useStartThread = function () {
    var workspace = useWorkspace();
    var user = useUser();
    var _a = useContext(ThoughtContext), thoughtId = _a.thoughtId, editor = _a.editor;
    return useMutation({
        mutationFn: function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var selection, thread, _c, _d;
            var content = _b.content, fileReferences = _b.fileReferences;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        selection = getSelection(editor);
                        _c = handleSupabaseError;
                        return [4 /*yield*/, supabase
                                .from("chat_threads")
                                .insert({
                                workspace_id: workspace.id,
                                document_id: thoughtId,
                            })
                                .select("*")
                                .single()];
                    case 1:
                        thread = _c.apply(void 0, [_e.sent()]);
                        _d = handleSupabaseError;
                        return [4 /*yield*/, supabase
                                .from("chat_messages")
                                .insert({
                                thread_id: thread.id,
                                content: content,
                                role: ChatRole.User,
                                user_id: user.id,
                                selection_text: selection,
                                file_references: JSON.stringify(fileReferences),
                            })
                                .select("*")
                                .single()];
                    case 2:
                        _d.apply(void 0, [_e.sent()]);
                        triggerThread(thread.id);
                        return [2 /*return*/, thread];
                }
            });
        }); },
        onSuccess: function (thread) {
            if (thread.document_id) {
                queryClient.invalidateQueries({ queryKey: thoughtQueryKeys.threadsForDoc(thread.document_id) });
            }
        },
    });
};
export var useReplyToThread = function () {
    var user = useUser();
    var editor = useContext(ThoughtContext).editor;
    return useMutation({
        mutationFn: function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var selection, message, _c;
            var threadId = _b.threadId, content = _b.content, fileReferences = _b.fileReferences;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        selection = getSelection(editor);
                        _c = handleSupabaseError;
                        return [4 /*yield*/, supabase
                                .from("chat_messages")
                                .insert({
                                thread_id: threadId,
                                content: content,
                                role: ChatRole.User,
                                user_id: user.id,
                                selection_text: selection,
                                file_references: JSON.stringify(fileReferences),
                            })
                                .select("*")
                                .single()];
                    case 1:
                        message = _c.apply(void 0, [_d.sent()]);
                        triggerThread(threadId);
                        return [2 /*return*/, message];
                }
            });
        }); },
        onSuccess: function (_, _a) {
            var threadId = _a.threadId;
            queryClient.invalidateQueries({ queryKey: chatThreadQueryKeys.thread(threadId) });
        },
    });
};
