var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AuthError } from "@supabase/supabase-js";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { supabase } from "../../clients/supabase";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import LoadingSpinner from "../../components/LoadingSpinner";
export var ForgotPassword = function () {
    var _a = useForm(), register = _a.register, handleSubmit = _a.handleSubmit, errors = _a.formState.errors;
    var _b = useState(null), resetError = _b[0], setResetError = _b[1];
    var _c = useState(false), resetSuccess = _c[0], setResetSuccess = _c[1];
    var resetPasswordMutation = useMutation({
        mutationFn: function (email) { return __awaiter(void 0, void 0, void 0, function () {
            var error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, supabase.auth.resetPasswordForEmail(email, {
                            redirectTo: "https://app.usecloudy.com/auth/password-reset",
                        })];
                    case 1:
                        error = (_a.sent()).error;
                        if (error)
                            throw error;
                        return [2 /*return*/];
                }
            });
        }); },
        onSuccess: function () {
            setResetSuccess(true);
        },
        onError: function (error) {
            if (error instanceof AuthError) {
                setResetError(error.message);
            }
            else {
                setResetError("An error occurred during password reset");
            }
        },
    });
    var onSubmit = function (data) {
        resetPasswordMutation.mutate(data.email);
    };
    return (_jsxs("form", { onSubmit: handleSubmit(onSubmit), className: "mt-4 flex flex-col gap-2", children: [_jsx("label", { htmlFor: "email", className: "font-medium text-secondary", children: "Email" }), _jsx(Input, __assign({ type: "email", className: "mb-2", placeholder: "iforgot@usecloudy.com" }, register("email", { required: "Email is required" }))), errors.email && _jsx("p", { className: "text-sm text-red-600", children: errors.email.message }), resetError && _jsx("p", { className: "mb-2 text-sm text-red-600", children: resetError }), resetSuccess && _jsx("p", { className: "mb-2 text-sm text-green-600", children: "Password reset email sent. Please check your inbox." }), !resetSuccess && (_jsx(Button, { type: "submit", className: "self-stretch", disabled: resetPasswordMutation.isPending, children: resetPasswordMutation.isPending ? _jsx(LoadingSpinner, { size: "xs", variant: "background" }) : "Reset Password" })), _jsxs("div", { className: "mt-4 text-center text-sm text-secondary", children: ["Suddenly remember your password?", " ", _jsx(Link, { to: "/auth", className: "text-accent hover:text-accent/70 hover:underline", children: "Sign in" })] })] }));
};
