import { jsx as _jsx } from "react/jsx-runtime";
import { Node, mergeAttributes } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import LoadingSpinner from "src/components/LoadingSpinner";
var PendingAttachmentComponent = function () {
    return (_jsx(NodeViewWrapper, { className: "react-component", children: _jsx("div", { className: "flex flex-1 items-center justify-center rounded-sm border border-border bg-card p-2", children: _jsx(LoadingSpinner, { size: "sm" }) }) }));
};
export var PendingAttachmentNode = Node.create({
    name: "pending-attachment",
    group: "block",
    atom: true,
    addAttributes: function () {
        return {
            attachmentId: {
                default: "",
            },
        };
    },
    parseHTML: function () {
        return [
            {
                tag: "pending-attachment",
            },
        ];
    },
    renderHTML: function (_a) {
        var HTMLAttributes = _a.HTMLAttributes;
        return ["pending-attachment", mergeAttributes(HTMLAttributes)];
    },
    addNodeView: function () {
        return ReactNodeViewRenderer(PendingAttachmentComponent);
    },
});
