var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FloatingFocusManager, offset, shift, useFloating } from "@floating-ui/react";
import { SparklesIcon } from "lucide-react";
import { useEffect } from "react";
import { useContext, useRef } from "react";
import { useMount } from "react-use";
import { AiTextArea } from "../aiTextArea/AiTextArea";
import { useReplyToThread, useStartThread } from "./chatSection/chat";
import { useExistingLinkedFiles } from "./hooks";
import { ThoughtContext } from "./thoughtContext";
export var AiSelectionMenu = function (_a) {
    var onCancel = _a.onCancel, onClose = _a.onClose;
    var _b = useContext(ThoughtContext), editor = _b.editor, threadId = _b.threadId, setThreadId = _b.setThreadId, thoughtId = _b.thoughtId, showAiEditor = _b.showAiEditor;
    var existingLinkedFiles = useExistingLinkedFiles(thoughtId).data;
    var startThreadMutation = useStartThread();
    var replyToThreadMutation = useReplyToThread();
    var _c = useFloating({
        open: true,
        placement: "bottom",
        middleware: [offset(10), shift()],
    }), refs = _c.refs, floatingStyles = _c.floatingStyles, context = _c.context;
    var textAreaRef = useRef(null);
    useMount(function () {
        refs.setPositionReference({
            // @ts-ignore
            getBoundingClientRect: function () {
                var _a;
                return (_a = document.querySelector("edit")) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
            },
        });
    });
    useEffect(function () {
        var handleSelectionUpdate = function () {
            onCancel(true);
        };
        editor === null || editor === void 0 ? void 0 : editor.on("selectionUpdate", handleSelectionUpdate);
        return function () {
            editor === null || editor === void 0 ? void 0 : editor.off("selectionUpdate", handleSelectionUpdate);
        };
    }, [editor, onCancel]);
    // useEffect(() => {
    // 	const floating = refs.floating.current;
    // 	if (!floating) return;
    // 	const isElementInViewport = (el: HTMLElement) => {
    // 		const rect = el.getBoundingClientRect();
    // 		return (
    // 			rect.top >= 0 &&
    // 			rect.left >= 0 &&
    // 			rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    // 			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    // 		);
    // 	};
    // 	if (!isElementInViewport(floating)) {
    // 		floating.scrollIntoView({ behavior: "smooth", block: "nearest" });
    // 	}
    // }, [refs.floating]);
    var handleSubmit = function (text, fileReferences) { return __awaiter(void 0, void 0, void 0, function () {
        var thread;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, startThreadMutation.mutateAsync({ content: text, fileReferences: fileReferences })];
                case 1:
                    thread = _a.sent();
                    onClose();
                    showAiEditor();
                    setThreadId(thread.id);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleReply = function (text, fileReferences) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!threadId) {
                        throw new Error("Thread ID is not set");
                    }
                    return [4 /*yield*/, replyToThreadMutation.mutateAsync({ threadId: threadId, content: text, fileReferences: fileReferences })];
                case 1:
                    _a.sent();
                    onClose();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(FloatingFocusManager, { context: context, initialFocus: textAreaRef, children: _jsxs("div", { ref: refs.setFloating, style: floatingStyles, className: "z-50 flex w-[32rem] flex-col gap-0.5 rounded-md border border-border bg-background px-2 py-2", children: [_jsxs("div", { className: "flex flex-row items-center gap-1 pb-1 pl-2 pt-1", children: [_jsx(SparklesIcon, { className: "h-4 w-4 text-accent" }), _jsx("span", { className: "text-sm font-medium", children: "Ask Cloudy" })] }), _jsx("div", { className: "flex flex-row items-end gap-1 px-2", children: _jsx(AiTextArea, { onSubmit: threadId ? handleReply : handleSubmit, onCancel: onCancel, secondaryButtonText: "New thread", onSecondaryAction: threadId ? handleSubmit : undefined, submitButtonText: threadId ? "Ask in thread" : "New thread", existingLinkedFiles: existingLinkedFiles, showConnectTooltip: true, addButtonText: "Files" }) })] }) }));
};
