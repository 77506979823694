import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HomeIcon, PlusIcon } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import { useWorkspaceStore } from "src/stores/workspace";
import { cn } from "src/utils";
import { useCreateThought } from "src/utils/thought";
export var MobileTabBar = function () {
    var workspace = useWorkspaceStore().workspace;
    var createThoughtMutation = useCreateThought();
    return (_jsxs("nav", { className: "relative z-50 flex w-screen flex-row items-center justify-between border-t border-border bg-background py-3 md:hidden", children: [_jsx(Tab, { icon: _jsx(HomeIcon, { className: "size-5" }), label: "Home", href: "/" }), workspace && (_jsx(Tab, { icon: _jsx(PlusIcon, { className: "size-5" }), label: "New note", onClick: function () { return createThoughtMutation.mutate({}); } }))] }));
};
var Tab = function (_a) {
    var icon = _a.icon, label = _a.label, onClick = _a.onClick, href = _a.href;
    var location = useLocation();
    var isActive = location.pathname === href;
    var inner = (_jsxs("button", { onClick: onClick, className: cn("flex flex-1 flex-col items-center justify-center gap-1", isActive && "text-accent"), children: [icon, _jsx("span", { className: "text-xs", children: label })] }));
    if (href) {
        return (_jsx(Link, { to: href, className: "flex flex-1", children: inner }));
    }
    return inner;
};
