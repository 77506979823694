import { PasteRule } from "@tiptap/core";
import { processExactSearches, } from "./tiptapSearchAndReplace";
export var backtickInputRegex = /```([a-z]+)?\s*([\s\S]*?)```/;
/**
 * Build an paste rule that adds a node when the
 * matched text is pasted into it.
 * @see https://tiptap.dev/docs/editor/extensions/custom-extensions/extend-existing#paste-rules
 */
export function createCodeBlockPasteRule(config) {
    return new PasteRule({
        find: config.find,
        handler: function (_a) {
            var _b;
            var match = _a.match, chain = _a.chain, range = _a.range, pasteEvent = _a.pasteEvent, state = _a.state;
            var rawText = (_b = pasteEvent === null || pasteEvent === void 0 ? void 0 : pasteEvent.clipboardData) === null || _b === void 0 ? void 0 : _b.getData("text/plain");
            var matches = Array.from((rawText === null || rawText === void 0 ? void 0 : rawText.matchAll(new RegExp(backtickInputRegex, "g"))) || []);
            if (!matches.length) {
                return null;
            }
            var matchOnTheOriginal = match.input.match(backtickInputRegex);
            if (!(matchOnTheOriginal === null || matchOnTheOriginal === void 0 ? void 0 : matchOnTheOriginal[0])) {
                return null;
            }
            var result = processExactSearches(state.doc, matchOnTheOriginal[0]);
            if (!result[0]) {
                return null;
            }
            // Process each code block match
            var currentPos = result[0].from;
            for (var _i = 0, matches_1 = matches; _i < matches_1.length; _i++) {
                var rawMatch = matches_1[_i];
                var attrs = {
                    language: rawMatch[1],
                };
                var node = { type: config.type.name, attrs: attrs };
                if (rawMatch[2]) {
                    node.content = [
                        {
                            type: "text",
                            text: rawMatch[2],
                        },
                    ];
                }
                chain()
                    .deleteRange({ from: currentPos, to: currentPos + rawMatch[0].length })
                    .insertContentAt(currentPos, node);
                currentPos += rawMatch[0].length;
            }
        },
    });
}
