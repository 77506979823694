import { jsx as _jsx } from "react/jsx-runtime";
import { cva } from "class-variance-authority";
import React from "react";
import { cn } from "src/utils";
var spinnerVariants = cva("rounded-full border-solid animate-spin", {
    variants: {
        size: {
            xs: "w-3.5 h-3.5 border-2",
            sm: "w-5 h-5 border-2",
            md: "w-10 h-10 border-4",
            lg: "w-16 h-16 border-4",
        },
        variant: {
            primary: "border-accent border-t-transparent",
            secondary: "border-secondary border-t-transparent",
            background: "border-background border-t-transparent",
        },
    },
    defaultVariants: {
        size: "md",
        variant: "primary",
    },
});
var LoadingSpinner = function (_a) {
    var size = _a.size, variant = _a.variant, className = _a.className;
    return (_jsx("div", { className: "flex items-center justify-center", children: _jsx("div", { className: cn(spinnerVariants({ size: size, variant: variant }), className) }) }));
};
export default LoadingSpinner;
