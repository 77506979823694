var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as amplitude from "@amplitude/analytics-browser";
import { handleSupabaseError } from "@cloudy/utils/common";
import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import posthog from "posthog-js";
import { useEffect, useMemo } from "react";
import { useMount } from "react-use";
import { create } from "zustand";
import { apiClient, setupAuthHeader } from "src/api/client";
import { queryClient } from "src/api/queryClient";
import { userQueryKeys } from "src/api/queryKeys";
import { supabase } from "src/clients/supabase";
import { getAllUserWorkspaces, useWorkspaceStore } from "./workspace";
export var createUserIfNotExists = function (user) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, supabase.from("users").upsert({
                id: user.id,
            })];
    });
}); };
// const waitForStripeCustomer = () =>
// 	new Promise<void>(resolve => {
// 		let iterations = 0;
// 		const interval = setInterval(async () => {
// 			try {
// 				if (iterations >= 8) {
// 					clearInterval(interval);
// 					resolve();
// 					return;
// 				}
// 				const status = await apiClient
// 					.get<PaymentsCustomersStatusGetResponse>("/api/payments/customers/status")
// 					.then(res => res.data);
// 				if (status.customerStatus) {
// 					clearInterval(interval);
// 					resolve();
// 				}
// 				iterations++;
// 			} catch (error) {
// 				iterations++;
// 			}
// 		}, 1000);
// 	});
var useQueryUserRecord = function () {
    var user = useUserStore(function (s) { return s.user; });
    return useQuery({
        queryKey: userQueryKeys.userRecord(user === null || user === void 0 ? void 0 : user.id),
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { var _a, _b; return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!user) return [3 /*break*/, 2];
                    _b = handleSupabaseError;
                    return [4 /*yield*/, supabase.from("users").select("*").eq("id", user.id).single()];
                case 1:
                    _a = _b.apply(void 0, [_c.sent()]);
                    return [3 /*break*/, 3];
                case 2:
                    _a = null;
                    _c.label = 3;
                case 3: return [2 /*return*/, _a];
            }
        }); }); },
        enabled: !!user,
        refetchInterval: function (data) { return (data ? false : 1000); },
    });
};
var startTrialIfEligible = function () { return __awaiter(void 0, void 0, void 0, function () {
    var status;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, apiClient
                    .get("/api/payments/customers/status")
                    .then(function (res) { return res.data; })];
            case 1:
                status = _a.sent();
                if (!(status.customerStatus && !status.customerStatus.isTrialing && status.customerStatus.isEligibleForTrial)) return [3 /*break*/, 3];
                return [4 /*yield*/, apiClient.post("/api/payments/start-trial")];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); };
export var useUserStore = create(function (set) { return ({
    user: null,
    isReady: false,
    isLoadingAuth: true,
    setUser: function (user) { return set({ user: user }); },
    setIsReady: function (isReady) { return set({ isReady: isReady }); },
    setIsLoadingAuth: function (isLoadingAuth) { return set({ isLoadingAuth: isLoadingAuth }); },
}); });
export var useUser = function () {
    var user = useUserStore(function (s) { return s.user; });
    return user;
};
export var useUserGuard = function () {
    return useUserStore();
};
export var useUserRecord = function () {
    var userRecord = useQueryUserRecord().data;
    return userRecord;
};
export var useUserOptions = function () {
    var userRecord = useUserRecord();
    var userMutation = useMutation({
        mutationFn: function (option) { return __awaiter(void 0, void 0, void 0, function () {
            var options, _a, _b;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase.from("users").select("options").eq("id", userRecord.id).single()];
                    case 1:
                        options = _a.apply(void 0, [_d.sent()]).options;
                        _b = handleSupabaseError;
                        return [4 /*yield*/, supabase
                                .from("users")
                                .update({ options: __assign(__assign({}, options), (_c = {}, _c[option.key] = option.value, _c)) })
                                .eq("id", userRecord.id)];
                    case 2:
                        _b.apply(void 0, [_d.sent()]);
                        return [2 /*return*/];
                }
            });
        }); },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: userQueryKeys.userRecord(userRecord.id) });
        },
    });
    return useMemo(function () { return ({
        get: function (key, defaultValue) {
            var _a, _b;
            if (defaultValue === void 0) { defaultValue = null; }
            return (_b = (_a = userRecord.options) === null || _a === void 0 ? void 0 : _a[key]) !== null && _b !== void 0 ? _b : defaultValue;
        },
        set: function (key, value) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, userMutation.mutateAsync({ key: key, value: value })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); },
    }); }, [userRecord, userMutation]);
};
// const useRefreshSession = () => {
// 	const handleAutoRefresh = useCallback(() => {
// 		if (document.visibilityState === "visible") {
// 			supabase.auth.startAutoRefresh();
// 		} else {
// 			supabase.auth.stopAutoRefresh();
// 		}
// 	}, []);
// 	useEffect(() => {
// 		// Initial call to set the correct state
// 		handleAutoRefresh();
// 		// Add event listener for visibility change
// 		document.addEventListener("visibilitychange", handleAutoRefresh);
// 		// Cleanup
// 		return () => {
// 			document.removeEventListener("visibilitychange", handleAutoRefresh);
// 			supabase.auth.stopAutoRefresh();
// 		};
// 	}, [handleAutoRefresh]);
// };
var useRefreshSessionHeader = function () {
    useEffect(function () {
        var interval = setInterval(function () {
            setupAuthHeader();
        }, 15000);
        return function () { return clearInterval(interval); };
    }, []);
};
export var useUserHandler = function () {
    var userStore = useUserStore();
    var workspaceStore = useWorkspaceStore();
    var userRecordQuery = useQueryUserRecord();
    useRefreshSessionHeader();
    var handleClearUser = function () {
        userStore.setUser(null);
        userStore.setIsReady(false);
        userStore.setIsLoadingAuth(false);
        workspaceStore.setWorkspace(null);
        workspaceStore.setRole(null);
        queryClient.clear();
        amplitude.setUserId(undefined);
        posthog.identify(undefined);
    };
    var handleSetUser = function (session) { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    userStore.setUser(session.user);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    amplitude.setUserId(session.user.id);
                    posthog.identify(session.user.id, { email: session.user.email });
                    return [4 /*yield*/, setupAuthHeader()];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    Sentry.captureException(e_1);
                    handleClearUser();
                    return [3 /*break*/, 4];
                case 4:
                    userStore.setIsLoadingAuth(false);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (userRecordQuery.data) {
            userStore.setIsReady(true);
        }
    }, [userRecordQuery.data]);
    useMount(function () { return __awaiter(void 0, void 0, void 0, function () {
        var subscription;
        return __generator(this, function (_a) {
            userStore.setIsLoadingAuth(true);
            supabase.auth.getSession().then(function (_a) {
                var session = _a.data.session;
                if (session === null || session === void 0 ? void 0 : session.user) {
                    handleSetUser(session);
                }
                else {
                    handleClearUser();
                }
            });
            subscription = supabase.auth.onAuthStateChange(function (event, session) {
                if (event === "SIGNED_IN") {
                    if (session === null || session === void 0 ? void 0 : session.user) {
                        handleSetUser(session);
                    }
                }
                if (event === "SIGNED_OUT") {
                    handleClearUser();
                }
            }).data.subscription;
            return [2 /*return*/, function () {
                    subscription.unsubscribe();
                }];
        });
    }); });
};
export var useAllUserWorkspaces = function () {
    var user = useUser();
    return useQuery({
        queryKey: ["userWorkspaces"],
        queryFn: function () { return getAllUserWorkspaces(user.id); },
    });
};
