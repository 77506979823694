import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChatRole, makeHumanizedTime } from "@cloudy/utils/common";
import { ArrowUpIcon, SparklesIcon } from "lucide-react";
import { useContext, useRef } from "react";
import { useEffect } from "react";
import Markdown from "react-markdown";
import { usePrevious } from "react-use";
import { Button } from "src/components/Button";
import LoadingSpinner from "src/components/LoadingSpinner";
import { ThoughtContext } from "../thoughtContext";
import { ChatMessageUserHeader } from "./ChatMessageUserHeader";
import { SuggestionContent } from "./SuggestionContent";
import { ChatMessageContext } from "./chat";
export var ChatContent = function (_a) {
    var _b, _c;
    var chatThread = _a.chatThread, isAnyLoading = _a.isAnyLoading;
    var setThreadId = useContext(ThoughtContext).setThreadId;
    var threadRef = useRef(null);
    var prevNumOfMessages = usePrevious((_b = chatThread.messages.length) !== null && _b !== void 0 ? _b : 0);
    // Add this useEffect to scroll to bottom when comments change
    useEffect(function () {
        if (threadRef.current && prevNumOfMessages !== chatThread.messages.length) {
            threadRef.current.scrollTo({
                top: threadRef.current.scrollHeight,
                behavior: "smooth",
            });
        }
    }, [chatThread === null || chatThread === void 0 ? void 0 : chatThread.messages, prevNumOfMessages]);
    return (_jsxs("div", { ref: threadRef, className: "no-scrollbar relative flex h-full w-full flex-col gap-2 overflow-y-auto", children: [_jsx("div", { className: "sticky top-0 z-40 flex flex-row items-center justify-center px-4 py-2", children: _jsxs(Button, { variant: "outline", size: "sm", onClick: function () { return setThreadId(null); }, className: "bg-background", children: [_jsx(ArrowUpIcon, { className: "size-4" }), _jsx("span", { children: "Exit thread" })] }) }), (_c = chatThread.messages) === null || _c === void 0 ? void 0 : _c.map(function (message) { return _jsx(ChatMessage, { message: message }, message.id); }), isAnyLoading && (_jsx("div", { className: "flex size-12 items-center justify-center rounded bg-background p-3", children: _jsx(LoadingSpinner, { size: "xs" }) }))] }));
};
var ChatMessage = function (_a) {
    var message = _a.message;
    var isLoading = message.role === ChatRole.Assistant && !message.completed_at;
    return (_jsxs("div", { className: "flex flex-col gap-2 rounded bg-background p-3 text-sm outline-offset-2 animate-in fade-in slide-in-from-top-4 fill-mode-forwards hover:outline-accent/40", children: [_jsxs("div", { className: "flex flex-row items-center justify-between gap-1", children: [message.role === ChatRole.User && message.user_id ? (_jsx(ChatMessageUserHeader, { userId: message.user_id })) : (_jsxs("div", { className: "flex flex-row items-center gap-1", children: [_jsx(SparklesIcon, { className: "h-4 w-4 text-accent" }), _jsx("span", { className: "text-xs font-medium text-secondary", children: "Cloudy" }), isLoading && _jsx(LoadingSpinner, { size: "xs" })] })), _jsx("div", { className: "text-xs text-secondary", children: makeHumanizedTime(message.created_at) })] }), _jsx("div", { children: _jsx(ChatMessageContext.Provider, { value: { message: message }, children: _jsx(Markdown, { className: "tiptap", components: {
                            pre: SuggestionContent,
                        }, children: message.content }) }) })] }));
};
