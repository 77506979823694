import { useDebouncedCallback, useThrottledCallback } from "use-debounce";
export var useSave = function (onSave, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.debounceDurationMs, debounceDurationMs = _c === void 0 ? 500 : _c;
    var handleSave = function (payload) {
        // Use generic type T instead of any
        onSave(payload);
    };
    var debouncedSave = useDebouncedCallback(handleSave, debounceDurationMs);
    var onChange = function (payload) {
        debouncedSave(payload);
    };
    return { onChange: onChange };
};
export var useThrottledSave = function (onSave, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.throttleDurationMs, throttleDurationMs = _c === void 0 ? 500 : _c;
    return useThrottledCallback(onSave, throttleDurationMs, {
        leading: false,
        trailing: true,
    });
};
