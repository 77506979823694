import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FilePlusIcon } from "lucide-react";
import { Button } from "src/components/Button";
import LoadingSpinner from "src/components/LoadingSpinner";
import { useCreateThought } from "src/utils/thought";
export var NewNote = function (_a) {
    var collectionId = _a.collectionId;
    var createThoughtMutation = useCreateThought();
    return (_jsx(Button, { variant: "outline", className: "w-full justify-start", onClick: function () { return createThoughtMutation.mutate({ collectionId: collectionId }); }, children: createThoughtMutation.isPending ? (_jsx(LoadingSpinner, { size: "xs", variant: "background" })) : (_jsxs(_Fragment, { children: [_jsx(FilePlusIcon, { className: "size-4" }), _jsxs("span", { children: ["Blank doc", collectionId ? " in collection" : ""] })] })) }));
};
