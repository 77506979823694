var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation, useQuery } from "@tanstack/react-query";
import { CheckCircle2Icon, XIcon } from "lucide-react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { queryClient } from "src/api/queryClient";
import { supabase } from "src/clients/supabase";
import { Button } from "src/components/Button";
import LoadingSpinner from "src/components/LoadingSpinner";
import { SimpleLayout, SimpleLayoutView } from "src/components/SimpleLayout";
import { useUser } from "src/stores/user";
export var InviteAcceptView = function () {
    var _a;
    var user = useUser();
    var navigate = useNavigate();
    var searchParams = useSearchParams()[0];
    var inviteId = searchParams.get("inviteId");
    var _b = useQuery({
        queryKey: ["invite", inviteId],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a, data, error;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!inviteId)
                            throw new Error("No invite ID provided");
                        return [4 /*yield*/, supabase
                                .from("user_pending_invites")
                                .select("*, workspaces(name)")
                                .eq("id", inviteId)
                                .single()];
                    case 1:
                        _a = _b.sent(), data = _a.data, error = _a.error;
                        if (error)
                            throw error;
                        return [2 /*return*/, data];
                }
            });
        }); },
        enabled: !!inviteId,
    }), inviteData = _b.data, isLoadingInvite = _b.isLoading;
    var acceptInviteMutation = useMutation({
        mutationFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, supabase.from("workspace_users").insert({
                            role: "member",
                            user_id: user.id,
                            workspace_id: inviteData === null || inviteData === void 0 ? void 0 : inviteData.workspace_id,
                        })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, supabase.from("user_pending_invites").delete().eq("id", inviteId)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: ["workspaces"] });
            navigate("/");
            toast.success("Account setup completed successfully", { icon: _jsx(CheckCircle2Icon, { className: "h-4 w-4" }) });
        },
    });
    var declineInviteMutation = useMutation({
        mutationFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, supabase.from("user_pending_invites").delete().eq("id", inviteId)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: ["workspaces"] });
            navigate("/");
            toast.success("Invite declined", { icon: _jsx(XIcon, { className: "h-4 w-4" }) });
        },
    });
    var onAccept = function () {
        acceptInviteMutation.mutate();
    };
    var onDecline = function () {
        declineInviteMutation.mutate();
    };
    if (isLoadingInvite) {
        return _jsx(LoadingSpinner, {});
    }
    if (!inviteData) {
        return _jsx(Navigate, { to: "/" });
    }
    return (_jsx(SimpleLayout, { children: _jsx(SimpleLayoutView, { className: "flex h-dvh flex-col items-center justify-center", children: _jsxs("div", { className: "flex w-full flex-col items-start gap-4 rounded-md border border-border p-4 md:w-[24rem]", children: [_jsxs("div", { className: "flex flex-col gap-1", children: [_jsx("h1", { className: "font-display text-lg font-bold tracking-wide", children: "Accept Invite" }), inviteData && (_jsxs("div", { className: "text-sm text-secondary", children: ["You've been invited to join", " ", _jsx("span", { className: "font-medium text-primary", children: (_a = inviteData.workspaces) === null || _a === void 0 ? void 0 : _a.name })] }))] }), _jsxs("div", { className: "flex w-full flex-col gap-1", children: [_jsxs(Button, { onClick: onAccept, className: "w-full", children: [_jsx(CheckCircle2Icon, { className: "h-4 w-4" }), _jsx("span", { children: "Accept invite" })] }), _jsxs(Button, { onClick: onDecline, className: "w-full text-red-600 hover:bg-red-600", variant: "ghost", children: [_jsx(XIcon, { className: "h-4 w-4" }), _jsx("span", { children: "Decline invite" })] })] })] }) }) }));
};
