import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useLocalStorage } from "react-use";
export var useDebug = function () {
    var debug = useLocalStorage("debug", false)[0];
    return debug;
};
export var useDebugQuery = function () {
    var searchParams = useSearchParams()[0];
    var _a = useLocalStorage("debug", false), _ = _a[0], setDebug = _a[1];
    var shouldEnableDebug = searchParams.get("debug") === "1";
    var shouldDisableDebug = searchParams.get("debug") === "0";
    useEffect(function () {
        if (shouldEnableDebug) {
            console.log("Setting debug to true");
            setDebug(true);
        }
        else if (shouldDisableDebug) {
            console.log("Setting debug to false");
            setDebug(false);
        }
    }, [shouldEnableDebug, shouldDisableDebug]);
};
